import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Modal,
  Box,
  Button,
  Divider,
} from "@mui/material";
import { MapContainer, TileLayer, GeoJSON, Tooltip } from "react-leaflet";
import axios from "axios";
import { scaleLinear } from "d3-scale";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./MoreGreenPage.css";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported"; // Importing a suitable placeholder icon

mapboxgl.accessToken =
  "pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q";

const MapComponent = ({ onSuburbClick }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const popupRef = useRef(null);
  const [clickedSuburb, setClickedSuburb] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const melbourneCenter = [144.963, -37.82];
  const melbourneBounds = [
    [144.5937, -38.42],
    [145.5125, -37.5113],
  ];

  const LoadingOverlay = () => (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          width: "50px",
          height: "50px",
          border: "5px solid #f3f3f3",
          borderTop: "5px solid #00441a", // Green color
          borderRadius: "50%",
          animation: "spin 1s linear infinite",
        }}
      />
    </div>
  );

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: melbourneCenter,
      zoom: 11.5,
      maxBounds: melbourneBounds,
    });

    map.current.addControl(new mapboxgl.NavigationControl(), "top-left");

    map.current.on("load", () => {
      setIsLoading(true); // Start loading when fetching data
      axios
        .get("https://api.greenfinderinmelb.me/api/green-spaces/")
        .then((response) => {
          const geoJsonData = response.data;
          if (geoJsonData && geoJsonData.features.length > 0) {
            map.current.addSource("green-spaces", {
              type: "geojson",
              data: geoJsonData,
            });

            map.current.addLayer({
              id: "green-spaces-layer",
              type: "fill",
              source: "green-spaces",
              paint: {
                "fill-color": [
                  "interpolate",
                  ["linear"],
                  ["get", "total_count"],
                  1,
                  "#d9f0d1",
                  45,
                  "#00441a",
                ],
                "fill-opacity": 0.6,
              },
            });

            map.current.addLayer({
              id: "green-spaces-outline-layer",
              type: "line",
              source: "green-spaces",
              paint: {
                "line-color": "#00441a",
                "line-width": 0.7,
              },
            });

            map.current.addLayer({
              id: "hover-border-layer",
              type: "line",
              source: "green-spaces",
              paint: {
                "line-color": "#00441a",
                "line-width": 3,
              },
              filter: ["==", "suburb", ""],
            });

            map.current.addLayer({
              id: "click-border-layer",
              type: "line",
              source: "green-spaces",
              paint: {
                "line-color": "#00441a",
                "line-width": 5,
              },
              filter: ["==", "suburb", ""],
            });

            map.current.on("mousemove", "green-spaces-layer", (e) => {
              const feature = e.features[0];
              const properties = feature.properties;

              if (popupRef.current) {
                popupRef.current.remove();
              }

              map.current.setFilter("hover-border-layer", [
                "==",
                "suburb",
                properties.suburb,
              ]);

              popupRef.current = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
              })
                .setLngLat(e.lngLat)
                .setHTML(
                  `
                    <strong>Suburb:</strong> ${properties.suburb}<br />
                    <strong>Total Open Green Spaces:</strong> ${properties.total_count}<br />
                    <strong>Parks and Gardens:</strong> ${properties.parks_count}<br />
                    <strong>Playgrounds:</strong> ${properties.playgrounds_count}<br />
                    <strong>Sportsfields:</strong> ${properties.sportsfields_count}
                  `
                )
                .addTo(map.current);
            });

            map.current.on("mouseleave", "green-spaces-layer", () => {
              map.current.setFilter("hover-border-layer", ["==", "suburb", ""]);
              if (popupRef.current) {
                popupRef.current.remove();
                popupRef.current = null;
              }
            });

            map.current.on("click", "green-spaces-layer", (e) => {
              const properties = e.features[0].properties;
              setClickedSuburb(properties.suburb);
              onSuburbClick(properties.suburb);
              map.current.setFilter("click-border-layer", [
                "==",
                "suburb",
                properties.suburb,
              ]);
            });

            map.current.on("click", (e) => {
              const features = map.current.queryRenderedFeatures(e.point, {
                layers: ["green-spaces-layer"],
              });

              if (!features.length) {
                map.current.setFilter("click-border-layer", [
                  "==",
                  "suburb",
                  "",
                ]);
                setClickedSuburb(null);
              }
            });

            const legend = document.createElement("div");
            legend.className = "gradient-legend";
            legend.innerHTML = `
                <div class="legend-title">Number of Green Spaces</div>
                <div class="legend-bar"></div>
                <div class="legend-scale">
                  <span>1</span>
                  <span>45</span>
                </div>
              `;
            map.current.getContainer().appendChild(legend);

            setIsLoading(false); // Stop loading when data is processed
          }
        })
        .catch((error) => {
          console.error("Error fetching GeoJSON data:", error);
          setIsLoading(false); // Stop loading even if there's an error
        });
    });
  }, [onSuburbClick]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        ref={mapContainer}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
      {isLoading && <LoadingOverlay />}
    </div>
  );
};

// Plant Card component
const PlantCard = ({ plant }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [plantDetails, setPlantDetails] = useState({});

  // Function to open modal and fetch plant details
  const handleOpenModal = async () => {
    await fetchPlantData(plant.name);
    setModalOpen(true);
  };

  // Function to close modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Function to fetch plant data from API
  const fetchPlantData = async (plantName) => {
    const plantDataUrl = `https://api.greenfinderinmelb.me/api/fetch-plant-details/?plant_name=${encodeURIComponent(
      plantName
    )}`;
    try {
      const response = await axios.get(plantDataUrl);
      console.log("Fetched Plant Data:", response.data);
      setPlantDetails(response.data); // Store fetched plant details
    } catch (error) {
      console.error("Error fetching plant data:", error);
      setPlantDetails({
        summary: "No additional information available.",
      });
    }
  };

  // Get plant information content
  const getContent = () => {
    if (plantDetails && Object.keys(plantDetails).length > 0) {
      if (
        plantDetails.dangers_and_threats &&
        plantDetails.dangers_and_threats.trim()
      ) {
        return plantDetails.dangers_and_threats;
      } else if (
        plantDetails.invasive_and_control_methods &&
        plantDetails.invasive_and_control_methods.trim()
      ) {
        return plantDetails.invasive_and_control_methods;
      } else if (
        plantDetails.uses_and_benefits &&
        plantDetails.uses_and_benefits.trim()
      ) {
        return plantDetails.uses_and_benefits;
      } else if (plantDetails.summary && plantDetails.summary.trim()) {
        return plantDetails.summary;
      }
    }
    return "No additional information available.";
  };

  return (
    <>
      <Card
        className="plant-card" // Add the CSS class here
        sx={{
          width: 200,
          margin: "10px",
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
        }}
        onClick={handleOpenModal}
      >
        {/* Conditional rendering to show either the plant image or an icon */}
        {plant.image.includes("Image+Not+Available") ? (
          <Box
            sx={{
              height: 140,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              backgroundColor: "#417446",
              color: "#ffffff", // White text for contrast
            }}
          >
            <ImageNotSupportedIcon sx={{ fontSize: 50, color: "#fff" }} />
            <Typography variant="body2" sx={{ marginTop: 1, color: "#fff" }}>
              No Image Available
            </Typography>
          </Box>
        ) : (
          <CardMedia
            component="img"
            height="140"
            image={plant.image}
            alt={plant.name}
          />
        )}

        <CardContent sx={{ flexGrow: 1, padding: "8px" }}>
          <Typography
            gutterBottom
            variant="body2"
            component="div"
            sx={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              marginBottom: -1, // Reduces space below the text
              marginTop: -1,
              fontSize: "1rem",
            }}
          >
            {plant.name}
          </Typography>
        </CardContent>
      </Card>

      {/* Modal for Plant Information */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="plant-info-title"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: 600, // Set appropriate width for content
            padding: 0,
            backgroundColor: "#FAF8F5", // Light green background
            borderRadius: "8px",
            boxShadow: 24,
            position: "relative",
            transform: "translateY(50px)", // Move the modal slightly down
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              backgroundColor: "#417446", // Dark green header
              padding: 1,
              borderRadius: "8px 8px 0 0",
              textAlign: "center",
              color: "#fff",
            }}
          >
            <Typography id="plant-info-title" variant="h7" component="h3">
              {plant.name}
            </Typography>
          </Box>

          {/* Modal Content */}
          <Box sx={{ display: "flex", padding: 3 }}>
            {/* Left Column: Image */}
            <Box sx={{ flex: 1, maxWidth: 150, marginRight: 3 }}>
              {plant.image.includes("Image+Not+Available") ? (
                <Box
                  sx={{
                    height: 150,
                    width: 150,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#417446",
                    color: "#ffffff", // White text for contrast
                    borderRadius: "8px",
                  }}
                >
                  <ImageNotSupportedIcon
                    sx={{ fontSize: 50, color: "#ffffff" }}
                  />
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    No Image Available
                  </Typography>
                </Box>
              ) : (
                <CardMedia
                  component="img"
                  height="150"
                  width="150"
                  image={plant.image}
                  alt={plant.name}
                  sx={{ borderRadius: "8px" }}
                />
              )}
            </Box>

            {/* Right Column: Plant Information */}
            <Box sx={{ flex: 2 }}>
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 2,
                  fontSize: "0.9rem",
                  lineHeight: "1.5",
                  color: "#417446",
                }}
              >
                {getContent()}
              </Typography>
            </Box>
          </Box>

          {/* Divider line */}
          <Divider sx={{ margin: "0 20px" }} />

          {/* Modal Close Button */}
          <Box sx={{ textAlign: "center", marginTop: 3, paddingBottom: 2 }}>
            <Button
              variant="contained"
              onClick={handleCloseModal}
              sx={{
                backgroundColor: "#417446",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#365b3e", // Slightly darker green on hover
                },
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const MoreGreenPage = () => {
  const [suburb, setSuburb] = useState("");
  const [greenSpace, setGreenSpace] = useState("");
  const [greenSpaceList, setGreenSpaceList] = useState([]);
  const [popularPlants, setPopularPlants] = useState([]);
  const navigate = useNavigate();

  const handleMapSuburbClick = (clickedSuburb) => {
    setSuburb(clickedSuburb);
    setGreenSpace(""); // Reset GreenSpace when a new suburb is clicked on the map
    fetchGreenSpaces(clickedSuburb);
    setPopularPlants([]);
  };

  const fetchGreenSpaces = (selectedSuburb) => {
    axios
      .get(
        `https://api.greenfinderinmelb.me/api/green-spaces-list/?suburb=${selectedSuburb}`
      )
      .then((response) => {
        setGreenSpaceList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching green spaces list:", error);
        setGreenSpaceList([]);
      });
  };

  const handleGreenSpaceChange = (event) => {
    const selectedGreenSpace = event.target.value;
    setGreenSpace(selectedGreenSpace);
    fetchPopularPlants(suburb, selectedGreenSpace);
  };

  const fetchPopularPlants = async (selectedSuburb, selectedGreenSpace) => {
    try {
      const response = await axios.get(
        `https://api.greenfinderinmelb.me/api/fetch-plant-names/?suburb=${encodeURIComponent(
          selectedSuburb
        )}&name=${encodeURIComponent(selectedGreenSpace)}`
      );
      const plantNames = response.data;

      const fetchPlantData = async (scientificName) => {
        const plantDataUrl = `https://api.greenfinderinmelb.me/api/fetch-plant-details/?plant_name=${encodeURIComponent(
          scientificName
        )}`;
        try {
          const response = await axios.get(plantDataUrl);
          return response.data;
        } catch (error) {
          console.error("Error fetching plant data:", error);
          return {};
        }
      };

      const updatedPlants = await Promise.all(
        plantNames.map(async (name, index) => {
          const plantData = await fetchPlantData(name);
          const encodedName = encodeURIComponent(name);
          const url = `https://en.wikipedia.org/w/api.php?action=query&titles=${encodedName}&prop=pageimages&format=json&pithumbsize=300&origin=*`;

          let imageUrl;
          try {
            const response = await axios.get(url);
            const pages = response.data.query.pages;
            const pageId = Object.keys(pages)[0];
            imageUrl = pages[pageId].thumbnail?.source;
          } catch (error) {
            console.error("Error fetching plant image:", error);
            imageUrl = null;
          }

          // Set default image if none is available
          if (!imageUrl) {
            imageUrl =
              "https://via.placeholder.com/150/417446/ffffff?text=Image+Not+Available"; // Green placeholder with text
          }

          return {
            id: index + 1,
            name: name,
            image: imageUrl,
            dangers_and_threats: plantData.dangers_and_threats,
            invasive_and_control_methods:
              plantData.invasive_and_control_methods,
            uses_and_benefits: plantData.uses_and_benefits,
            summary: plantData.summary,
          };
        })
      );

      setPopularPlants(updatedPlants);
    } catch (error) {
      console.error("Error fetching popular plants:", error);
      setPopularPlants([]);
    }
  };
  const handleRedirect = () => {
    navigate("/nearby-green-spaces"); // Redirect to the desired page
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Ensures full viewport height for the page
        backgroundColor: "#F5F3E6",
        paddingTop: { xs: "64px", md: "0px" }, // Padding for small screens
      }}
    >
      {/* Main Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          flex: 1, // Ensures content takes up available space and pushes footer to bottom
        }}
      >
        {/* Left Section: Map */}
        <Box
          sx={{
            flex: 1,
            backgroundColor: "#F5F3E6",
            display: "flex",
            flexDirection: "column",
            padding: { xs: 2, md: 4 },
          }}
        >
          <Typography variant="h5" sx={{ marginBottom: 2, color: "#417446" }}>
            Green Spaces Available in each Suburb
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: { xs: "40vh", md: "calc(100vh - 100px)" }, // Adjust height based on screen size
              position: "relative",
              overflow: "hidden",
              borderRadius: "8px",
            }}
          >
            <MapComponent onSuburbClick={handleMapSuburbClick} />
          </Box>
        </Box>

        {/* Right Section: Dropdowns and Plant Cards */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#F5F3E6",
            padding: { xs: 2, md: 4 },
            height: { xs: "auto", md: "90vh" }, // Adjust height for small screens
            overflowY: { xs: "visible", md: "scroll" }, // Scrollable on larger screens, not scrollable on small screens
          }}
        >
          <Typography
            variant="h5"
            sx={{ marginBottom: "20px", color: "#417446" }}
          >
            Learn About the Plants in Melbourne's Green Spaces
          </Typography>

          <Typography
            variant="body1"
            sx={{ marginBottom: "20px", color: "#417446" }}
          >
            Please click on a Suburb on the map.
          </Typography>

          {suburb && (
            <>
              <Typography
                variant="body1"
                sx={{ marginBottom: "20px", color: "#417446" }}
              >
                Please select the Green Space.
              </Typography>
              <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                <Select
                  value={greenSpace}
                  onChange={handleGreenSpaceChange}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select GreenSpace
                  </MenuItem>
                  {greenSpaceList.map((space) => (
                    <MenuItem key={space} value={space}>
                      {space}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}

          {suburb && greenSpace && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "20px",
                backgroundColor: "#F5F3E6",
                marginBottom: "20px",
              }}
            >
              {popularPlants.length > 0 ? (
                popularPlants.map((plant) => (
                  <PlantCard key={plant.id} plant={plant} />
                ))
              ) : (
                <Typography variant="body2" sx={{ color: "#417446" }}>
                  Loading popular plants...
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {/* New Lower Section with Button */}
      <Box
        sx={{
          backgroundColor: "#417446", // Background color for the new section
          padding: "20px",
          textAlign: "center",
          marginTop: "auto", // Ensures it stays at the bottom
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#ffffff",
            color: "#417446",
            transform: "scale(1)", // Initial scale
            animation: popularPlants.length > 0 ? "pulse 2s infinite" : "none", // Animation starts when plant results are shown
            "@keyframes pulse": {
              "0%": {
                transform: "scale(1)", // Initial state
              },
              "50%": {
                transform: "scale(1.1)", // Scale up
              },
              "100%": {
                transform: "scale(1)", // Scale back to normal
              },
            },
            transition:
              "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "#f3f3f3", // Light color on hover
              transform: "scale(1.05)", // Scale up slightly more on hover
              animation: "none", // Stop pulse on hover for clarity
            },
            "&:active": {
              transform: "scale(0.95)", // Scale down slightly when clicked
              animation: "none", // No pulse when active
            },
          }}
          onClick={handleRedirect}
        >
          Find Nearby Green Spaces
        </Button>
      </Box>
    </Box>
  );
};

export default MoreGreenPage;

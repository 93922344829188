import React, { forwardRef } from "react";
import { Map, Marker, NavigationControl, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const GreenSpaceMap = forwardRef(
  (
    {
      coordinates,
      greenSpaces,
      currentLocationIcon,
      parkLocationIcon,
      playgroundLocationIcon,
      sportLocationIcon,
      showMarker,
    },
    ref
  ) => {
    const [selectedSpace, setSelectedSpace] = React.useState(null);

    React.useEffect(() => {
      if (selectedSpace) {
        const timer = setTimeout(() => {
          setSelectedSpace(null);
        }, 3000); // 3 seconds

        return () => clearTimeout(timer);
      }
    }, [selectedSpace]);

    const isValidCoordinate = (lat, lon) => {
      return (
        typeof lat === "number" &&
        typeof lon === "number" &&
        lat >= -90 &&
        lat <= 90 &&
        lon >= -180 &&
        lon <= 180
      );
    };

    return (
      <Map
        ref={ref}
        initialViewState={{
          longitude: coordinates.longitude,
          latitude: coordinates.latitude,
          zoom: 12,
        }}
        style={{
          width: "100%",
          height: "100%", // Takes up 100% of the parent container's height
          minHeight: "50vh", // Ensure at least 50% of viewport height on small screens
        }} // Takes up 100% of the parent container's height
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken="pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q"
        maxBounds={[
          [144.5937, -38.4339], // Southwest coordinates (approximate for Melbourne)
          [145.5126, -37.5113], // Northeast coordinates (approximate for Melbourne)
        ]}
        minZoom={10} // Minimum zoom level to keep the map focused on Melbourne
        maxZoom={16} // Maximum zoom level
      >
        {showMarker && (
          <Marker
            longitude={coordinates.longitude}
            latitude={coordinates.latitude}
            onClick={() =>
              setSelectedSpace({
                name: "Your Location",
                longitude: coordinates.longitude,
                latitude: coordinates.latitude,
              })
            }
          >
            <div
              style={{
                backgroundImage: `url(${currentLocationIcon})`,
                width: "32px",
                height: "32px",
                backgroundSize: "100%",
                cursor: "pointer",
              }}
            />
          </Marker>
        )}

        {/* Green Spaces Markers */}
        {greenSpaces.map((space, index) => {
          let markerIcon;
          switch (space.category) {
            case "Parks and gardens":
              markerIcon = parkLocationIcon;
              break;
            case "Playgrounds":
              markerIcon = playgroundLocationIcon;
              break;
            case "Sportsfields and organised recreation":
              markerIcon = sportLocationIcon;
              break;
            default:
              markerIcon = parkLocationIcon;
          }

          try {
            const coordsArray = space.coords
              .replace(/[()]/g, "")
              .split(",")
              .map(Number);
            const latitude = parseFloat(coordsArray[0]); // Latitude is the first value
            const longitude = parseFloat(coordsArray[1]); // Longitude is the second value

            if (!isValidCoordinate(latitude, longitude)) {
              console.error(
                `Invalid coordinates for ${space.name}: (${latitude}, ${longitude})`
              );
              return null;
            }

            return (
              <Marker
                key={`${space.id}-${index}`}
                longitude={longitude}
                latitude={latitude}
                onClick={() =>
                  setSelectedSpace({ name: space.name, longitude, latitude })
                }
              >
                <div
                  style={{
                    backgroundImage: `url(${markerIcon})`,
                    width: "32px",
                    height: "32px",
                    backgroundSize: "100%",
                    cursor: "pointer",
                  }}
                />
              </Marker>
            );
          } catch (error) {
            console.error("Error parsing coordinates:", space.coords, error);
            return null;
          }
        })}

        {/* Popup for Selected Space */}
        {selectedSpace && (
          <Popup
            longitude={selectedSpace.longitude}
            latitude={selectedSpace.latitude}
            onClose={() => setSelectedSpace(null)}
            closeButton={true}
            closeOnClick={false}
            offset={25}
          >
            <div
              style={{
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#2E7D32",
                color: "#ffffff",
                fontWeight: "bold",
                textAlign: "center",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              {selectedSpace.name}
            </div>
          </Popup>
        )}

        {/* Navigation Controls */}
        <NavigationControl position="top-left" />
      </Map>
    );
  }
);

export default GreenSpaceMap;

import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";

const StickyNote = ({ open, onClose, onOpen }) => {
  return (
    <>
      {open ? (
        <Box
          sx={{
            position: "fixed",
            top: { xs: "5%", sm: "10%" }, // Adjust the top margin based on screen size
            right: { xs: "10px", sm: "20px" }, // Right margin adjusts for mobile and larger screens
            width: { xs: "150px", sm: "200px", md: "250px" }, // Responsive width based on screen size
            backgroundColor: "#FFF59D",
            border: "1px solid #FFEB3B",
            borderRadius: "8px",
            padding: { xs: "8px", md: "10px" }, // Adjust padding for small screens
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            zIndex: 1500, // Stay on top of other elements
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "#616161",
                fontSize: { xs: "0.9rem", md: "1.1rem" }, // Responsive font size
              }}
            >
              Tips
            </Typography>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: "textSecondary",
              fontWeight: 600,
              fontSize: { xs: "0.75rem", md: "0.85rem" }, // Responsive font size
            }}
          >
            Click on the amenity icon on the map to get the direction.
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            position: "fixed",
            top: { xs: "15%", sm: "10%" },
            right: { xs: "10px", sm: "20px" },
            zIndex: 1500, // Ensure the button is on top
          }}
        >
          <IconButton
            onClick={onOpen}
            sx={{
              backgroundColor: "#FFEB3B",
              borderRadius: "50%",
              width: { xs: "40px", md: "50px" }, // Adjust icon size for smaller screens
              height: { xs: "40px", md: "50px" },
            }}
          >
            <StickyNote2Icon
              fontSize="large"
              sx={{
                color: "#616161",
                fontSize: { xs: "1.5rem", md: "2rem" }, // Responsive icon size
              }}
            />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default StickyNote;

import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled, keyframes } from "@mui/system";

// Bounce animation for the scroll icon
const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

// Styled Scroll Icon
const ScrollIcon = styled(ExpandMoreIcon)`
  position: fixed;
  bottom: 20px;
  left: 48%;
  transform: translate(-50%, -50%);
  font-size: 70px;
  color: #598f57;
  z-index: 1000;
  animation: ${bounce} 2s infinite;
  cursor: pointer;
  width: 70px;
  height: 70px; // Explicit width and height to ensure centering

  @media (max-width: 600px) {
    font-size: 50px;
    width: 50px;
    height: 50px;
  }

  @media (min-width: 600px) {
    font-size: 100px;
    width: 100px;
    height: 100px;
  }
`;

const ScrollToNext = () => {
  const [showIcon, setShowIcon] = useState(true);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0); // Track current section index

  const scrollToNextSection = () => {
    const sections = document.querySelectorAll(".scroll-section");

    // Ensure there are sections to scroll through
    if (sections.length === 0) return;

    // Get the next section by index
    const nextSection = sections[currentSectionIndex];

    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
      setCurrentSectionIndex((prevIndex) => prevIndex + 1); // Move to the next section index

      // Hide the icon if the user has reached the last section
      if (currentSectionIndex + 1 >= sections.length) {
        setShowIcon(false);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;

      // Hide the icon if the user has reached the bottom of the page
      if (scrollTop + windowHeight >= documentHeight - 10) {
        setShowIcon(false);
      } else {
        setShowIcon(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* Only show the icon if the user is not at the bottom */}
      {showIcon && <ScrollIcon onClick={scrollToNextSection} />}
    </>
  );
};

export default ScrollToNext;

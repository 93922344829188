import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Divider,
} from "@mui/material";
import CloudOffIcon from "@mui/icons-material/CloudOff"; // Icon for unfavorable conditions
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Icon for favorable conditions
import EventIcon from "@mui/icons-material/Event"; // Icon for suggested better days
import UVIndexInfo from "./UVIndexInfo"; // Import UVIndexInfo component
import WeatherInfo from "./WeatherInfo"; // Import WeatherInfo component
import axios from "axios"; // For making API calls

const formatDate = (dateString) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options); // Format to "Tuesday 08 October 2024"
};

const UVWeatherDisplay = ({ placeId, category }) => {
  const [recommendations, setRecommendations] = useState(null); // State to hold recommendation data
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    // Fetch recommendations from the API
    const fetchRecommendations = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.greenfinderinmelb.me/api/generate-recommendations?place_id=${placeId}&category=${category}`
        );
        setRecommendations(response.data);
      } catch (err) {
        setError("Failed to load recommendations.");
      } finally {
        setLoading(false);
      }
    };

    fetchRecommendations();
  }, [placeId, category]);

  return (
    <>
      {/* UV and Weather Sections */}
      <Box
        sx={{
          borderRadius: "15px",
          display: "flex", // Flexbox for horizontal layout
          "@media (max-width: 900px)": {
            flexDirection: "column", // Stack in a column for smaller screens
          },
        }}
      >
        {/* UV Index Info Section */}
        <Box
          sx={{
            flex: "1", // Make the UV section flexible
            padding: 3,
            backgroundColor: "#F5F2EC", // Soft light background for consistency
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center content vertically
            minHeight: "100%", // Ensure it expands to the full height of its container
          }}
        >
          <UVIndexInfo placeId={placeId} category={category} />
        </Box>

        {/* Weather Info Section */}
        <Box
          sx={{
            flex: "1", // Make the Weather section flexible as well
            padding: 3,
            backgroundColor: "#F5F2EC", // Light background for weather section
            display: "flex",
            flexDirection: "column", // Allow for stacking of elements inside the weather section
            justifyContent: "center", // Ensure content is centered
            alignItems: "center", // Align the content centrally
            minHeight: "100%", // Ensure the section matches the UV section's height
            width: "100%", // Ensure full width usage
          }}
        >
          <WeatherInfo placeId={placeId} category={category} />
        </Box>
      </Box>

      {/* Smart Suggestions Section */}
      <Box
        sx={{
          marginTop: 4,
          padding: 4,
          borderRadius: "15px",
          backgroundColor: "#FAF9F6",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {loading ? (
          <Typography>Loading recommendations...</Typography>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          recommendations && (
            <>
              {/* Updated Current Status Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column", // Stacks the icon above the text
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 3,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {recommendations.current_status?.includes("Unfavorable") ? (
                  <>
                    <CloudOffIcon
                      sx={{ fontSize: 50, color: "#FF8C00", mb: 1 }}
                    />{" "}
                    {/* Icon above with spacing */}
                    <Box>
                      <Typography
                        variant="h5"
                        sx={{ color: "#455A64", fontWeight: "bold" }}
                      >
                        Although the current conditions are not ideal for a park
                        visit,
                      </Typography>
                      <Typography variant="h6" sx={{ color: "#607D8B", mt: 1 }}>
                        we’ve got you covered! Here's a suggestion for a better
                        time for your next sustainable trip:
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <CheckCircleIcon
                      sx={{ fontSize: 50, color: "#388E3C", mb: 1 }}
                    />{" "}
                    {/* Icon above */}
                    <Typography
                      variant="h5"
                      sx={{ color: "#388E3C", fontWeight: "bold" }}
                    >
                      Conditions are great for a park visit! Enjoy your
                      sustainable trip to the park!
                    </Typography>
                  </>
                )}
              </Box>

              {/* Hide sections when conditions are favorable */}
              {!recommendations.current_status?.includes(
                "Unfavorable"
              ) ? null : (
                <>
                  {/* Suggested Better Days Section */}
                  <Box sx={{ mb: 4, textAlign: "center" }}>
                    {/* Calendar Icon in Circle */}
                    <Box
                      sx={{
                        width: 60,
                        height: 60,
                        borderRadius: "50%", // Makes the background a circle
                        backgroundColor: "#0288D1", // Blue background for the circle
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 2,
                        mx: "auto", // Ensure it's centered horizontally
                      }}
                    >
                      <EventIcon sx={{ fontSize: 30, color: "#FFF" }} />{" "}
                      {/* Centered Icon */}
                    </Box>

                    {/* Suggested Better Day Text */}
                    <Typography
                      variant="h6"
                      sx={{ mb: 2, color: "#0288d1", fontWeight: "bold" }}
                    >
                      Suggested Better Day:
                    </Typography>

                    {/* Date and Time */}
                    {recommendations?.alternative_dates?.length > 0 ? (
                      <>
                        <Typography variant="body1" sx={{ color: "#455a64" }}>
                          {formatDate(
                            recommendations.alternative_dates[0].date
                          )}{" "}
                          at {recommendations.alternative_dates[0].time}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#607d8b", mt: 1 }}
                        >
                          Weather:{" "}
                          {recommendations.alternative_dates[0].weather}
                        </Typography>
                      </>
                    ) : null}
                  </Box>

                  <Divider sx={{ width: "100%", my: 2 }} />

                  {/* Alternative Activities Section */}
                  <Typography
                    variant="h6"
                    sx={{ mb: 3, color: "#417446", fontWeight: "bold" }}
                  >
                    While you wait, explore other sustainable activities to
                    contribute to Melbourne's green future:
                  </Typography>
                  <Grid container spacing={3}>
                    {recommendations?.alternative_activities?.length > 0
                      ? recommendations.alternative_activities.map(
                          (activity, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                              <Card
                                sx={{
                                  height: "100%",
                                  borderRadius: "12px",
                                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                  transition:
                                    "transform 0.3s ease, box-shadow 0.3s ease",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  "&:hover": {
                                    transform: "translateY(-5px)",
                                    boxShadow:
                                      "0px 6px 16px rgba(0, 0, 0, 0.2)",
                                  },
                                  backgroundColor: "#ECE6DA",
                                }}
                              >
                                <CardContent sx={{ flexGrow: 1, padding: 2 }}>
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontWeight: "bold",
                                      color: "#004d40",
                                    }}
                                  >
                                    {activity.name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      mt: 1,
                                      mb: 2,
                                      color: "#37474f",
                                      fontSize: "0.95rem",
                                    }}
                                  >
                                    {activity.description}
                                  </Typography>
                                </CardContent>
                                <Box sx={{ padding: 2 }}>
                                  <Button
                                    href={activity.link}
                                    target="_blank"
                                    sx={{
                                      backgroundColor: "#417446",
                                      color: "#fff",
                                      width: "100%",
                                      "&:hover": {
                                        backgroundColor: "#598F57",
                                      },
                                      padding: "8px 16px",
                                    }}
                                    variant="contained"
                                  >
                                    Learn More
                                  </Button>
                                </Box>
                              </Card>
                            </Grid>
                          )
                        )
                      : null}
                  </Grid>
                </>
              )}
            </>
          )
        )}
      </Box>
    </>
  );
};

export default UVWeatherDisplay;

import React from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import NearbyGreenSpaces from "./components/NearbyGreenSpaces";
import NavigationPage from "./components/NavigationPage";
import PersistentDrawer from "./components/PersistentDrawer";
import LoginPage from "./components/LoginPage";
import PrivateRoute from "./components/PrivateRoute";
import ExploreGreenSpacePage from "./components/Epic4/ExploreGreenSpacePage";
import LandingPage from "./components/LandingPage";
import WhyGreenSpaces from "./components/WhyGreenSpaces";
import AssessmentPage from "./components/AssessmentPage";
import QuestionPage from "./components/QuestionPage";
import PersonalizedResults from "./components/PersonalizedResults";
import TripSummaryPage from "./components/Epic4/TripSummaryPage";
import GoGreenPage from "./components/GoGreen";
import MoreGreenPage from "./components/MoreGreenPage";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="App">
      <ScrollToTop /> {/* Ensure this component is included */}
      <Routes>
        {/* Public Route for Login */}
        <Route path="/login" element={<LoginPage />} />

        {/* Protected Routes */}
        <Route
          path="*"
          element={
            <PrivateRoute
              element={
                <PersistentDrawer
                  content={
                    <Routes>
                      <Route
                        path="/nearby-green-spaces"
                        element={<NearbyGreenSpaces />}
                      />
                      <Route path="/navigate" element={<NavigationPage />} />

                      <Route
                        path="/explore-park"
                        element={<ExploreGreenSpacePage />}
                      />

                      <Route path="/LandingPage" element={<LandingPage />} />
                      <Route
                        path="/why-green-spaces"
                        element={<WhyGreenSpaces />}
                      />
                      <Route path="/assessment" element={<AssessmentPage />} />
                      <Route path="/question-page" element={<QuestionPage />} />
                      <Route
                        path="/personalized-results"
                        element={<PersonalizedResults />}
                      />
                      <Route
                        path="/trip-summary"
                        element={<TripSummaryPage />}
                      />
                      <Route path="/more-green" element={<MoreGreenPage />} />

                      <Route path="/go-green" element={<GoGreenPage />} />

                      <Route
                        path="*"
                        element={<Navigate to="/LandingPage" />}
                      />
                    </Routes>
                  }
                />
              }
            />
          }
        />

        {/* Redirect any unmatched routes to login */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Slider,
  Rating,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ExploreMap from "./ExploreMap"; // Import the ExploreMap component
import UVWeatherDisplay from "./UVWeatherDisplay";
import ParkIcon from "@mui/icons-material/Park"; // Icon for park
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios"; // For API calls
import { WbSunny as SunIcon } from "@mui/icons-material";
import CustomStepper from "../CustomStepper";
import { styled } from "@mui/system";
import { keyframes } from "@mui/system";
import StickyNote from "./StickyNote";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScrollToNext from "../../components/ScrollToNext";
import loadingGif from "../../assets/images/research2.gif";

// Import icons for the amenities (replace with the actual paths or icon components)
import toiletIcon from "../../assets/exploreIcon/toilet.png";
import barbecueIcon from "../../assets/exploreIcon/barbecue.png";
import bicycleRailsIcon from "../../assets/exploreIcon/bicycle_rails.png";
import binIcon from "../../assets/exploreIcon/bin.png";
import drinkingFountainIcon from "../../assets/exploreIcon/drinking_fountain.png";
import picnicSettingIcon from "../../assets/exploreIcon/picnic_setting.png";
import playgroundIcon from "../../assets/exploreIcon/playground.png";
import seatIcon from "../../assets/exploreIcon/seat.png";
import CheckCircleIcon from "@mui/icons-material/Check"; // Check icon
import CancelIcon from "@mui/icons-material/Close"; // Cross icon
import PeopleIcon from "@mui/icons-material/PeopleAlt";
import petIcon from "../../assets/exploreIcon/dog.png"; // Pet-friendly icon path
import wheelchairIcon from "../../assets/exploreIcon/wheelchair.png"; // Wheelchair

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
// import slotShouldForwardProp from "@mui/material/styles/slotShouldForwardProp";
// import { DoubleClickZoomHandler } from "mapbox-gl";

const ExploreParkPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [transportMode, setTransportMode] = useState("walking");
  const [isNoteOpen, setIsNoteOpen] = useState(true);
  const {
    park,
    placeId,
    category,
    calculatedCarbonSavings = 0,
  } = location.state || {};
  console.log("Received carbon savings:", calculatedCarbonSavings);

  // const [timeSpent, setTimeSpent] = useState(0); // Track time in seconds
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    let storedStartTime = sessionStorage.getItem("startTime");

    if (!storedStartTime) {
      storedStartTime = Date.now(); // Set start time as the current time
      sessionStorage.setItem("startTime", storedStartTime); // Store it in sessionStorage
    }

    setStartTime(parseInt(storedStartTime, 10)); // Store startTime in state
  }, []);

  const ScrollArrow = styled("div")({
    marginTop: "8px",
    width: "24px",
    height: "24px",
    color: "#F5F2EC",
    borderLeft: "2px solid #F5F2EC",
    borderBottom: "2px solid #F5F2EC",
    transform: "rotate(315deg)", // Adjust the arrow to point down
    animation: "bounce 1.5s infinite",
    "@keyframes bounce": {
      "0%, 20%, 50%, 80%, 100%": {
        transform: "translateY(0) rotate(315deg)", // Arrow points down
      },
      "40%": {
        transform: "translateY(-10px) rotate(315deg)", // Move up 10px
      },
      "60%": {
        transform: "translateY(-5px) rotate(315deg)", // Move up 5px
      },
    },
  });

  useEffect(() => {
    const scrollablePanel = document.getElementById("scrollable-right-column");
    const scrollIndicator = document.getElementById("scroll-panel-indicator");

    const handlePanelScroll = () => {
      if (scrollablePanel && scrollIndicator) {
        const scrollTop = scrollablePanel.scrollTop;
        const scrollHeight = scrollablePanel.scrollHeight;
        const clientHeight = scrollablePanel.clientHeight;

        // Check if the user has scrolled to the bottom of the panel
        if (scrollTop + clientHeight >= scrollHeight) {
          // Hide the scroll indicator when at the bottom
          scrollIndicator.style.opacity = "0";
        } else if (scrollTop === 0) {
          // Hide the scroll indicator when at the top
          scrollIndicator.style.opacity = "1";
        } else {
          // Show the arrow if the user is scrolling
          scrollIndicator.style.opacity = "1";
        }
      }
    };

    if (scrollablePanel) {
      scrollablePanel.addEventListener("scroll", handlePanelScroll);
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      if (scrollablePanel) {
        scrollablePanel.removeEventListener("scroll", handlePanelScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollIndicator = document.getElementById("scroll-indicator");

      // Add null check to prevent errors if the element is not found
      if (!scrollIndicator) return;

      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;

      if (scrollTop + windowHeight >= documentHeight) {
        // User has reached the bottom
        scrollIndicator.style.opacity = "0"; // Hide the arrow
      } else {
        scrollIndicator.style.opacity = "1"; // Show the arrow
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to handle "End this Trip" click and navigate to the summary page
  const handleEndTripClick = () => {
    const endTime = Date.now(); // Get the current time
    const totalTimeInMinutes = Math.floor((endTime - startTime) / 60000); // Calculate time spent in minutes
    const carbonSavings = location.state?.calculatedCarbonSavings || 0; // Access carbonSavings from location.state

    // Clear session storage
    console.log("Calculated carbon savings before passing:", carbonSavings);
    sessionStorage.removeItem("startTime");

    navigate("/trip-summary", {
      state: {
        park,
        timeSpent: totalTimeInMinutes, // Pass the calculated time in minutes
        transportMode,
        carbonSavings,
      },
    });
  };

  // Define parkCoordinates first before using it
  const parkCoordinates = park?.coords
    ? {
        latitude: parseFloat(park.coords.split(",")[0].replace(/[()]/g, "")),
        longitude: parseFloat(park.coords.split(",")[1].replace(/[()]/g, "")),
      }
    : null;

  const [mapCenter] = useState([
    parkCoordinates?.longitude,
    parkCoordinates?.latitude,
  ]); // Initial map center
  const [mapZoom] = useState(12); // Initial zoom level

  const [selectedTab, setSelectedTab] = useState(0); // Tab state
  const [amenities, setAmenities] = useState({}); // Store amenities data
  const [selectedAmenity, setSelectedAmenity] = useState(null); // Store the selected amenity
  const [directions, setDirections] = useState({ route: null, steps: [] }); // Store turn-by-turn directions and route
  const [recommendations, setRecommendations] = useState([]); // Store filtered recommendations

  const [crowdingData, setCrowdingData] = useState([]);
  const [selectedHour, setSelectedHour] = useState(new Date().getHours());
  const [uvData, setUvData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [originalRecommendations, setOriginalRecommendations] = useState([]);
  const [currentHour] = useState(new Date().getHours());
  const [isCollapsed, setIsCollapsed] = useState(true); // Initially collapsed
  const [uvIndex, setUvIndex] = useState(null);
  const [showGeoPoints, setShowGeoPoints] = useState(true); // New state for geo point visibility

  // State for amenities filter
  const [selectedAmenities, setSelectedAmenities] = useState([]);

  // Define amenity icons
  const amenityIcons = {
    Toilets: toiletIcon,
    Barbecues: barbecueIcon,
    "Bicycle Rails": bicycleRailsIcon,
    "Litter Bin": binIcon,
    "Drinking Fountain": drinkingFountainIcon,
    "Picnic Setting": picnicSettingIcon,
    Playgrounds: playgroundIcon,
    Seat: seatIcon,
  };

  const handleGeoPointToggle = () => {
    setShowGeoPoints((prev) => !prev); // Toggle geo point visibility
  };
  const mapboxToken =
    "pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q";

  // Fetch UV data when the component mounts
  useEffect(() => {
    axios
      .get(
        `https://api.greenfinderinmelb.me/api/fetch-uv-data?place_id=${placeId}&category=${category}`
      )
      .then((response) => {
        console.log("Full UV data response:", response.data); // Log the full response

        const currentHour = new Date().getHours(); // Get current hour in local time
        const currentDate = new Date(); // Get the current date and time

        // Match the UV data by finding the timestamp closest to the current hour
        const matchingUvData = Object.entries(response.data).find(([key]) => {
          const timestamp = new Date(key); // Convert the key (timestamp) to a Date object
          return (
            timestamp.getHours() === currentHour &&
            timestamp.getDate() === currentDate.getDate() &&
            timestamp.getMonth() === currentDate.getMonth() &&
            timestamp.getFullYear() === currentDate.getFullYear()
          );
        });

        console.log("Matching UV data:", matchingUvData);

        if (matchingUvData) {
          const uvDataForCurrentHour = matchingUvData[1]; // Get the UV data
          setUvIndex(uvDataForCurrentHour.uv_index); // Store the UV index
        } else {
          console.warn("No UV data found for the current hour"); // Warn if no data found
        }
        setLoading(false); // Set loading to false
      })
      .catch((error) => {
        console.error("Error fetching UV data:", error); // Log any errors
        setLoading(false);
      });
  }, [placeId, category]);

  // Fetch amenities data when the component mounts
  useEffect(() => {
    console.log(
      "placeId:",
      placeId,
      "category:",
      category,
      "parkCoordinates:",
      parkCoordinates
    );

    if (park && placeId && category) {
      axios
        .get(
          `https://api.greenfinderinmelb.me/api/locate-amenities?place_id=${placeId}&category="${category}"`
        )
        .then((response) => {
          console.log("API Response:", response.data); // Log the API response
          setAmenities(response.data); // Store the fetched amenities
        })
        .catch((error) => {
          console.error("Error fetching amenities:", error);
        });
    }
  }, [park, placeId, category]);

  const handleAmenityClick = (amenity) => {
    setSelectedAmenities(
      (prev) =>
        prev.includes(amenity)
          ? prev.filter((item) => item !== amenity) // Remove if already selected
          : [...prev, amenity] // Add if not selected
    );
  };

  const renderAmenityButtons = () => {
    if (!amenities || Object.keys(amenities).length === 0) {
      return <Typography>No amenities available for this park.</Typography>;
    }

    const availableAmenities = Object.keys(amenities).filter(
      (amenity) => amenities[amenity]?.length > 0 && amenityIcons[amenity]
    );

    if (availableAmenities.length === 0) {
      return (
        <Typography>No amenities filter available for this park.</Typography>
      );
    }

    return (
      <>
        {/* Add button to toggle geo points (shaded area points) */}
        {/* <Button
          key="geoPointToggle"
          onClick={handleGeoPointToggle}
          variant={showGeoPoints ? "contained" : "outlined"}
          sx={{
            margin: 0.5,
            padding: "4px 8px",
            fontSize: "0.75rem",
            backgroundColor: showGeoPoints ? "#4CAF50" : "transparent",
            color: showGeoPoints ? "#fff" : "#9E9E9E",
            borderColor: showGeoPoints ? "#4CAF50" : "#9E9E9E",
            "&:hover": {
              backgroundColor: showGeoPoints ? "#66BB6A" : "#EEEEEE",
            },
          }}
        >
          Shaded Area
        </Button> */}

        {/* Render buttons for available amenities */}
        <Box display="flex" flexWrap="wrap" gap={2} mt={0.5}>
          {availableAmenities.map((amenity) => (
            <Button
              key={amenity}
              onClick={() => handleAmenityClick(amenity)}
              variant={
                selectedAmenities.includes(amenity) ? "contained" : "outlined"
              }
              sx={{
                fontSize: { xs: "0.7rem", md: "0.8rem" }, // Adjust button font size for mobile
                padding: { xs: "4px 8px", md: "6px 12px" }, // Adjust padding
                margin: "5px",
                backgroundColor: selectedAmenities.includes(amenity)
                  ? "#4CAF50"
                  : "transparent",
                color: selectedAmenities.includes(amenity) ? "#fff" : "#9E9E9E",
                borderColor: selectedAmenities.includes(amenity)
                  ? "#4CAF50"
                  : "#9E9E9E",
                "&:hover": {
                  backgroundColor: selectedAmenities.includes(amenity)
                    ? "#66BB6A"
                    : "#EEEEEE",
                },
              }}
            >
              <img
                src={amenityIcons[amenity]}
                alt={amenity}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "8px",
                }}
              />
              {amenity}
            </Button>
          ))}
        </Box>
      </>
    );
  };

  const handleToggleCollapse = () => {
    setIsCollapsed((prev) => !prev); // Toggle between collapsed and expanded
  };

  // Fetch park info
  useEffect(() => {
    if (placeId && category) {
      setLoading(true);
      axios
        .get(
          `https://api.greenfinderinmelb.me/api/place-info?place_id=${placeId}&category=${category}`
        )
        .then((response) => {
          console.log("Park Info:", response.data);
          setParkInfo(response.data);
        })
        .catch((err) => {
          setError("Error fetching park info");
        })
        .finally(() => setLoading(false));
    }
  }, [placeId, category]);

  useEffect(() => {
    axios
      .get(
        `https://api.greenfinderinmelb.me/api/fetch-uv-data?place_id=99&category=Parks and gardens`
      )
      .then((response) => {
        setUvData(response.data); // Store the fetched UV data
      })
      .catch((error) => {
        console.error("Error fetching UV data:", error);
      });
  }, []);

  // Retrieve recommendations from sessionStorage and filter them based on crowding_level and area
  useEffect(() => {
    const savedRecommendations =
      JSON.parse(sessionStorage.getItem("top10GreenSpaces")) || [];

    if (savedRecommendations.length > 0 && park) {
      const filteredRecommendations = savedRecommendations
        .filter(
          (space) =>
            space.id !== park.id &&
            (space.crowding_level === "Low" ||
              (space.crowding_level === "Medium" &&
                park.crowding_level !== "Low") ||
              (space.crowding_level === "High" &&
                park.crowding_level === "High"))
        )
        .sort((a, b) => a.area - b.area) // Sort by the smallest area
        .slice(0, 3); // Get top 3 suggestions

      setRecommendations(filteredRecommendations);

      // Set the original recommendations only once
      if (originalRecommendations.length === 0) {
        setOriginalRecommendations(filteredRecommendations);
      }
    }
  }, [park]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getDirectionIcon = (maneuver) => {
    const { type, modifier } = maneuver; // Extract both type and modifier
    console.log("Maneuver type:", type, "Modifier:", modifier); // Log for debugging

    // Check for 'turn' type and differentiate using 'modifier'
    if (type === "turn") {
      switch (modifier) {
        case "left":
          return <ArrowBackIcon sx={{ color: "#388E3C", fontSize: "2rem" }} />; // Left turn icon
        case "right":
          return (
            <ArrowForwardIcon sx={{ color: "#388E3C", fontSize: "2rem" }} />
          ); // Right turn icon
        case "slight-left":
          return <ArrowBackIcon sx={{ color: "#388E3C", fontSize: "2rem" }} />; // Slight left icon
        case "slight-right":
          return (
            <ArrowForwardIcon sx={{ color: "#388E3C", fontSize: "2rem" }} />
          ); // Slight right icon
        case "straight":
          return (
            <ArrowUpwardIcon sx={{ color: "#388E3C", fontSize: "2rem" }} />
          ); // Go straight
        default:
          return (
            <DirectionsWalkIcon sx={{ color: "#388E3C", fontSize: "2rem" }} />
          ); // Default walking direction
      }
    }

    // Handle other types like depart, arrive, roundabout, etc.
    switch (type) {
      case "depart":
        return <ArrowUpwardIcon sx={{ color: "#388E3C", fontSize: "2rem" }} />; // Departure (straight)
      case "arrive":
        return <CheckCircleIcon sx={{ color: "#388E3C", fontSize: "2rem" }} />; // Arrival (destination)
      case "roundabout":
        if (modifier === "left") {
          return <ArrowBackIcon sx={{ color: "#388E3C", fontSize: "2rem" }} />; // Roundabout left
        } else if (modifier === "right") {
          return (
            <ArrowForwardIcon sx={{ color: "#388E3C", fontSize: "2rem" }} />
          ); // Roundabout right
        }
        break;
      default:
        return (
          <DirectionsWalkIcon sx={{ color: "#388E3C", fontSize: "2rem" }} />
        ); // Default walking direction
    }
  };

  // Function to handle the click on a recommendation and swap parks
  const handleParkClick = (selectedPark) => {
    // Swap the selected park with the current park
    const newRecommendations = recommendations.map((rec) =>
      rec.id === selectedPark.id ? park : rec
    );

    // Navigate to the selected park's page and pass the state
    navigate("/explore-park", {
      state: {
        park: selectedPark,
        placeId: selectedPark.id,
        category: selectedPark.category,
      },
    });

    // Update the recommendations list with the swapped parks
    setRecommendations(newRecommendations);
  };

  const navigateAmenityClick = (amenity) => {
    setSelectedAmenity({
      ...amenity,
      type: Object.keys(amenities).find((key) =>
        amenities[key].includes(amenity)
      ), // Find the amenity type
    });
    setDirections({ route: null, steps: [] }); // Clear previous directions
    setSelectedTab(1); // Switch to the second tab for directions
    console.log(`Navigating to: ${amenity.type}`);
  };

  // Filter amenities based on selectedAmenities
  const filterAmenitiesBySelection = (amenities, selectedAmenities) => {
    if (selectedAmenities.length === 0) {
      return amenities; // Return all amenities if nothing is selected
    }
    const filteredAmenities = {};
    Object.keys(amenities).forEach((amenityType) => {
      if (selectedAmenities.includes(amenityType)) {
        filteredAmenities[amenityType] = amenities[amenityType]; // Keep only the selected amenity types
      }
    });
    return filteredAmenities;
  };

  const handleStartNavigate = () => {
    if (!selectedAmenity || !parkCoordinates) return;

    // Fetch directions from Mapbox Directions API
    const directionsUrl = `https://api.mapbox.com/directions/v5/mapbox/walking/${parkCoordinates.longitude},${parkCoordinates.latitude};${selectedAmenity.lon},${selectedAmenity.lat}?steps=true&geometries=geojson&access_token=${mapboxToken}`;

    axios
      .get(directionsUrl)
      .then((response) => {
        const route = response.data.routes[0].geometry;
        const steps = response.data.routes[0].legs[0].steps;

        // Instead of directly interacting with mapboxgl, update the state
        setDirections({ route, steps });
      })
      .catch((error) => {
        console.error("Error fetching directions:", error);
      });
  };

  const [parkInfo, setParkInfo] = useState(null);

  // Call the API when the component mounts
  useEffect(() => {
    // Function to make the API call
    // Function to make the API call
    const fetchCrowdingData = async () => {
      const now = new Date();
      const current_time = now.toISOString(); // ISO format of the current time

      const data = {
        place_id: placeId, // Use the place_id directly as a number
        current_time: current_time, // ISO string for the current time
        category: category, // Use the category as is
      };

      // Log the payload to confirm its structure
      console.log("Sending crowding data request with payload:", data);

      try {
        const response = await axios.post(
          "https://api.greenfinderinmelb.me/api/get-24-hour-predictions/",
          data,
          {
            headers: {
              "Content-Type": "application/json", // Ensure the correct header is set
            },
          }
        );

        if (response.data.status === "success") {
          setCrowdingData(response.data.predictions); // Set the crowding data
        } else {
          console.error("Unexpected API response:", response.data);
        }
      } catch (error) {
        console.error(
          "Error fetching crowding data:",
          error.response?.data || error.message
        );
      }
    };

    fetchCrowdingData();
  }, [placeId]);

  // Handle slider value change
  const handleSliderChange = (event, newValue) => {
    if (newValue < currentHour) {
      setSelectedHour(currentHour); // Snap back to current hour if the user tries to select a past hour
    } else {
      setSelectedHour(newValue); // Allow future hour selection
    }
  };

  // Function to render emoji based on crowding level
  const renderEmoji = (crowdingLevel) => {
    const emojiStyle = {
      fontSize: "3rem", // Adjust the font size
      margin: 0, // Remove margin to reduce blank space
      lineHeight: 1.5,
      display: "flex", // Use flexbox for centering
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      height: "100%", // Set line-height to 1 to prevent extra vertical space
    };

    switch (crowdingLevel) {
      case "Low":
        return <p style={emojiStyle}>&#x1F603;</p>; // Smiley Face
      case "Medium":
        return <p style={emojiStyle}>&#x1F610;</p>; // Neutral Face
      case "High":
        return <p style={emojiStyle}>&#x1F622;</p>; // Crying Face
      default:
        return <p style={emojiStyle}>&#x1F636;</p>; // Blank Face
    }
  };

  // Get the crowding level for the selected hour
  const crowdingLevelForSelectedHour =
    crowdingData.find((data) => new Date(data.time).getHours() === selectedHour)
      ?.crowding_level || "Unknown";

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh" // Full viewport height to center the gif
        sx={{
          backgroundColor: "#FAF9F6", // Match the background theme
        }}
      >
        <img
          src={loadingGif}
          alt="Loading..."
          style={{
            width: "100px", // Adjust size of the gif
            height: "100px",
          }}
        />
      </Box>
    );
  }

  if (error) {
    return <Typography>{error}</Typography>;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#FAF9F6",
        minHeight: "100vh",
        width: "100%",
        mb: { xs: 5, md: 10 }, // Adjust margin-bottom for small screens
        // padding: { xs: "0 10px", md: "0 10px" }, // Add padding for small screens
      }}
    >
      <Box
        sx={{
          mb: { xs: -6, md: 0 }, // Adjust margin-bottom for mobile
          padding: { xs: "10px 15px", md: "15px 20px" }, // Adjust padding
          mt: { xs: "60px", md: "0" }, // Add top margin for small screens to avoid overlap with navbar
          position: "relative", // Ensure positioning doesn't conflict with other elements
          zIndex: 100, // Make sure the button is on top of other content
        }}
      >
        <Button
          variant="outlined"
          onClick={() => navigate(-1)}
          sx={{
            color: "#1B5E20",
            borderColor: "#1B5E20",
            "&:hover": {
              backgroundColor: "#e0f2f1",
              borderColor: "#1B5E20",
            },
            fontWeight: "bold",
            marginRight: "10px",
          }}
        >
          Back
        </Button>
      </Box>

      {/* ScrollToNext Component */}
      <ScrollToNext />

      <CustomStepper activeStep={2} />
      {/* Title Section (Full-width) */}
      <Box
        sx={{
          padding: "0px 20px",
          textAlign: "center",
          borderRadius: "30px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "#ffffff",
        }}
      >
        {/* Main Title
        <Typography
          variant="h2"
          sx={{
            fontWeight: "700",
            fontSize: "2rem",
            letterSpacing: "2px",
            lineHeight: "1.3",
            marginBottom: "5px",
            color: "#417446",
          }}
        >
          Explore {park.name}
        </Typography> */}

        {/* Decorative Element (Optional) */}
        <Box
          sx={{
            height: "4px",
            width: "80px",
            background: "#4CAF50",
            borderRadius: "2px",
            marginTop: "10px",
          }}
        />
      </Box>
      {/* Content Section */}
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }} // Column for small screens, row for larger
        gap={0}
        justifyContent="center"
        alignItems="start"
        mt={3}
        ml={1}
        mr={1}
      >
        <Box
          flex={{ xs: 12, md: 6 }} // Full width for small screens, half for larger screens
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: { xs: "70vh", sm: "70vh", md: "500px" },
            width: "100%",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            marginLeft: { xs: "0px", md: "20px" }, // Add margin to the left
            // padding: "10px",
            marginBottom: { xs: "15px", md: "0px" },
          }}
        >
          {/* Sticky Note Component */}
          <StickyNote
            open={isNoteOpen}
            onClose={() => setIsNoteOpen(false)}
            onOpen={() => setIsNoteOpen(true)}
          />
          <ExploreMap
            parkCoordinates={parkCoordinates}
            amenities={filterAmenitiesBySelection(amenities, selectedAmenities)}
            selectedAmenities={selectedAmenities}
            parkName={park.name}
            onAmenityClick={navigateAmenityClick} // Handle amenity click
            directions={directions.route} // Pass the route to the map
            placeId={placeId} // Ensure placeId is passed
            category={category} // Ensure category is passed
            mapCenter={mapCenter} // Pass map center to ExploreMap
            mapZoom={mapZoom} // Pass map zoom level to ExploreMap
            setSelectedAmenity={setSelectedAmenity}
            showGeoPoints={showGeoPoints}
            setShowGeoPoints={setShowGeoPoints}
          />
        </Box>
        {/* Right Side */}
        {/* Legend Section */}

        {/* Right Side Scrollable Section */}
        <Box
          id="scrollable-right-column"
          flex={4}
          sx={{
            padding: 0,
            backgroundColor: "#f1f8e9",
            borderRadius: "12px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            height: { xs: "80vh", sm: "70vh", md: "500px" }, // Ensure it fits well on small screens
            maxHeight: { xs: "80vh", sm: "70vh", md: "500px" }, // Prevents overflow on small screens
            width: { xs: "100%", md: "auto" }, // Full width for smaller screens
            overflowY: "auto", // Enable vertical scrolling
            border: "1px solid #66bb6a",
            position: "relative",
            margin: { xs: "0", md: "0 20px" }, // Remove margin for small screens
            "&::-webkit-scrollbar": {
              width: "15px", // Width of the scrollbar
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#598F57", // Scrollbar color
              borderRadius: "10px", // Rounded scrollbar thumb
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#66BB6A", // Color when hovering over scrollbar
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f8e9", // Track color behind the scrollbar
            },
            "&::-webkit-scrollbar-track:hover": {
              backgroundColor: "#e0f2f1", // Track color when hovering
            },
            scrollbarColor: "#598F57 #f1f8e9", // Firefox scrollbar colors
            scrollbarWidth: "thin", // Firefox scrollbar width
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#f1f8e9",
              zIndex: 1,
              borderBottom: "2px solid #66bb6a",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Tab
              label="Get to know this place"
              icon={
                <InfoIcon
                  sx={{
                    fontSize: "1.5rem",
                    color: selectedTab === 0 ? "#1B5E20" : "#555",
                  }}
                />
              }
              sx={{
                fontWeight: selectedTab === 0 ? "bold" : "normal",
                color: selectedTab === 0 ? "#1B5E20" : "#555",
                backgroundColor: selectedTab === 0 ? "#a5d6a7" : "transparent",
                borderRadius: "12px",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#81c784",
                },
              }}
            />
            <Tab
              label="Explore what's inside"
              icon={
                <ParkIcon
                  sx={{
                    fontSize: "1.5rem",
                    color: selectedTab === 1 ? "#1B5E20" : "#555",
                  }}
                />
              }
              sx={{
                fontWeight: selectedTab === 1 ? "bold" : "normal",
                color: selectedTab === 1 ? "#1B5E20" : "#555",
                backgroundColor: selectedTab === 1 ? "#a5d6a7" : "transparent",
                borderRadius: "12px",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#81c784",
                },
              }}
            />
          </Tabs>
          {/* Scroll Indicator
          <Box
            id="scroll-panel-indicator"
            sx={{
              position: "absolute",
              bottom: "10px", // Keep it at the bottom of the panel
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1000,
              opacity: 1, // Initially visible
              transition: "opacity 0.5s ease", // Smooth fade-out transition
            }}
          >
            <ScrollArrow />
          </Box> */}
          {/* Tab Content */}
          {selectedTab === 0 && (
            <Box pt={0} px={2}>
              <Box
                mb={1}
                sx={{
                  backgroundColor: "#f1f8e9",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                {/* Green Space Name */}
                {park.name && (
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#1B5E20",
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    {park.name}
                  </Typography>
                )}
                <Box mb={2} display="flex" alignItems="flex-start">
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      color: "#388E3C",
                      minWidth: { xs: "100px", md: "170px" }, // Ensure labels have equal width for alignment
                      marginRight: "10px", // Add a small space between the label and value
                    }}
                  >
                    Opening Hours:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#388E3C",
                      fontWeight: "530",
                      whiteSpace: "pre-wrap",
                    }} // Allow text to wrap
                  >
                    {parkInfo.opening_hours
                      ? parkInfo.opening_hours
                          .split(", ")
                          .map((hours, index) => (
                            <span key={index}>
                              {hours}
                              {index <
                                parkInfo.opening_hours.split(", ").length -
                                  1 && <br />}
                              {/* Add a line break between days */}
                            </span>
                          ))
                      : "No opening hours available"}
                  </Typography>
                </Box>
                {/* User Rating Section */}
                <Box display="flex" alignItems="center" mt={2} sx={{ mb: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "#388E3C",
                      minWidth: "180px",
                      maxWidth: "100%", // Ensure the content does not overflow horizontally
                      overflowWrap: "break-word", // Handle long content
                    }}
                  >
                    User Rating:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: -1,
                      "&:hover .rating-value": {
                        opacity: 1, // Show the rating when hovered
                        transform: "translate(-250%, -100%)",
                      },
                      "&:hover .MuiRating-icon": {
                        transform: "scale(1.2)",
                        transition: "transform 0.2s ease-in-out",
                      },
                      "& .rating-value": {
                        opacity: 0, // Hidden by default
                        transform: "translate(-50%, -100%)", // Start in the default centered position
                        transition:
                          "opacity 0.2s ease-in-out, transform 0.2s ease-in-out", // Smooth transition for hover and hover out
                      },
                    }}
                  >
                    <Rating
                      value={parseFloat(parkInfo.user_rating)}
                      readOnly
                      precision={0.5}
                      sx={{ color: "gold", fontSize: "1.5rem" }}
                    />
                    <Typography
                      className="rating-value"
                      sx={{
                        ml: -1,
                        color: "#388E3C",
                        fontWeight: "bold",
                        fontSize: "1.0rem",
                        opacity: 0,
                        transition: "opacity 0.2s ease-in-out",
                      }}
                    >
                      {parseFloat(parkInfo.user_rating).toFixed(1)}
                    </Typography>
                    {/* Info Icon with Tooltip */}
                    <Tooltip title="This rating is based on Google Review.">
                      <IconButton
                        sx={{ ml: -2 }}
                        size="small"
                        aria-label="info about user rating"
                      >
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" mt={2} sx={{ mb: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "#388E3C",
                      minWidth: "180px",
                    }}
                  >
                    Sustainability Score:
                  </Typography>

                  {/* Sustainability Score Section */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      ml: -1,
                      "&:hover .sustainability-value": {
                        opacity: 1, // Show the score when hovered
                        transform: "translate(-250%, -100%)", // Move the score upwards and left when hovered
                      },
                      "&:hover .MuiRating-icon": {
                        transform: "scale(1.2)", // Scale up stars when hovered
                        transition: "transform 0.2s ease-in-out",
                      },
                      "& .sustainability-value": {
                        opacity: 0, // Hidden by default
                        transform: "translate(-50%, -100%)", // Start in the default centered position
                        transition:
                          "opacity 0.2s ease-in-out, transform 0.2s ease-in-out", // Smooth transition for hover and hover out
                      },
                    }}
                  >
                    <Rating
                      value={parseFloat(parkInfo.sustainability_score)}
                      readOnly
                      precision={0.5}
                      sx={{ color: "#66BB6A", fontSize: "1.5rem" }}
                    />
                    <Typography
                      className="sustainability-value"
                      sx={{
                        ml: -1,
                        color: "#388E3C",
                        fontWeight: "bold",
                        fontSize: "1.0rem",
                        opacity: 0,
                        transition: "opacity 0.2s ease-in-out",
                      }}
                    >
                      {parseFloat(parkInfo.sustainability_score).toFixed(1)}
                    </Typography>
                    {/* Info Icon with Tooltip */}
                    <Tooltip title="This sustainablility score based on Wikipedia sustainable features, amenities promoting sustainable living, average user rating, and Google review.">
                      <IconButton
                        sx={{ ml: -2 }}
                        size="small"
                        aria-label="info about sustainable score"
                      >
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                {/* Crowd Level Section */}
                <Box display="flex" alignItems="center" mt={2} sx={{ mb: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "#388E3C",
                      minWidth: "180px",
                    }}
                  >
                    Crowd Level:
                  </Typography>
                  <Typography
                    sx={{
                      ml: -1,
                      color:
                        crowdingLevelForSelectedHour === "Low"
                          ? "#388E3C"
                          : crowdingLevelForSelectedHour === "Medium"
                          ? "#F57C00"
                          : crowdingLevelForSelectedHour === "High"
                          ? "#D32F2F"
                          : "#9E9E9E", // Default color for unknown
                      fontWeight: "bold",
                      fontSize: "1.0rem",
                    }}
                  >
                    {crowdingLevelForSelectedHour}
                  </Typography>
                  {/* Learn More button for Crowd Level */}
                  <Button
                    size="small"
                    sx={{
                      backgroundColor: "#598F57", // Updated background color
                      color: "white",
                      padding: { xs: "4px 8px", md: "6px 12px" }, // Responsive padding for small and larger screens
                      fontSize: { xs: "10px", md: "12px" }, // Responsive font size
                      fontWeight: "bold",
                      borderRadius: "8px",
                      textTransform: "none", // Maintain original text transformation
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      ml: "auto",
                      "&:hover": {
                        backgroundColor: "#45A049", // Updated hover background color
                      },
                    }}
                    onClick={() => {
                      document
                        .getElementById("crowd-level-details")
                        .scrollIntoView({
                          behavior: "smooth",
                        });
                    }}
                  >
                    Learn More
                  </Button>
                </Box>
                {/* UV Index Section */}
                {!loading && (
                  <Box display="flex" alignItems="center" mt={2}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "#388E3C",
                        minWidth: "180px",
                      }}
                    >
                      UV Index:
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color:
                          uvIndex < 3
                            ? "#66BB6A"
                            : uvIndex < 6
                            ? "#FFEB3B"
                            : uvIndex < 8
                            ? "#FF9800"
                            : uvIndex < 11
                            ? "#F44336"
                            : "#9C27B0",
                        fontSize: "1.2rem",
                        ml: -1,
                      }}
                    >
                      {uvIndex !== null ? uvIndex : "N/A"}
                    </Typography>
                    {/* Learn More button for UV Index */}
                    <Button
                      size="small"
                      sx={{
                        backgroundColor: "#598F57", // Updated background color
                        color: "white",
                        padding: { xs: "4px 8px", md: "6px 12px" }, // Responsive padding for small and larger screens
                        fontSize: { xs: "10px", md: "12px" }, // Responsive font size
                        fontWeight: "bold",
                        borderRadius: "8px",
                        textTransform: "none", // Maintain original text transformation
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        ml: "auto",
                        "&:hover": {
                          backgroundColor: "#45A049", // Updated hover background color
                        },
                      }}
                      onClick={() => {
                        document
                          .getElementById("uv-weather-details")
                          .scrollIntoView({
                            behavior: "smooth",
                          });
                      }}
                    >
                      Learn More
                    </Button>
                  </Box>
                )}
                {/* Divider between this section and the next */}
                <Divider sx={{ borderColor: "#81C784", my: 2 }} />{" "}
                {/* Another subtle divider */}
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                  sx={{ mb: 2 }}
                >
                  {/* Combined Pet Friendly and Wheelchair Accessibility Section */}
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "#388E3C",
                      minWidth: "180px",
                    }}
                  >
                    More Info
                  </Typography>

                  {/* Display the icons and statuses together */}
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    mt={1}
                  >
                    {/* Pet Friendly Section */}
                    <Tooltip
                      title={
                        parkInfo.pet_friendly === "Yes"
                          ? "Pet Friendly"
                          : "Pets Not Allowed"
                      }
                    >
                      <Button
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor:
                            parkInfo.pet_friendly === "Yes"
                              ? "#388E3C"
                              : "#D3D3D3", // Green for allowed, Grey for not allowed
                          color:
                            parkInfo.pet_friendly === "Yes" ? "#fff" : "#888", // Adjust the text color
                          marginRight: 2,
                          "&:hover": {
                            backgroundColor:
                              parkInfo.pet_friendly === "Yes"
                                ? "#66BB6A"
                                : "#E0E0E0", // Hover effect
                          },
                        }}
                      >
                        <img
                          src={petIcon}
                          alt="Pet Friendly"
                          style={{
                            width: "24px",
                            height: "24px",
                            marginRight: "8px",
                          }}
                        />
                        {parkInfo.pet_friendly === "Yes"
                          ? "Allowed"
                          : "Not Allowed"}
                      </Button>
                    </Tooltip>

                    {/* Wheelchair Accessible Section */}
                    <Tooltip
                      title={
                        parkInfo.wheelchair_accessibility === "Yes"
                          ? "Wheelchair Accessible"
                          : "Not Wheelchair Accessible"
                      }
                    >
                      <Button
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor:
                            parkInfo.wheelchair_accessibility === "Yes"
                              ? "#388E3C"
                              : "#D3D3D3", // Green for allowed, Grey for not allowed
                          color:
                            parkInfo.wheelchair_accessibility === "Yes"
                              ? "#fff"
                              : "#888", // Adjust the text color
                          "&:hover": {
                            backgroundColor:
                              parkInfo.wheelchair_accessibility === "Yes"
                                ? "#66BB6A"
                                : "#E0E0E0", // Hover effect
                          },
                        }}
                      >
                        <img
                          src={wheelchairIcon}
                          alt="Wheelchair Accessible"
                          style={{
                            width: "24px",
                            height: "24px",
                            marginRight: "8px",
                          }}
                        />
                        {parkInfo.wheelchair_accessibility === "Yes"
                          ? "Accessible"
                          : "Not Accessible"}
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
                {/* Divider between this section and the next */}
                <Divider sx={{ borderColor: "#81C784", my: 2 }} />{" "}
                {/* Another subtle divider */}
                {/* User Vibes */}
                <Box mb={2}>
                  <Typography sx={{ fontWeight: "bold", color: "#388E3C" }}>
                    What People Are Saying:
                  </Typography>
                  <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                    {parkInfo.associated_keywords.map((vibe, index) => (
                      <Box
                        key={index}
                        variant="contained"
                        sx={{
                          bgcolor: "#388E3C", // Background color
                          color: "white", // Text color
                          fontSize: "1rem", // Adjust the font size
                          padding: "6px 12px", // Adjust the padding inside the button
                          minWidth: "80px", // Set the minimum width for the button
                          height: "36px", // Set a specific height for the button
                        }}
                      >
                        {vibe}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ borderColor: "#81C784", my: 2 }} />{" "}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center" // This centers the content horizontally
                justifyContent="center"
                sx={{
                  marginTop: 3, // Adjust the margin for spacing
                  textAlign: "center", // Ensure text aligns center
                }}
              >
                {/* End this Trip Button */}
                <Button
                  variant="contained"
                  onClick={handleEndTripClick}
                  sx={{
                    backgroundColor: "#1B5E20",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#2E7D32",
                    },
                    fontWeight: "bold",
                    mb: 1, // Add margin-bottom for spacing between the button and "or" text
                  }}
                >
                  Trip Summary
                </Button>

                {/* "Or" Text */}
                <Typography
                  variant="body1"
                  sx={{
                    color: "#757575", // Subtle grey color for the "or" text
                    fontWeight: "bold",
                    mb: 1, // Margin bottom to space it from the next button
                  }}
                >
                  or
                </Typography>
              </Box>
              {/* "More Like This" Section */}
              {originalRecommendations.length > 0 && (
                <Box mt={1}>
                  {/* Title */}

                  {/* Toggle Button */}
                  <Button
                    variant="outlined"
                    onClick={handleToggleCollapse}
                    sx={{
                      marginBottom: 2,
                      color: "#1B5E20",
                      borderColor: "#1B5E20",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    {isCollapsed
                      ? "Show More Like This"
                      : "Hide More Like This"}
                  </Button>

                  {/* Conditional Rendering of More Like This section */}
                  {!isCollapsed && (
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          mb: 2,
                          fontWeight: "bold",
                          color: "#1B5E20",
                          textAlign: "center",
                          fontSize: "20px",
                        }}
                      >
                        More Like This{" "}
                        <span style={{ fontSize: "24px" }}>✨</span>
                      </Typography>
                      {recommendations.map((space) => (
                        <Box
                          key={space.id}
                          sx={{
                            mb: 2, // Reduced margin bottom
                            padding: "12px", // Reduced padding
                            background: "#8FBC8B",
                            borderRadius: "12px", // Slightly smaller border radius
                            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)", // Adjusted shadow for a smaller card
                            transition: "all 0.3s ease-in-out",
                            "&:hover": {
                              boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
                              transform: "translateY(-3px)",
                            },
                            position: "relative",
                            maxWidth: "100%",
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            height="100%"
                          >
                            {/* Park Name */}
                            <Typography
                              variant="h7"
                              sx={{
                                color: "white",
                                fontWeight: "bold",
                                mb: 0.5, // Less margin at the bottom
                                textAlign: "center",
                                fontSize: "16px", // Reduced font size
                              }}
                            >
                              {space.name}
                            </Typography>

                            {/* Crowding Level */}
                            <Typography
                              variant="body2"
                              sx={{
                                color: "white",
                                textAlign: "center",
                                mb: 0.5,
                                fontSize: "14px",
                              }}
                            >
                              Crowding Level:
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color:
                                    space.crowding_level === "Low"
                                      ? "#388E3C"
                                      : space.crowding_level === "Medium"
                                      ? "#F57C00"
                                      : "#D32F2F",
                                }}
                              >
                                {` ${space.crowding_level}`}
                              </span>
                            </Typography>

                            {/* Distance */}
                            <Typography
                              variant="body2"
                              sx={{
                                color: "white",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              {space.distance?.toFixed(2)} km away
                            </Typography>

                            {/* Centered "Explore" Button */}
                            <Box display="flex" justifyContent="center" mt={1}>
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "#006400",
                                  color: "white",
                                  "&:hover": {
                                    backgroundColor: "#388E3C",
                                  },
                                  fontWeight: "bold",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "6px 24px", // Reduced padding for smaller button
                                  borderRadius: "15px", // Reduced border-radius
                                  textTransform: "none",
                                  fontSize: "14px", // Smaller button text
                                }}
                                onClick={() =>
                                  navigate("/explore-park", {
                                    state: {
                                      park: space,
                                      placeId: space.id,
                                      category: space.category,
                                    },
                                  })
                                }
                              >
                                <ParkIcon sx={{ mr: 0.5, fontSize: "18px" }} />{" "}
                                {/* Smaller icon */}
                                Explore
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
          {selectedTab === 1 && (
            <Box p={2}>
              {/* Instructional Guide Text */}
              {!selectedAmenity && (
                <Typography
                  variant="body1"
                  sx={{
                    mb: 3,
                    textAlign: "center",
                    fontStyle: "italic",
                    color: "#388E3C",
                  }}
                >
                  Click on an amenity on the map, then press the "Start
                  Navigate" button here to get the navigation guide.
                </Typography>
              )}

              {/* Once an Amenity is Selected */}
              {selectedAmenity && (
                <>
                  <Typography
                    variant="h6"
                    sx={{ mb: 2, fontWeight: "bold", color: "#1B5E20" }}
                  >
                    Navigate to {selectedAmenity?.type || "Shaded Area"}
                    {/* Display the selected amenity type */}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    Click the button below to start navigating to the{" "}
                    {selectedAmenity.type || "Shaded Area"}.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleStartNavigate}
                    sx={{ mt: 2 }}
                  >
                    Start Navigate
                  </Button>

                  {/* Display turn-by-turn directions */}
                  {directions && directions.steps && (
                    <Box mt={3}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#1B5E20" }}
                      >
                        Turn-by-Turn Directions:
                      </Typography>
                      {directions.steps.map((step, index) => (
                        <Box
                          key={index}
                          sx={{
                            borderRadius: "8px",
                            backgroundColor: "#f9f9f9",
                            padding: "10px",
                            marginBottom: "10px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            transition: "background-color 0.3s ease",
                            "&:hover": {
                              backgroundColor: "#e0f7fa",
                            },
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            {/* Render the MUI icon based on the maneuver */}
                            {getDirectionIcon(step.maneuver)}
                            <Typography
                              sx={{
                                fontSize: "15px",
                                fontWeight: "400",
                                marginLeft: "10px",
                              }}
                            >
                              Step {index + 1}: {step.maneuver.instruction}
                            </Typography>
                          </Box>
                          <Typography
                            variant="caption"
                            sx={{ color: "#757575" }}
                          >
                            {step.distance.toFixed(1)} meters
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {/* Amenity Filter Section */}
      <Box
        className="scroll-section"
        sx={{
          padding: "0px 25px",
          borderRadius: "10px",
          marginTop: "20px",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#1B5E20" }}>
          Click on the Amenity button(s) to filter basic amenity:
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={2} mt={2}>
          {renderAmenityButtons()}
        </Box>
      </Box>
      {/* Crowd Level Card */}
      <Box
        id="crowd-level-details"
        sx={{
          mt: 3, // Add space between sections
          border: "3px solid #417446", // Green border to match theme
          borderRadius: "15px", // Rounded corners
          backgroundColor: "#FAF9F6", // Light background to match the theme
          maxWidth: "100%", // Width constraint
          margin: "20px", // Center card
          padding: "0px", // Remove padding at the top to eliminate space
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
        }}
      >
        {/* Card Header */}
        <Box
          sx={{
            backgroundColor: "#417446", // Green header to match the theme
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            padding: "10px",
            display: "flex", // Use flexbox
            alignItems: "center", // Align items vertically in the center
          }}
        >
          <PeopleIcon sx={{ fontSize: "2.5rem", color: "#EFEAE0", mr: 1 }} />
          <Typography
            variant="h6"
            sx={{ color: "white", fontWeight: "bold", margin: "0px" }}
          >
            Crowd Level
          </Typography>
        </Box>

        {/* Crowd Level Card Content */}
        <Box
          sx={{
            display: "flex", // Use flexbox for layout
            justifyContent: "space-between", // Space between slider and legend
            alignItems: "center", // Center items vertically
            padding: "20px", // Add padding to the content
            flexDirection: { xs: "column", md: "row" }, // Responsive layout: column for small, row for larger
          }}
        >
          {/* Left side: Slider and crowd result */}
          <Box
            sx={{
              flex: 7, // Takes 7 parts of the container
              display: "flex", // Stack slider and result vertically
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: { xs: "0", md: "20px" }, // No margin on small, 20px on large
              marginRight: { xs: "0", md: "20px" },
              marginTop: { xs: "10px", md: "30px" }, // Smaller margin for small screens
            }}
          >
            <Slider
              value={selectedHour}
              min={0}
              max={23}
              step={1}
              marks={[
                { value: 0, label: "12 AM" },
                { value: 6, label: "6 AM" },
                { value: 12, label: "12 PM" },
                { value: 18, label: "6 PM" },
                { value: 23, label: "11 PM" },
              ]}
              valueLabelDisplay="on"
              onChange={handleSliderChange}
              sx={{
                width: "100%", // Full width for the slider
                "& .MuiSlider-thumb": {
                  backgroundColor: "#ffffff", // Thumb color
                  border: "2px solid #598F57",
                  width: { xs: 16, md: 20 }, // Thumb size adjustment for smaller screens
                  height: { xs: 16, md: 20 }, // Thumb size adjustment for smaller screens
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#598F57",
                  height: { xs: 6, md: 8 }, // Track height adjustment
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "#D32F2F",
                  height: { xs: 6, md: 8 }, // Rail height adjustment
                },
              }}
            />

            {/* Crowding Level message and emoji */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={3}
            >
              <Typography
                variant="h7"
                sx={{
                  fontWeight: "bold",
                  color: "#1B5E20",
                  marginRight: "10px",
                }}
              >
                Crowding Level at {selectedHour}:00:
              </Typography>
              <Box>{renderEmoji(crowdingLevelForSelectedHour)}</Box>
            </Box>
          </Box>

          {/* Right side: Improved Emoji Legend */}
          <Box
            sx={{
              flex: 3, // Takes 3 parts of the container
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#F1F8E9", // Light green background for contrast
              borderRadius: "12px", // Rounded corners for container
              padding: "15px", // Padding inside the container
              border: "2px solid #81C784", // Border to match theme
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
              marginRight: { xs: "0px", md: "20px" }, // No margin on small, margin on large
              marginLeft: { xs: "0px", md: "30px" },
              mt: { xs: "10px", md: "0px" }, // Add margin-top for small screens
              width: { xs: "100%", md: "auto" }, // Full width on small screens
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#1B5E20", textAlign: "center" }}
            >
              Crowd Level Legend:
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={2}
              sx={{
                backgroundColor: "#81C784", // Slightly darker background for individual items
                borderRadius: "8px", // Rounded corners for items
                padding: "8px 12px", // Padding for items
                width: "100%", // Full width for consistent look
              }}
            >
              <Typography sx={{ marginRight: "8px", fontSize: "1.5rem" }}>
                &#x1F603;
              </Typography>
              <Typography>Low Crowding</Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={2}
              sx={{
                backgroundColor: "#FFF176", // Yellow background for medium crowding
                borderRadius: "8px", // Rounded corners for items
                padding: "8px 12px", // Padding for items
                width: "100%", // Full width for consistent look
              }}
            >
              <Typography sx={{ marginRight: "8px", fontSize: "1.5rem" }}>
                &#x1F610;
              </Typography>
              <Typography>Medium Crowding</Typography>
            </Box>
            <Box
              className="scroll-section"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={2}
              sx={{
                backgroundColor: "#FF8A80", // Light red background for high crowding
                borderRadius: "8px", // Rounded corners for items
                padding: "8px 12px", // Padding for items
                width: "100%", // Full width for consistent look
              }}
            >
              <Typography sx={{ marginRight: "8px", fontSize: "1.5rem" }}>
                &#x1F622;
              </Typography>
              <Typography>High Crowding</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* UV and Weather Data Section */}
      <Box
        id="uv-weather-details"
        sx={{
          mt: 3, // Add space between sections
          border: "3px solid #417446", // Green border to match theme
          borderRadius: "15px", // Rounded corners
          backgroundColor: "#FAF9F6", // Light background to match the theme
          maxWidth: "100%", // Width constraint
          margin: "20px", // Center card
          marginBottom: "0px",
          padding: "0px", // Remove padding at the top to eliminate space
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
        }}
      >
        {/* Card Header */}
        <Box
          sx={{
            backgroundColor: "#417446", // Green header
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            padding: "10px",
            display: "flex", // Use flexbox
            alignItems: "center", // Align items vertically in the center
          }}
        >
          <SunIcon sx={{ fontSize: "2.5rem", color: "#FFC107", mr: 1 }} />
          <Typography
            variant="h6"
            sx={{ color: "white", fontWeight: "bold", margin: "0px" }}
          >
            UV Index & Weather
          </Typography>
        </Box>

        {/* UVWeatherDisplay Component inside styled container */}
        <Box>
          <UVWeatherDisplay placeId={placeId} category={category} />
        </Box>
      </Box>
    </Box>
  );
};

export default ExploreParkPage;

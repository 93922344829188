import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Box,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SearchIcon from "@mui/icons-material/Search";
import NavigationIcon from "@mui/icons-material/Navigation";
import ParkIcon from "@mui/icons-material/Park";
import EmojiNatureIcon from "@mui/icons-material/EmojiNature";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { styled, useTheme } from "@mui/system";

// Custom connector with thicker line and dynamic colors
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& .MuiStepConnector-line": {
      borderColor: "#2e7d32", // Active state color
      borderTopWidth: "16px", // Thicker line
    },
  },
  completed: {
    "& .MuiStepConnector-line": {
      borderColor: "#81c784", // Completed state color
      borderTopWidth: "16px", // Thicker line
    },
  },
  line: {
    borderColor: "#c0c0c0", // Future steps color
    borderTopWidth: "16px", // Thicker line
  },
}));

// Custom Step Icon component
const ColorlibStepIcon = ({ icon, active, completed }) => {
  const theme = useTheme(); // Access the theme for responsive breakpoints

  const icons = {
    1: <SearchIcon />,
    2: <NavigationIcon />,
    3: <ParkIcon />,
    4: <EmojiNatureIcon />,
    5: <VolunteerActivismIcon />,
  };

  return (
    <div
      style={{
        backgroundColor: completed ? "#81c784" : active ? "#2e7d32" : "#e0e0e0",
        color: "#fff",
        width: 40,
        height: 40,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: active ? "0px 4px 8px rgba(0, 0, 0, 0.2)" : "none",
        transition: "all 0.3s ease",
        [theme.breakpoints.down("sm")]: {
          width: 30, // Smaller icon on small screens
          height: 30, // Adjust height accordingly
        },
      }}
    >
      {completed ? <CheckCircleIcon /> : icons[icon]}
    </div>
  );
};

// Define the steps and paths
const steps = [
  { label: "Search Nearby Green Spaces", path: "/nearby-green-spaces" },
  { label: "Navigate to Park", path: "/navigate" },
  { label: "Explore Park", path: "/explore-park" },
  { label: "Trip Summary", path: "/trip-summary" },
  { label: "Go Green", path: "/go-green" },
];

const CustomStepper = ({ activeStep }) => {
  const theme = useTheme(); // Access the theme for responsive breakpoints

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: 2,
        padding: "10px 0",
        paddingTop: { xs: "60px", sm: "10px" }, // Adjust padding for navbar
      }}
    >
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />} // Using the custom connector here
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={(props) => (
                <ColorlibStepIcon {...props} icon={index + 1} />
              )}
            >
              <Typography
                sx={{
                  color: activeStep === index ? "#2e7d32" : "#9e9e9e", // Dark green for active step, grey for future steps
                  fontWeight: activeStep === index ? "bold" : "normal", // Bold active step label
                  transition: "all 0.3s ease", // Smooth transition
                  fontSize: { xs: "0.8rem", sm: "1rem" }, // Adjust font size for smaller screens
                }}
              >
                {step.label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomStepper;

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationMap from "./NavigationMap";
import CarbonFootprintVisualizer from "./CarbonFootprintVisualizer";
import mapboxgl from "mapbox-gl";
import FlipCard from "./FlipCard";
import CustomStepper from "./CustomStepper";
import earthGif from "../assets/images/earth.gif";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RouteIcon from "@mui/icons-material/Route";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import ParkIcon from "@mui/icons-material/EmojiNature";
import EcoIcon from "@mui/icons-material/EnergySavingsLeaf";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TurnLeftIcon from "@mui/icons-material/TurnLeft";
import TurnRightIcon from "@mui/icons-material/TurnRight";
import StraightIcon from "@mui/icons-material/ArrowForward";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar"; // Add this to import the car icon

import Tooltip from "@mui/material/Tooltip";

import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const MemoizedMap = React.memo(
  ({
    mapContainerRef,
    userCoordinates,
    parkCoordinates,
    transportMode,
    routeOption,
    setIsScenicAvailable,
    setTurnByTurnDirections,
  }) => {
    return (
      <Box
        ref={mapContainerRef} // Use the ref from NavigationPage
        width={{ xs: "100%", md: "70%" }}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        sx={{ height: "70vh", padding: 0, margin: 0 }} // Fixed height for the map
      >
        <NavigationMap
          userCoordinates={userCoordinates}
          parkCoordinates={parkCoordinates}
          transportMode={transportMode}
          routeOption={routeOption}
          setIsScenicAvailable={setIsScenicAvailable}
          setTurnByTurnDirections={setTurnByTurnDirections}
          style={{ flex: 1, height: "100%", marginBottom: 0 }}
        />
      </Box>
    );
  }
);

// Utility function to calculate the distance between two lat/lon points in kilometers
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km
  return distance;
};

const formatDuration = (durationInSeconds) => {
  if (durationInSeconds >= 3600) {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    return `${hours} hr ${minutes} min`;
  } else if (durationInSeconds >= 60) {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${minutes} min ${seconds} sec`;
  } else {
    return `${durationInSeconds} sec`;
  }
};

const NavigationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { park, userCoordinates, placeId, category } = location.state || {};

  const [selectedMode, setSelectedMode] = useState(null);
  const [selectedRouteOption, setSelectedRouteOption] = useState(null);
  const [isScenicAvailable, setIsScenicAvailable] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);
  const [turnByTurnDirections, setTurnByTurnDirectionsState] = useState([]);
  const [routeDistance, setRouteDistance] = useState(0);
  const [routeDuration, setRouteDuration] = useState(0);
  const mapContainerRef = useRef(null); // Ref for the map container
  const [panelHeight, setPanelHeight] = useState("auto"); // State to store panel height
  const [allowDriving, setAllowDriving] = useState(false); // Add this to manage driving mode availability
  const [openTripDialog, setOpenTripDialog] = useState(false); // State to control Trip dialog visibility
  const [tripCountdown, setTripCountdown] = useState(7); // Countdown for Trip dialog
  const [isTripCountingDown, setIsTripCountingDown] = useState(false); // Countdown state
  const [calculatedSavings, setCalculatedSavings] = useState(0); // Add this state to track savings
  const scrollableContentRef = useRef(null); // Ref for scrollable content

  const updatePanelHeight = useCallback(() => {
    if (mapContainerRef.current) {
      const mapHeight = mapContainerRef.current.offsetHeight;
      setPanelHeight(`${mapHeight}px`); // Set the right panel height equal to map height
    }
  }, []);

  // Animation state
  const [animate, setAnimate] = useState(true);

  // Stop the animation once a mode of transport is selected
  const handleModeSelection = (mode) => {
    setSelectedMode(mode);
    setAnimate(false); // Stop animation
  };

  // Check if scroll indicator is necessary
  useEffect(() => {
    if (scrollableContentRef.current) {
      const { scrollHeight, clientHeight } = scrollableContentRef.current;
      if (scrollHeight > clientHeight) {
        setShowScrollIndicator(true); // Show if content overflows
      } else {
        setShowScrollIndicator(false); // Hide if content fits
      }
    }
  }, [selectedMode]); // Re-check when mode is selected

  useEffect(() => {
    updatePanelHeight(); // Initial height set
    window.addEventListener("resize", updatePanelHeight); // Update height on window resize

    return () => {
      window.removeEventListener("resize", updatePanelHeight);
    };
  }, [updatePanelHeight]);

  const parkCoordinates = useMemo(() => {
    return park?.coords
      ? {
          latitude: parseFloat(park.coords.split(",")[0].replace(/[()]/g, "")),
          longitude: parseFloat(park.coords.split(",")[1].replace(/[()]/g, "")),
        }
      : null;
  }, [park]);

  useEffect(() => {
    if (userCoordinates && parkCoordinates) {
      const distance = calculateDistance(
        userCoordinates.latitude,
        userCoordinates.longitude,
        parkCoordinates.latitude,
        parkCoordinates.longitude
      );

      // If distance is more than 5 km, allow driving mode
      if (distance > 5) {
        setAllowDriving(true);
      } else {
        setAllowDriving(false);
      }

      console.log(`Distance between user and park: ${distance} km`);
    }
  }, [userCoordinates, parkCoordinates]);

  const { savings, mode, routeOption } = location.state || {};

  const setTurnByTurnDirections = useCallback((directions) => {
    setTurnByTurnDirectionsState(directions);

    const totalDistance =
      directions.reduce((acc, step) => acc + step.distance, 0) / 1000;
    const totalDuration =
      directions.reduce((acc, step) => acc + step.duration, 0) / 60;

    setRouteDistance(totalDistance);
    setRouteDuration(totalDuration);
  }, []);

  useEffect(() => {
    if (placeId && category) {
      console.log("Place ID:", placeId);
      console.log("Category:", category);
      // You can use placeId and category in any fetch or API logic here
    }
  }, [placeId, category]);

  // Automatically set default route to eco-friendly when mode is selected
  useEffect(() => {
    if (selectedMode) {
      setSelectedRouteOption("eco-friendly");
    }
  }, [selectedMode]);

  useEffect(() => {
    let intervalId;
    if (isCountingDown && countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      clearInterval(intervalId);
      navigate("/explore-park", {
        state: {
          park,
          userCoordinates,
          placeId,
          category,
          calculatedCarbonSavings: savings,
          mode: selectedMode,
          routeOption: selectedRouteOption,
        },
      });
    }
    return () => clearInterval(intervalId);
  }, [
    countdown,
    isCountingDown,
    navigate,
    park,
    userCoordinates,
    placeId,
    category,
  ]);

  const handleAlreadyThere = () => {
    setOpenDialog(true);
    setIsCountingDown(true);
    setCountdown(7);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTripClick = () => {
    // Calculate CO2 savings based on transport mode
    const savings =
      selectedMode === "walking"
        ? (routeDistance * 0.21).toFixed(2)
        : selectedMode === "cycling"
        ? (routeDistance * 0.16).toFixed(2)
        : -(routeDistance * 0.21).toFixed(2); // For driving

    setCalculatedSavings(savings); // Store calculated savings in state
    console.log("Calculated savings in NavigationPage:", savings);
    setOpenTripDialog(true); // Show the dialog
    setIsTripCountingDown(true); // Start countdown
    setTripCountdown(7);

    // Start countdown and navigate after 5 seconds
    const intervalId = setInterval(() => {
      setTripCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(intervalId);
          // Redirect to the explore park page with the savings data
          navigate("/explore-park", {
            state: {
              park,
              userCoordinates,
              placeId,
              category,
              calculatedCarbonSavings: savings, // Pass savings data
              mode: selectedMode,
              routeOption: selectedRouteOption,
            },
          });
          console.log("Passing savings to ExplorePark:", savings);
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const renderInstructionIcon = (instruction) => {
    if (instruction.toLowerCase().includes("left")) {
      return <TurnLeftIcon sx={{ color: "#4CAF50", marginRight: "10px" }} />;
    } else if (instruction.toLowerCase().includes("right")) {
      return <TurnRightIcon sx={{ color: "#FF5722", marginRight: "10px" }} />;
    } else {
      return <StraightIcon sx={{ color: "#007FFF", marginRight: "10px" }} />;
    }
  };

  const getBackgroundColor = (index) => {
    return index % 2 === 0 ? "#ACE1AF" : "#E8F5E9";
  };

  useEffect(() => {
    const scrollableElement = document.getElementById(
      "scrollable-right-column"
    );

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollableElement;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setShowScrollIndicator(false);
      } else {
        setShowScrollIndicator(true);
      }
    };

    scrollableElement.addEventListener("scroll", handleScroll);

    return () => {
      scrollableElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        height: "100%",
        backgroundColor: "#F1F8E9",
        pt: { xs: 9, sm: 6, md: 5 }, // Adjust padding top based on screen size
        overflow: "hidden",
        position: "relative", // Ensure the entire container is relatively positioned
      }}
    >
      {/* Responsive Back button container */}
      <Box
        mb={{ xs: -6, sm: 2 }} // Adjust the bottom margin based on screen size
        display="flex"
        justifyContent="space-between"
        sx={{
          paddingX: { xs: 2, md: 4 }, // Add padding to the left and right for better spacing
          zIndex: 1000, // Ensure the navbar or back button is on top
          position: "relative", // Keep the button fixed within this section
        }}
      >
        <Button
          variant="outlined"
          onClick={() => navigate(-1)} // Go back to the previous page
          sx={{
            color: "#1B5E20",
            borderColor: "#1B5E20",
            "&:hover": {
              backgroundColor: "#e0f2f1",
              borderColor: "#1B5E20",
            },
            paddingX: { xs: "8px", sm: "12px", md: "16px" }, // Adjust button padding for responsiveness
            fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" }, // Adjust font size based on screen size
          }}
        >
          Back
        </Button>
      </Box>

      {/* <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: "#2E7D32",
          textAlign: "center",
          mb: 2,
          letterSpacing: "1px",
          paddingX: 2,
        }}
      >
        Navigation to {park?.name}
      </Typography> */}
      <CustomStepper
        activeStep={1}
        sx={{
          mt: { xs: 0, md: 4 }, // 'mt' stands for margin-top. It will have less margin on small screens (xs) and larger margin on medium screens (md) and up.
        }}
      />

      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        p={2}
        gap={2}
        sx={{ flex: 1, backgroundColor: "#F1F8E9", height: "100%" }}
      >
        <MemoizedMap
          mapContainerRef={mapContainerRef}
          userCoordinates={userCoordinates}
          parkCoordinates={parkCoordinates}
          transportMode={selectedMode}
          routeOption={selectedRouteOption}
          setIsScenicAvailable={setIsScenicAvailable}
          setTurnByTurnDirections={setTurnByTurnDirections}
        />

        <Box
          id="scrollable-right-column"
          ref={scrollableContentRef} // Ref to check scroll height
          width={{ xs: "100%", md: "30%" }} // Full width on small screens, 30% on medium screens and up
          display="flex"
          flexDirection="column"
          sx={{
            backgroundColor: "#E8F5E9",
            borderRadius: "12px",
            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1)",
            height: panelHeight, // Dynamically set height based on map height
            zIndex: 1, // Keep it under the Tabs
            overflowY: "auto", // Enable vertical scrolling
            scrollbarWidth: "thin", // Firefox scrollbar width
            "&::-webkit-scrollbar": {
              width: "15px", // Width of the scrollbar
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#598F57", // Scrollbar color
              borderRadius: "10px", // Rounded scrollbar thumb
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#66BB6A", // Color when hovering over scrollbar
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f8e9", // Track color behind the scrollbar
            },
            "&::-webkit-scrollbar-track:hover": {
              backgroundColor: "#e0f2f1", // Track color when hovering
            },
            scrollbarColor: "#598F57 #f1f8e9", // Firefox scrollbar colors
            scrollbarWidth: "thin", // Firefox scrollbar width
          }}
        >
          <Box
            sx={{
              backgroundColor: "#C8E6C9",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1)",
              position: "sticky", // Keep the tabs at the top while scrolling
              top: 0,
              zIndex: 999, // Ensure the tab stays on top of scrollable content
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="navigation tabs"
              centered
              variant="fullWidth" // This makes the tabs full width
              sx={{
                "& .MuiTab-root": {
                  color: "#888888", // Light gray for unselected tabs
                  fontWeight: "500", // Regular weight for unselected
                  fontSize: "16px",
                  textTransform: "none",
                  transition: "all 0.3s",
                },
                "& .Mui-selected": {
                  color: "#2E7D32", // Dark green for selected tab
                  backgroundColor: "#E8F5E9", // Light green background for selected tab
                  fontWeight: "bold", // Bold font for selected tab
                  fontSize: "18px", // Slightly larger font size for selected tab
                  borderBottom: "4px solid #388E3C", // Green underline for selected tab
                },
                "& .MuiTab-root:hover": {
                  backgroundColor: "#F1F8E9", // Light hover background for unselected tabs
                  color: "#388E3C", // Green text color on hover
                },
              }}
            >
              <Tab label="Green Saver" />
              <Tab label="Directions" />
            </Tabs>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TabPanel value={tabValue} index={0}>
              {park.name && (
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#1B5E20",
                    mt: 2, // Add some margin top for spacing
                  }}
                >
                  {`Navigate to: ${park.name}`}
                </Typography>
              )}

              {/* Select Mode of Transport with animation */}
              <Box sx={{ width: "100%", textAlign: "center", mb: 1, mt: 2 }}>
                {/* Apply animation to the surrounding Box, not the Typography */}
                <Box
                  sx={{
                    animation: animate
                      ? "pulse 3s infinite ease-in-out"
                      : "none", // Subtle animation applied here
                    display: "inline-block", // Keep it applied to the container
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#2E7D32",
                      fontWeight: "bold",
                      mb: 2,
                    }}
                  >
                    Select your mode of transport:
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" gap={2}>
                  <Button
                    variant={
                      selectedMode === "walking" ? "contained" : "outlined"
                    }
                    color="primary"
                    onClick={() => handleModeSelection("walking")}
                    sx={{
                      backgroundColor:
                        selectedMode === "walking" ? "#388E3C" : "transparent",
                      color: selectedMode === "walking" ? "white" : "#388E3C",
                      fontWeight: "bold",
                      padding: "5px 10px",
                    }}
                    startIcon={<DirectionsWalkIcon />}
                  >
                    Walking
                  </Button>
                  <Button
                    variant={
                      selectedMode === "cycling" ? "contained" : "outlined"
                    }
                    color="primary"
                    onClick={() => handleModeSelection("cycling")}
                    sx={{
                      backgroundColor:
                        selectedMode === "cycling" ? "#388E3C" : "transparent",
                      color: selectedMode === "cycling" ? "white" : "#388E3C",
                      fontWeight: "bold",
                      padding: "5px 10px",
                    }}
                    startIcon={<DirectionsBikeIcon />}
                  >
                    Cycling
                  </Button>
                  {allowDriving && (
                    <Button
                      variant={
                        selectedMode === "driving" ? "contained" : "outlined"
                      }
                      color="primary"
                      onClick={() => handleModeSelection("driving")}
                      sx={{
                        backgroundColor:
                          selectedMode === "driving"
                            ? "#388E3C"
                            : "transparent",
                        color: selectedMode === "driving" ? "white" : "#388E3C",
                        fontWeight: "bold",
                        padding: "5px 10px",
                      }}
                      startIcon={<DirectionsCarIcon />}
                    >
                      Driving
                    </Button>
                  )}
                </Box>
              </Box>

              {selectedMode && (
                <Box
                  sx={{
                    width: "80%",
                    textAlign: "center",
                    mt: 1,
                    margin: "0 auto",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#2E7D32",
                      mb: 1,
                      fontWeight: "bold",
                    }}
                  >
                    Select Route Option:
                  </Typography>

                  <Box display="flex" justifyContent="center" gap={2} mb={2}>
                    <Tooltip
                      title="No scenic route available for this mode of transport"
                      disableFocusListener={isScenicAvailable}
                      disableHoverListener={isScenicAvailable}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          padding: 0,
                          margin: 0,
                          lineHeight: 0,
                          minWidth: "150px",
                        }}
                      >
                        <Button
                          variant={
                            selectedRouteOption === "scenic"
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          onClick={() => setSelectedRouteOption("scenic")}
                          sx={{
                            backgroundColor:
                              selectedRouteOption === "scenic"
                                ? "#388E3C"
                                : "transparent",
                            color:
                              selectedRouteOption === "scenic"
                                ? "white"
                                : "#388E3C",
                            fontWeight: "bold",
                            padding: "5px 15px",
                            minWidth: "150px",
                            minHeight: "83.5px",
                          }}
                          startIcon={<ParkIcon />}
                          disabled={!isScenicAvailable}
                        >
                          Scenic Route
                        </Button>
                      </div>
                    </Tooltip>

                    <Button
                      variant={
                        selectedRouteOption === "eco-friendly"
                          ? "contained"
                          : "outlined"
                      }
                      color="primary"
                      onClick={() => setSelectedRouteOption("eco-friendly")}
                      sx={{
                        backgroundColor:
                          selectedRouteOption === "eco-friendly"
                            ? "#388E3C"
                            : "transparent",
                        color:
                          selectedRouteOption === "eco-friendly"
                            ? "white"
                            : "#388E3C",
                        fontWeight: "bold",
                        padding: "5px 15px",
                        minWidth: "150px",
                      }}
                      startIcon={<EcoIcon />}
                    >
                      Eco-friendly Route
                    </Button>
                  </Box>
                </Box>
              )}

              {routeDistance > 0 && routeDuration > 0 && (
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={1}
                  >
                    <RouteIcon sx={{ color: "#2E7D32", marginRight: "5px" }} />
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#2E7D32",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    >
                      Total Distance: {routeDistance.toFixed(2)} km
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <AccessTimeIcon
                      sx={{ color: "#2E7D32", marginRight: "5px" }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#2E7D32",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    >
                      Estimated Time: {routeDuration.toFixed(0)} mins
                    </Typography>
                  </Box>
                </Box>
              )}
              {/* Add a Divider line */}
              <Divider
                sx={{
                  width: "90%",
                  margin: "20px auto", // Centers the divider and adds spacing
                  backgroundColor: "#4CAF50", // Custom color for the divider
                }}
              />
              {selectedMode && selectedRouteOption && (
                <Box
                  sx={{
                    width: "90%",
                    textAlign: "center",
                    margin: "0 auto",
                    mt: 2,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#2E7D32",
                      mb: 1,
                      fontSize: "24px",
                      fontWeight: "900",
                    }}
                  >
                    Your environmental impact from this trip.
                  </Typography>

                  <CarbonFootprintVisualizer
                    savings={calculatedSavings}
                    selectedMode={selectedMode}
                    routeDistance={routeDistance}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px", // Add spacing before the button
                      marginBottom: "20px", // Add spacing after the button
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setTabValue(1); // Switch to the Directions tab
                        document.getElementById(
                          "scrollable-right-column"
                        ).scrollTop = 0; // Scroll to top
                      }}
                      sx={{
                        backgroundColor: "#388E3C",
                        color: "white",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        animation: "pulse 2s infinite", // Correct animation applied here
                        "&:hover": {
                          backgroundColor: "#2E7D32",
                        },
                      }}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              )}
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              {/* Moved the "Already there?" button here */}
              <Box
                display="flex"
                justifyContent="center"
                sx={{ mt: 2, mb: 2 }} // Add some margin-bottom for spacing
              >
                <Button
                  variant="contained"
                  onClick={handleAlreadyThere} // Trigger AlreadyThere action
                  sx={{
                    backgroundColor: "#1B5E20",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#2E7D32",
                    },
                  }}
                >
                  Reached The Park?
                </Button>
              </Box>

              <Typography
                variant="body1"
                sx={{
                  color: "#2E7D32",
                  mb: 1,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                If not,
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "#2E7D32",
                  mb: 1,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                here are your turn-by-turn directions:
              </Typography>
              <List>
                {turnByTurnDirections.length > 0 ? (
                  turnByTurnDirections.map((step, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        backgroundColor: getBackgroundColor(index),
                        padding: "10px 0",
                      }}
                    >
                      {renderInstructionIcon(step.instruction)}
                      <ListItemText
                        primary={step.instruction}
                        secondary={`${Math.round(
                          step.distance
                        )} meters (${formatDuration(
                          Math.round(step.duration)
                        )})`}
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography>No turn-by-turn directions available.</Typography>
                )}
              </List>
              {/* Complete Trip Button: Only show after a route option is selected */}
              {selectedRouteOption && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center", // Center align the button
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleTripClick} // Function to handle Complete Trip button click
                    sx={{
                      backgroundColor: "#1B5E20",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#2E7D32",
                      },
                    }}
                  >
                    Complete Trip
                  </Button>
                </Box>
              )}
            </TabPanel>

            {showScrollIndicator && (
              <Box
                sx={{
                  position: "sticky", // Use sticky positioning to keep it within the container
                  bottom: "20px", // Keep it 20px from the bottom of the container
                  display: "flex",
                  justifyContent: "center", // Center the icon horizontally within its container
                  alignItems: "center", // Vertically center the icon inside the circle
                  padding: "4px",
                  backgroundColor: "#b2c7b4", // Green background for the circle
                  borderRadius: "50%", // Circular shape
                  width: "40px", // Circle width
                  height: "40px", // Circle height
                  margin: "0 auto", // Ensure it centers within the panel width
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
                  transition: "background-color 0.3s ease", // Smooth hover effect
                  "&:hover": {
                    backgroundColor: "#2E7D32", // Darken background on hover
                  },
                  animation: "bounce 1.5s infinite", // Adding the bounce animation for attention
                }}
              >
                <ExpandMoreIcon
                  sx={{
                    fontSize: "1.5rem", // Icon size
                    color: "#388E3C", // Icon color
                  }}
                />
              </Box>
            )}

            <style>
              {`
    @keyframes bounce {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(-5px); } // Slight bounce for a subtle effect
    }
  `}
            </style>
          </Box>
        </Box>

        <style>
          {`
          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.05); } /* Reduced scale */
            100% { transform: scale(1); }
          }

          @keyframes bounce {
            0% { transform: translateY(0); }
            50% { transform: translateY(-5px); } /* Smaller bounce */
            100% { transform: translateY(0); }
          }

          @keyframes fadein {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }
        `}
        </style>
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            width: { xs: "90%", sm: "75%", md: "60%", lg: "50%" }, // Responsive width
            maxWidth: "100%", // Ensure it doesn't exceed screen size
            margin: { xs: "20px", md: "40px auto" }, // Margin to ensure it stays centered on smaller screens
          },
        }}
      >
        <DialogContent
          sx={{
            textAlign: "center",
            p: { xs: 2, sm: 3 }, // Responsive padding
            backgroundColor: "#E8F5E9",
            borderRadius: "12px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%", // Full width of the parent container
              mt: { xs: 1, sm: 2 }, // Responsive top margin
            }}
          >
            {/* Earth GIF */}
            <Box
              display="flex"
              justifyContent="center"
              mb={2}
              sx={{
                position: "relative",
                width: "100%", // Full width
              }}
            >
              <img
                src={earthGif}
                alt="Earth animation"
                style={{
                  width: "auto", // Keep aspect ratio
                  height: { xs: "50px", sm: "70px", md: "80px" }, // Set responsive height
                  maxHeight: "100px",
                }}
              />
            </Box>

            {/* Progress bar */}
            <Box
              sx={{
                backgroundColor: "#C8E6C9",
                borderRadius: "5px",
                height: { xs: "5px", sm: "6px", md: "8px" }, // Responsive height for progress bar
                width: "100%", // Full width of the container
                mt: { xs: 2, sm: 3 }, // Spacing between the GIF and the bar
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#388E3C",
                  height: "100%",
                  width: `${(7 - countdown) * 14.29}%`, // Progress based on countdown
                  transition: "width 1s ease-out",
                }}
              />
            </Box>
          </Box>

          <Typography
            variant="h6"
            color="#2E7D32"
            gutterBottom
            sx={{
              fontWeight: "bold",
              mb: { xs: 1, sm: 2 },
              fontSize: { xs: "1.1rem", sm: "1.25rem", md: "1.5rem" }, // Responsive font size
            }}
          >
            Enjoy your time at {park?.name}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: "#4CAF50",
              fontWeight: "bold",
              mt: { xs: 1, sm: 2 },
              fontSize: { xs: "1rem", sm: "1.2rem", md: "1.25rem" }, // Responsive font size
            }}
          >
            Redirecting you to explore "{park?.name}" in {countdown} seconds...
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openTripDialog}
        onClose={() => setOpenTripDialog(false)}
        PaperProps={{
          sx: {
            width: { xs: "90%", sm: "75%", md: "60%", lg: "50%" }, // Responsive width
            maxWidth: "100%",
            margin: { xs: "20px", md: "40px auto" }, // Center it on smaller screens
          },
        }}
      >
        <DialogContent
          sx={{
            textAlign: "center",
            p: { xs: 2, sm: 3 }, // Responsive padding
            backgroundColor: "#E8F5E9",
            borderRadius: "12px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            mb={2}
            sx={{
              position: "relative",
              width: "100%", // Full width
            }}
          >
            <img
              src={earthGif}
              alt="Earth animation"
              style={{
                width: "auto",
                height: { xs: "50px", sm: "70px", md: "80px" }, // Responsive height
                maxHeight: "100px",
              }}
            />
          </Box>

          {selectedMode === "driving" ? (
            <>
              <Typography
                variant="h6"
                color="#FF5722"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                  fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.75rem" },
                }}
              >
                ⚠️ Driving Impact Alert ⚠️
              </Typography>

              <Typography
                variant="h5"
                color="#F44336"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                  mb: 1,
                }}
              >
                You emitted
              </Typography>

              <Typography
                variant="h2"
                color="#F44336"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
                  mb: 1,
                }}
              >
                {calculatedSavings} kg of CO<sub>2</sub> 🚗
              </Typography>

              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ fontStyle: "italic", mb: 3 }}
              >
                Consider switching to walking or cycling to save the planet 🌍.
                This trip emitted the equivalent of burning
                <strong style={{ color: "#FF5722", fontSize: "20px" }}>
                  {" "}
                  {Math.round((calculatedSavings / 21) * 365)}{" "}
                </strong>
                litres of gasoline.
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="h5"
                color="#2E7D32"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                  mb: 1,
                }}
              >
                You've saved
              </Typography>

              <Typography
                variant="h2"
                color="#8BC34A"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
                  mb: 1,
                }}
              >
                {calculatedSavings} kg of CO<sub>2</sub> 🌍
              </Typography>

              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ fontStyle: "italic", mb: 3 }}
              >
                Doing this for a year will be equivalent to planting
                <strong style={{ color: "#4CAF50", fontSize: "20px" }}>
                  {" "}
                  {Math.round((calculatedSavings / 21) * 365)}{" "}
                </strong>
                trees 🌳
              </Typography>
            </>
          )}

          <Typography
            variant="body1"
            sx={{
              color: "#388E3C",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Redirecting you to explore "{park?.name}" in {tripCountdown}{" "}
            seconds...
          </Typography>

          <Box
            mt={3}
            sx={{
              backgroundColor: "#C8E6C9",
              borderRadius: "5px",
              position: "relative",
              height: { xs: "5px", sm: "6px", md: "8px" },
              width: "100%",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#388E3C",
                height: "100%",
                width: `${(7 - tripCountdown) * 14.29}%`,
                transition: "width 1s ease-out",
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <style>
        {`
          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.2); }
            100% { transform: scale(1); }
          }
        `}
      </style>
    </Box>
  );
};

export default NavigationPage;

import React, { useState, useEffect } from "react";
import { Box, Typography, Tooltip, IconButton } from "@mui/material";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import InfoIcon from "@mui/icons-material/Info";
import TreeIcon from "@mui/icons-material/Park"; // Tree icon

// Array of encouraging messages
// const encouragingMessages = [
//   "Great Job",
//   "Well Done",
//   "Marvellous",
//   "Fantastic",
//   "Keep It Up",
// ];

// Array of suggestions for driving
const drivingSuggestions = [
  "Consider carpooling to reduce emissions!",
  "Could a public transport option work for this route?",
  "Why not try cycling or walking for shorter trips?",
  "Driving less can reduce your carbon footprint.",
];

// Function to shuffle messages
// const getRandomEncouragingMessage = () => {
//   return encouragingMessages[
//     Math.floor(Math.random() * encouragingMessages.length)
//   ];
// };

// Function to shuffle suggestions
const getRandomDrivingSuggestion = () => {
  return drivingSuggestions[
    Math.floor(Math.random() * drivingSuggestions.length)
  ];
};

const CarbonFootprintVisualizer = ({
  selectedMode,
  routeDistance,
  alternativeRouteDistance,
}) => {
  // Use the actual distance (considering alternative routes)
  const distance = alternativeRouteDistance || routeDistance;

  // CO2 emissions per kilometer for different modes
  const drivingEmissionPerKm = 0.21;
  const cyclingEmissionPerKm = 0.05;
  const walkingEmissionPerKm = 0.01;

  // Calculate emissions for the given route distance over a year (365 days)
  const drivingEmission = distance * drivingEmissionPerKm * 365;
  const cyclingEmission = distance * cyclingEmissionPerKm * 365;
  const walkingEmission = distance * walkingEmissionPerKm * 365;

  // Calculate savings based on the selected mode (for a year)
  const savings =
    selectedMode === "walking"
      ? drivingEmission - walkingEmission
      : selectedMode === "cycling"
      ? drivingEmission - cyclingEmission
      : 0;

  // Calculate percentage saved based on driving emissions over a year
  const percentageSaved =
    selectedMode === "walking" || selectedMode === "cycling"
      ? ((savings / drivingEmission) * 100).toFixed(2)
      : 0;

  const explanation = `This calculation assumes that you make this same trip every day for a year (365 days). The percentage saved represents the reduction in CO2 compared to driving this route.`;

  // State to hold the message (either encouragement or driving suggestion)
  const [messageText, setMessageText] = useState(
    selectedMode === "driving" ? getRandomDrivingSuggestion() : ""
  );

  useEffect(() => {
    if (selectedMode === "driving") {
      setMessageText(getRandomDrivingSuggestion());
    } else {
      setMessageText(""); // No message for walking or cycling
    }
  }, [selectedMode, alternativeRouteDistance]);

  const savingsText =
    selectedMode === "driving"
      ? `You emitted ${drivingEmission.toFixed(2)} kg of CO2.`
      : `By choosing ${selectedMode}, you saved ${savings.toFixed(
          2
        )} kg of CO2 over a year!`;

  // Determine which icon to use based on the mode of transport
  const transportIcon =
    selectedMode === "walking" ? (
      <DirectionsWalkIcon fontSize="large" sx={{ color: "#4CAF50" }} />
    ) : selectedMode === "cycling" ? (
      <DirectionsBikeIcon fontSize="large" sx={{ color: "#4CAF50" }} />
    ) : (
      <DirectionsCarIcon fontSize="large" sx={{ color: "#F44336" }} />
    );

  // Calculate the number of trees saved, rounded to the nearest integer
  const treesSaved = Math.round(savings / 21);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", textAlign: "center", margin: "20px 0" }}
    >
      {/* Add Label for Circular Progress Bar */}
      <Typography
        variant="h6"
        color={selectedMode === "driving" ? "#F44336" : "#4CAF50"}
        sx={{ fontWeight: "bold", mb: 1 }}
      >
        CO<sub>2</sub> Reduction
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ marginBottom: "10px" }}
      >
        (Compared to Driving)
      </Typography>

      {/* Circular Progress Bar with transport icon */}
      <Box
        sx={{
          width: "200px",
          height: "200px",
          marginBottom: "20px",
          position: "relative",
          "&:hover": {
            transform: "scale(1.05)",
            transition: "transform 0.3s ease",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        >
          {transportIcon}
        </Box>
        <CircularProgressbar
          value={percentageSaved}
          maxValue={100}
          styles={buildStyles({
            textSize: "14px", // Adjust text size for percentage
            pathColor: selectedMode === "driving" ? "#FF5722" : "#4CAF50", // Color for saved percentage
            trailColor: "#D3D3D3", // Grey color for the "unfilled" part
            textColor: selectedMode === "driving" ? "#F44336" : "#2E7D32",
            trailColor: "#D3D3D3", // Set the grey trail color for the unfilled portion
            backgroundColor: selectedMode === "driving" ? "#FFCDD2" : "#C8E6C9",
            strokeLinecap: "round", // Smooth, rounded edges for progress bar
          })}
        />
        {/* Percentage value adjusted */}
        <Box
          sx={{
            position: "absolute",
            bottom: "30%",
            left: "50%",
            transform: "translate(-50%, 0)",
            zIndex: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "700",
              color: selectedMode === "driving" ? "#F44336" : "#2E7D32",
            }}
          >
            {percentageSaved}%
          </Typography>
        </Box>
      </Box>

      {/* Display message */}
      <Typography
        variant="h6"
        color={selectedMode === "driving" ? "#F44336" : "#4CAF50"}
        sx={{ fontWeight: "bold", mb: 1 }}
      >
        {messageText}
      </Typography>

      <Typography
        variant="body1"
        sx={{ color: "#2E7D32", fontWeight: "bold", mb: 2 }}
      >
        {savingsText}
      </Typography>

      {/* Tree savings displayed with tree icon and multiplier */}
      {selectedMode !== "driving" && treesSaved > 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          sx={{ mb: 2 }}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ fontWeight: "bold", mb: 1 }}
          >
            This is equivalent to planting:
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <TreeIcon
              sx={{ color: "#4CAF50", fontSize: "30px", marginRight: "4px" }}
            />
            <Typography
              sx={{
                color: "#4CAF50",
                fontSize: "24px",
                fontWeight: "bold",
                marginRight: "4px",
              }}
            >
              ×
            </Typography>
            <Typography
              sx={{ color: "#4CAF50", fontSize: "24px", fontWeight: "bold" }}
            >
              {treesSaved} {treesSaved === 1 ? "tree" : "trees"}
            </Typography>
          </Box>
        </Box>
      )}

      {/* Explanation Tooltip */}
      <Box display="flex" alignItems="center">
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ fontStyle: "italic", mr: 1 }}
        >
          Explanation
        </Typography>
        <Tooltip title={explanation}>
          <IconButton>
            <InfoIcon fontSize="small" color="action" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default CarbonFootprintVisualizer;

import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import NavigateIcon from "@mui/icons-material/Navigation";
import NatureIcon from "@mui/icons-material/NaturePeopleTwoTone";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

// Import the correct icons for the amenities
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import PicnicIcon from "@mui/icons-material/OutdoorGrill"; // Picnic Setting icon
import WcIcon from "@mui/icons-material/Wc";
import swingImage from "../assets/images/swing.png";
import slideImage from "../assets/images/slide.png";
import ShadeIcon from "@mui/icons-material/FilterDrama"; // Shade icon
import SeatIcon from "@mui/icons-material/EventSeat"; // Seat icon
import PeopleIcon from "@mui/icons-material/People";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

// Amenity icon mapping
const amenityIcons = {
  "Drinking Fountain": <LocalDrinkIcon style={{ color: "#1CB8FC" }} />,
  "Bicycle Rails": <DirectionsBikeIcon style={{ color: "#FF9800" }} />,
  "Picnic Setting": <PicnicIcon style={{ color: "#8BC34A" }} />,
  PicnicSetting: <PicnicIcon style={{ color: "#8BC34A" }} />,
  Playground: <ChildFriendlyIcon style={{ color: "#FFC107" }} />,
  Toilet: <WcIcon style={{ color: "#3F51B5" }} />,
  Swing: (
    <img
      src={swingImage}
      alt="Swing"
      style={{ width: "24px", height: "24px" }}
    />
  ),
  Slide: (
    <img
      src={slideImage}
      alt="Slide"
      style={{ width: "24px", height: "24px" }}
    />
  ),
  Shade: <ShadeIcon style={{ color: "#11A022" }} />,
  Seat: <SeatIcon style={{ color: "#795548" }} />, // Seat icon
  default: <HelpOutlineIcon style={{ color: "#9E9E9E" }} />, // Use the correct icon here
};

// Icon mapping for space categories
const iconMap = {
  "Parks and gardens": <NatureIcon style={{ color: "#388E3C" }} />,
  "Sportsfields and organised recreation": (
    <SportsBasketballIcon style={{ color: "#FF5722" }} />
  ),
  Playgrounds: <ChildFriendlyIcon style={{ color: "#FFC107" }} />,
};

const SearchResults = ({ greenSpaces, onSelect }) => {
  return (
    <Box p={2} style={{ maxHeight: "100%", overflow: "auto" }}>
      {greenSpaces.length > 0 ? (
        greenSpaces.map((space) => (
          <Card
            key={space.id}
            style={{
              marginBottom: "16px",
              borderRadius: "12px",
              backgroundColor: "#ceded0",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                className="card-content"
              >
                {/* Space Name and Navigate Button */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    {/* Type Icon */}
                    {iconMap[space.category]}

                    {/* Green Space Name */}
                    <Typography variant="h6" style={{ color: "#2E7D32" }}>
                      {space.name}
                    </Typography>
                  </Box>

                  {/* Navigate Button */}
                  <CardActions>
                    <Button
                      className="navigate-button"
                      startIcon={<NavigateIcon />}
                      size="medium"
                      style={{
                        color: "#ffffff",
                        backgroundColor: "#388E3C",
                        padding: "6px 12px",
                        fontWeight: "bold",
                        borderRadius: "8px",
                      }}
                      onClick={() => onSelect(space)}
                    >
                      Navigate
                    </Button>
                  </CardActions>
                </Box>

                {/* Amenities and Distance */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pl={4}
                  pr={2}
                >
                  {/* Amenities */}
                  {space.matchedAmenities &&
                    space.matchedAmenities.length > 0 && (
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: "normal",
                            color: "textPrimary",
                            marginRight: "8px",
                          }}
                        >
                          Amenities:
                        </Typography>
                        {space.matchedAmenities.map((amenity) => {
                          const Icon =
                            amenityIcons[amenity] || amenityIcons["default"];
                          return (
                            <Tooltip key={amenity} title={amenity} arrow>
                              <Box
                                sx={{
                                  backgroundColor: "#F9F6EE",
                                  padding: "6px",
                                  borderRadius: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                {Icon}
                              </Box>
                            </Tooltip>
                          );
                        })}
                      </Box>
                    )}

                  {/* Distance */}
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#5A5A5A", // Colder, lighter grey color
                      fontWeight: "550", // Emphasize with bold font
                      fontSize: "0.85rem", // Slightly larger font size
                      textAlign: "right", // Align to the right
                    }}
                  >
                    {space.distance?.toFixed(2)} km away
                  </Typography>
                </Box>

                {/* Shaded Coverage Section */}
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  className="info-text"
                  mt={1}
                  pl={4}
                >
                  <ShadeIcon sx={{ color: "#11A022" }} />
                  <Typography variant="body2" className="info-text">
                    Shaded Coverage:
                    <Box
                      component="span"
                      fontWeight="bold"
                      color="#11A022"
                      paddingLeft="10px"
                    >
                      {space.shaded_region_perc !== "NA"
                        ? `${space.shaded_region_perc}`
                        : "Not Available"}
                    </Box>
                  </Typography>
                  <Box
                    className="shaded-coverage-bar"
                    sx={{
                      width: "120px",
                      height: "10px",
                      backgroundColor: "#F9F6EE",
                      borderRadius: "4px",
                      overflow: "hidden",
                      ml: 2,
                      background:
                        space.shaded_region_perc === "NA"
                          ? "repeating-linear-gradient(45deg, #ccc, #ccc 10px, #f0f0f0 10px, #f0f0f0 20px)"
                          : "#F9F6EE",
                    }}
                  >
                    {space.shaded_region_perc !== "NA" && (
                      <Box
                        sx={{
                          width: `${parseFloat(space.shaded_region_perc)}%`,
                          height: "100%",
                          backgroundColor: "#11A022",
                        }}
                      />
                    )}
                  </Box>
                </Box>

                {/* Crowding Level Section */}
                <Typography
                  variant="body2"
                  display="flex"
                  alignItems="center"
                  gap="8px"
                  mt={1}
                  pl={4}
                >
                  <PeopleIcon
                    sx={{
                      color:
                        space.crowding_level === "High"
                          ? "red"
                          : space.crowding_level === "Medium"
                          ? "orange"
                          : "green",
                    }}
                  />
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    Crowd Level:
                  </span>
                  <Box
                    component="span"
                    sx={{
                      fontWeight: "bold",
                      color:
                        space.crowding_level === "High"
                          ? "red"
                          : space.crowding_level === "Medium"
                          ? "orange"
                          : "green",
                    }}
                  >
                    {space.crowding_level}
                  </Box>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
          p={3}
          textAlign="center"
          bgcolor="#f0f4f7"
          borderRadius="12px"
        >
          <SentimentDissatisfiedIcon
            style={{ fontSize: "80px", color: "#a0a0a0" }}
          />
          <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
            No green spaces found.
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
            Try adjusting your search criteria or location and try again.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SearchResults;

import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import mapboxgl from "mapbox-gl";
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";
import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";
import polyline from "@mapbox/polyline";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q";

const NavigationMap = ({
  userCoordinates,
  parkCoordinates,
  transportMode,
  routeOption,
  setIsScenicAvailable,
  setTurnByTurnDirections,
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const directions = useRef(null);
  const [defaultRoute, setDefaultRoute] = useState(null);
  const [scenicRoute, setScenicRoute] = useState(null);
  const [mapInitialized, setMapInitialized] = useState(false);

  // Wrap melbourneBounds in useMemo to ensure it's not recreated on every render
  const melbourneBounds = useMemo(
    () => [
      [144.5937, -38.4339], // Southwest corner
      [145.5125, -37.5113], // Northeast corner
    ],
    []
  );

  // Fetch routes for the selected mode of transport
  const fetchRoutesForMode = useCallback(
    (mode, origin, destination) => {
      console.log(`Fetching routes for ${mode}.`);
      console.log(
        `From: ${origin.latitude}, ${origin.longitude} to ${destination.latitude}, ${destination.longitude}`
      );

      if (!directions.current) {
        directions.current = new MapboxDirections({
          accessToken: mapboxgl.accessToken,
          unit: "metric",
          profile: `mapbox/${mode}`,
          alternatives: true, // Fetch scenic route as an alternative
          controls: { instructions: false, inputs: false },
          interactive: false,
        });
      }

      directions.current.setOrigin([origin.longitude, origin.latitude]);
      directions.current.setDestination([
        destination.longitude,
        destination.latitude,
      ]);

      directions.current.on("route", (e) => {
        const routes = e.route;
        console.log("Routes fetched:", routes);

        if (routes.length > 0) {
          setDefaultRoute(routes[0]); // Eco-friendly route (default)
          setTurnByTurnDirections(formatTurnByTurnInstructions(routes[0]));

          if (routes.length > 1) {
            setScenicRoute(routes[1]); // Scenic route as an alternative
            setIsScenicAvailable(true);
          } else {
            setScenicRoute(null);
            setIsScenicAvailable(false);
          }
        }
      });
    },
    [setTurnByTurnDirections, setIsScenicAvailable]
  );

  // Format turn-by-turn instructions
  const formatTurnByTurnInstructions = (route) => {
    const legs = route.legs[0]; // Assumes only one leg
    return legs.steps.map((step) => ({
      instruction: step.maneuver.instruction,
      distance: step.distance,
      duration: step.duration,
    }));
  };

  // Draw the selected route on the map
  const drawRouteOnMap = (route, isScenic) => {
    if (!map.current || !route || !route.geometry) return;

    // Check if the style is loaded before adding the source
    if (!map.current.isStyleLoaded()) {
      console.error("Style is not loaded yet, retrying...");
      return; // Wait until the style is loaded
    }

    let coordinates = [];

    if (typeof route.geometry === "string") {
      coordinates = polyline
        .decode(route.geometry)
        .map((coord) => [coord[1], coord[0]]);
    } else if (route.geometry.coordinates) {
      coordinates = route.geometry.coordinates;
    }

    const routeGeoJSON = {
      type: "Feature",
      properties: {},
      geometry: { type: "LineString", coordinates },
    };

    if (map.current.getSource("route")) {
      map.current.removeLayer("routeLayer");
      map.current.removeSource("route");
    }

    map.current.addSource("route", {
      type: "geojson",
      data: routeGeoJSON,
    });

    map.current.addLayer({
      id: "routeLayer",
      type: "line",
      source: "route",
      layout: { "line-join": "round", "line-cap": "round" },
      paint: {
        "line-color": isScenic ? "#007FFF" : "#388E3C",
        "line-width": 10,
      },
    });

    const bounds = coordinates.reduce(
      (bounds, coord) => bounds.extend(coord),
      new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
    );
    map.current.fitBounds(bounds, { padding: 50 });
  };

  useEffect(() => {
    if (mapInitialized) {
      // Check which route to draw based on the selected route option
      if (routeOption === "scenic" && scenicRoute) {
        console.log("Drawing the scenic route.");
        drawRouteOnMap(scenicRoute, true); // Draw scenic route
        setTurnByTurnDirections(formatTurnByTurnInstructions(scenicRoute));
      } else if (defaultRoute) {
        console.log("Drawing the eco-friendly (default) route.");
        drawRouteOnMap(defaultRoute, false); // Draw eco-friendly route
        setTurnByTurnDirections(formatTurnByTurnInstructions(defaultRoute));
      }
    }
  }, [
    routeOption, // Trigger redraw when route option changes
    scenicRoute, // Trigger redraw when scenic route changes
    defaultRoute, // Trigger redraw when default route changes
    mapInitialized, // Only draw after the map is initialized
    setTurnByTurnDirections,
  ]);

  useEffect(() => {
    if (!parkCoordinates || !userCoordinates) return;

    const parsedParkCoordinates =
      typeof parkCoordinates === "string"
        ? {
            latitude: parseFloat(
              parkCoordinates.replace(/[()]/g, "").split(",")[0]
            ),
            longitude: parseFloat(
              parkCoordinates.replace(/[()]/g, "").split(",")[1]
            ),
          }
        : parkCoordinates;

    if (!map.current) {
      // Initialize Mapbox map
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [userCoordinates.longitude, userCoordinates.latitude],
        zoom: 12,
        maxBounds: melbourneBounds,
      });

      map.current.on("load", () => {
        setMapInitialized(true);

        if (map.current.isStyleLoaded()) {
          // Add markers for user and park locations
          new mapboxgl.Marker({ color: "red" })
            .setLngLat([userCoordinates.longitude, userCoordinates.latitude])
            .addTo(map.current);

          new mapboxgl.Marker({ color: "green" })
            .setLngLat([
              parsedParkCoordinates.longitude,
              parsedParkCoordinates.latitude,
            ])
            .addTo(map.current);

          // Fetch the route once the map is ready
          if (transportMode) {
            fetchRoutesForMode(
              transportMode,
              userCoordinates,
              parsedParkCoordinates
            );
          }
        }
      });
    } else if (transportMode) {
      // Clear previous routes and fetch new ones
      fetchRoutesForMode(transportMode, userCoordinates, parsedParkCoordinates);
    }

    return () => {
      if (map.current && directions.current) {
        directions.current.removeRoutes();
        if (map.current.hasControl(directions.current)) {
          map.current.removeControl(directions.current);
        }
        map.current.remove();
        map.current = null;
        directions.current = null;
      }
    };
  }, [
    userCoordinates,
    parkCoordinates,
    transportMode,
    fetchRoutesForMode,
    melbourneBounds,
  ]);

  return (
    <div
      ref={mapContainer}
      style={{
        width: "100%",
        height: "100%", // Full height of the viewport
        borderRadius: "12px", // Keep the border-radius if desired
      }}
    />
  );
};

export default NavigationMap;

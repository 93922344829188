import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Rating } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import StarIcon from "@mui/icons-material/Star";

import toiletIcon from "../assets/exploreIcon/toilet.png";
import barbecueIcon from "../assets/exploreIcon/barbecue.png";
import bicycleRailsIcon from "../assets/exploreIcon/bicycle_rails.png";
import binIcon from "../assets/exploreIcon/bin.png";
import drinkingFountainIcon from "../assets/exploreIcon/drinking_fountain.png";
import picnicSettingIcon from "../assets/exploreIcon/picnic_setting.png";
import playgroundIcon from "../assets/exploreIcon/playground.png";
import seatIcon from "../assets/exploreIcon/seat.png";

// Styled components
const ResultCardWrapper = styled.div`
  background-color: ${({ $cardBackground }) => $cardBackground};
  padding: 25px;
  border-radius: 15px;
  width: 350px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 20px;
`;

const ResultTitle = styled.h2`
  font-size: 28px;
  font-weight: bold;
  color: ${({ $headerColor }) => $headerColor};
  margin-bottom: 10px;
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background-color: ${({ color }) => color || "#ccc"};
  margin: 15px 0;
`;

const InfoSection = styled.div`
  margin-bottom: 10px;
  font-size: 15px;
`;

const AmenitiesGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const AmenityIcon = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

const AmenityTooltip = styled.div`
  position: relative;

  &:hover::after {
    content: "${(props) => props.tooltip}";
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
  }
`;

const VisitButton = styled.button`
  padding: 12px 20px;
  background-color: ${({ $buttonColor }) => $buttonColor};
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ $buttonHoverColor }) => $buttonHoverColor};
  }
`;

const CategoryText = styled.p`
  font-size: 14px;
  color: #888;
  margin-top: 5px;
`;

const SmallText = styled.p`
  font-size: 15px;
  margin-bottom: 10px;
`;

// New style for the keyword buttons
const KeywordsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;

const KeywordButton = styled.button`
  background-color: #e6decf;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #598f57;
    color: white;
  }
`;

// Define the amenityIconMap
const amenityIconMap = {
  Toilets: toiletIcon,
  Barbecues: barbecueIcon,
  "Bicycle Rails": bicycleRailsIcon,
  "Litter Bin": binIcon,
  "Drinking Fountain": drinkingFountainIcon,
  "Picnic Setting": picnicSettingIcon,
  Playgrounds: playgroundIcon,
  Seat: seatIcon,
};

const ResultCard = ({
  name,
  category,
  opening_hours,
  amenities,
  user_rating,
  sustainability_score,
  associated_keywords,
  colors,
  parsedData,
  placeId,
  userCoordinates,
}) => {
  const navigate = useNavigate();

  // Extract park coordinates from the parsedData (assuming parsedData has the coords in the format '(lat, lon)')
  const parkCoordinates = parsedData?.coords
    ? {
        latitude: parseFloat(
          parsedData.coords.split(",")[0].replace(/[()]/g, "")
        ),
        longitude: parseFloat(
          parsedData.coords.split(",")[1].replace(/[()]/g, "")
        ),
      }
    : null;

  // Display opening hours or default to "No hours available"
  const displayOpeningHours = opening_hours
    ? opening_hours
    : "No hours available";

  // Display user rating or "No reviews yet" if rating is 0 or doesn't exist
  const displayUserRating =
    user_rating && parseFloat(user_rating) > 0
      ? `${user_rating} / 5`
      : "No reviews yet";

  // Handle keywords or display "No reviews available"
  const displayKeywords =
    associated_keywords && associated_keywords.length > 0
      ? associated_keywords
      : [];

  // Deduplicate "Barbecues" and "Barbecue"
  const deduplicatedAmenities = amenities
    ? [
        ...new Set(
          amenities.map((amenity) =>
            amenity === "Barbecue" ? "Barbecues" : amenity
          )
        ),
      ]
    : [];

  const handleVisitNowClick = () => {
    console.log("Navigating to navigate page with the following data:");
    console.log("Place ID:", placeId);
    console.log("Category:", category);
    console.log("User Coordinates:", userCoordinates);
    console.log("Park Coordinates:", parkCoordinates);

    if (parkCoordinates && userCoordinates) {
      navigate("/navigate", {
        state: {
          placeId, // Pass the placeId
          category, // Pass the category
          userCoordinates, // Pass the user's location
          park: {
            coords: `(${parkCoordinates.latitude}, ${parkCoordinates.longitude})`,
          }, // Format same as NearbyGreenSpace
        },
      });
    } else {
      console.error("Missing park or user coordinates!");
    }
  };

  // Check if sustainability score from parsedData is null or undefined
  const showSustainabilityScore =
    parsedData && parsedData.sustainability_score !== null;

  return (
    <ResultCardWrapper $cardBackground={colors.cardBackground}>
      {/* Title Section */}
      <ResultTitle $headerColor={colors.header}>{name}</ResultTitle>

      {/* Category below title */}
      <CategoryText>{category || "N/A"}</CategoryText>

      <Divider color="598F57" />

      {/* General Info Section */}
      <InfoSection>
        <SmallText>
          <strong>Opening Hours:</strong> {displayOpeningHours}
        </SmallText>
      </InfoSection>

      <Divider />

      {/* User Rating Section */}
      <InfoSection>
        <SmallText>
          <strong>User Rating:</strong>
        </SmallText>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Rating
            name="user-rating"
            value={parseFloat(user_rating) || 0}
            precision={0.1}
            readOnly
          />
          <Tooltip title="Based on Google reviews">
            <InfoIcon
              style={{
                marginLeft: "5px",
                color: "#598F57",
                cursor: "pointer",
                fontSize: "20px",
              }}
            />
          </Tooltip>
        </div>

        {/* Conditionally show sustainability score based on parsedData */}
        {showSustainabilityScore && sustainability_score > 0 && (
          <>
            <SmallText>
              <strong>Sustainability Score:</strong>
            </SmallText>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Rating
                name="sustainability-score"
                value={parseFloat(sustainability_score) || 0}
                precision={0.1}
                readOnly
                icon={<StarIcon style={{ color: "598F57" }} />} // Green stars for sustainability score
                emptyIcon={<StarIcon style={{ color: "#ddd" }} />} // Gray stars when empty
              />
              <Tooltip title="Based on Wikipedia sustainable features, amenities promoting sustainable living, average user rating, and Google review">
                <InfoIcon
                  style={{
                    marginLeft: "5px",
                    color: "598F57",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              </Tooltip>
            </div>
          </>
        )}
      </InfoSection>

      <Divider />

      {/* Amenities Section */}
      <SmallText>
        <strong>Amenities:</strong>
      </SmallText>
      <AmenitiesGrid>
        {deduplicatedAmenities.length > 0 ? (
          deduplicatedAmenities
            .filter((amenity) => amenityIconMap[amenity]) // Only show amenities with icons
            .map((amenity, index) => (
              <AmenityTooltip key={index} tooltip={amenity}>
                <AmenityIcon src={amenityIconMap[amenity]} alt={amenity} />
              </AmenityTooltip>
            ))
        ) : (
          <p>Amenity data for this place is not available</p>
        )}
      </AmenitiesGrid>

      <Divider />

      {/* Keywords Section */}
      <InfoSection>
        <SmallText>
          <strong>What people are saying?:</strong>
        </SmallText>
        <KeywordsContainer>
          {displayKeywords.length > 0 ? (
            displayKeywords.map((keyword, index) => (
              <KeywordButton key={index}>{keyword}</KeywordButton>
            ))
          ) : (
            <p>No reviews available</p>
          )}
        </KeywordsContainer>
      </InfoSection>

      <VisitButton
        $buttonColor={"#598F57"}
        $buttonHoverColor={"#417446"}
        onClick={handleVisitNowClick} // Navigate on button click
      >
        Visit Now
      </VisitButton>
    </ResultCardWrapper>
  );
};

export default ResultCard;

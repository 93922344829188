import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import homepageImage from "../assets/images/homepage.jpg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ParkIcon from "@mui/icons-material/Update";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import WbSunnyIcon from "@mui/icons-material/WbSunnyOutlined";
import NatureIcon from "@mui/icons-material/ForestOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";

// Keyframes for animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(65, 116, 70, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 15px 15px rgba(65, 116, 70, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(65, 116, 70, 0);
  }
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

const slideInUp = keyframes`
  from { transform: translateY(100px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

// Styled components for layout and sections
const Container = styled.div`
  font-family: "Arial", sans-serif;
  color: #333;
  background-color: #d4ceae; // Variant white
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const HeroSection = styled.section`
  width: 100vw;
  height: 100vh;
  background: url(${homepageImage}) center/cover no-repeat, rgba(0, 0, 0, 0.3); /* Darken the background image */
  background-blend-mode: overlay;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  animation: ${fadeIn} 2s ease-in-out;
`;

// Transparent overlay for the text
const TransparentOverlay = styled.div`
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Increase opacity for better contrast */
  padding: 40px;
  border-radius: 15px;
  max-width: 80%; /* Increase the width to avoid long text lines */
  color: white;
  animation: ${fadeIn} 2s ease-in-out;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5); /* Add shadow for depth */
`;

const HeroTitle = styled.h2`
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 8px;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7); /* Stronger shadow for readability */
  @media (max-width: 768px) {
    font-size: 36px; /* Smaller size for mobile screens */
  }
`;

const HeroSubtitle = styled.p`
  font-size: 22px;
  margin-bottom: 30px;
  line-height: 1.6; /* Better spacing between lines */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Slightly stronger shadow */
  @media (max-width: 768px) {
    font-size: 18px; /* Adjust font size for smaller screens */
  }
`;

const LearnMoreButton = styled.button`
  padding: 15px 40px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: #417446;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  &:hover {
    background-color: #598f57;
    transform: scale(1.05); /* Slight scale-up on hover */
  }
`;
// Arrow animation
const ScrollIcon = styled(ExpandMoreIcon)`
  position: absolute;
  bottom: 20px;
  font-size: 70px;
  color: white;
  animation: ${bounce} 2s infinite; // Bounce animation
  cursor: pointer;
`;

const SectionTitle = styled.h3`
  font-size: 36px;
  margin-bottom: 20px;
  color: #417446;
  text-align: center;
  animation: ${slideInUp} 1s ease;
`;
const SectionTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap; /* Allow text to wrap on smaller screens */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack titles vertically on smaller screens */
  }
`;

const InlineSectionTitle = styled.h3`
  font-size: 36px;
  margin-bottom: 20px;
  color: ${(props) => props.color || "#417446"};
  text-align: center;
  margin: 0 5px; /* Add spacing between the two texts */
  display: inline;

  @media (max-width: 768px) {
    font-size: 28px; /* Reduce size on tablets */
    margin-bottom: 15px; /* Adjust spacing */
  }

  @media (max-width: 480px) {
    font-size: 22px; /* Reduce further for mobile */
    margin-bottom: 10px;
  }
`;

// Problem Section
const ProblemSection = styled.section`
  width: 100vw;
  padding: 60px 20px;
  background-color: #e3dfc8;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  animation: ${fadeIn} 2s ease forwards;
  animation-delay: 0.5s; // Delayed to appear smoothly after scroll
`;

const ProblemText = styled.p`
  max-width: 800px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.6;
`;

const SellingPointSection = styled.section`
  width: 100vw;
  padding: 60px 20px;
  background-color: #f0ebd4;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  ); /* Dynamic columns */
  gap: 40px; /* Adjust gap as needed */
  width: 100%; /* Ensure grid takes full width */
  max-width: 1200px;
  justify-items: center;
  margin-top: 30px;
`;

const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: transform 0.3s ease;
  width: 300px; /* Fixed width */
  height: auto;
  &:hover {
    transform: scale(1.05);
    color: #417446;
  }
`;

const FeatureIcon = styled.div`
  font-size: 50px;
  margin-bottom: 10px;
  color: #573e38;
  background-color: #b2c7b4;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 30px; /* Adjust for smaller screens */
    padding: 10px;
  }
`;

const FeatureHeading = styled.h4`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #573e38; // Text color for headings
`;

const FeatureText = styled.p`
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  color: #454237;
`;

const ListSection = styled.section`
  width: 100vw;
  padding: 20px 20px -10px 20px;
  background-color: #f2f1ed;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  max-width: 800px;
`;

const ListItem = styled.li`
  font-size: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const Icon = styled.span`
  font-size: 30px;
  margin-right: 15px;
`;

const ListText = styled.span`
  line-height: 1.6;
`;

// Benefits Section
const BenefitsSection = styled.section`
  width: 100vw;
  padding: 50px 20px;
  background-color: #e2dac9;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BenefitGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 cards for large screens */
  gap: 30px;
  width: 80%;
  max-width: 1200px;
  animation: ${slideInUp} 1s ease;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 2 cards on medium screens */
    gap: 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* 1 card on small screens */
    gap: 20px;
  }
`;

const BenefitCard = styled.div`
  background-color: #f0ebd4;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: scale(1.05);
    color: #417446;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Enhanced hover effect */
  }
`;

const NumberContainer = styled.div`
  font-size: 56px;
  font-weight: bold;
  color: #598f57;
  background: #f0ebd4;
  padding: 20px;
  border-radius: 12px;
  // box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 150px;
  min-width: 150px;
  margin: 0 auto; /* Centering horizontally */
`;

const BenefitText = styled.p`
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
`;

const CountupContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: 26px;
`;

const CallToActionSection = styled.section`
  padding: 50px;
  background-color: #598f57;
  color: white;
  text-align: center;
`;

const CallToActionButton = styled.button`
  padding: 20px 50px;
  font-size: 20px;
  font-weight: bold;
  color: #417446; /* Dark green text */
  background-color: #fffacd; /* Light yellow background */
  border: 3px solid #417446;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);

  /* Add the pulse animation */
  animation: ${pulse} 2s infinite;

  &:hover {
    background-color: #fff7b0; /* Slightly brighter on hover */
    transform: scale(1.05); /* Slightly larger on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
  }

  &:active {
    transform: scale(0.98); /* Press-down effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const Footer = styled.footer`
  padding: 30px;
  background-color: #417446;
  color: white;
  text-align: center;
`;

// Other section scroll icons
const SectionScrollIcon = styled(ExpandMoreIcon)`
  margin-top: 40px;
  font-size: 70px;
  color: #417446;
  animation: ${bounce} 2s infinite;
  cursor: pointer;
`;

function LandingPage() {
  const [startCount, setStartCount] = useState(false);
  const navigate = useNavigate(); // Correct initialization
  // const problemSectionRef = useRef(null);
  const sellingPointRef = useRef(null);
  const problemSectionRef = useRef(null);
  const benefitsSectionRef = useRef(null);
  const { inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setStartCount(true);
    }
  }, [inView]);

  // const scrollToProblemSection = () => {
  //   problemSectionRef.current.scrollIntoView({ behavior: "smooth" });
  // };

  // Smooth scroll functions
  const scrollToSellingPointSection = () => {
    sellingPointRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToProblemSection = () => {
    problemSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToBenefitsSection = () => {
    benefitsSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleAssessmentClick = () => {
    navigate("/assessment");
  };

  const handleLearnMoreClick = () => {
    navigate("/why-green-spaces");
  };

  return (
    <Container>
      {/* Hero Section */}
      <HeroSection>
        <TransparentOverlay>
          <HeroTitle>Where Family Meets Sustainability</HeroTitle>
          <HeroSubtitle>
            Want to contribute towards a greener city of Melbourne? <br />
            <span style={{ fontWeight: "bold", color: "#417446" }}>
              Discover green space
            </span>{" "}
            for busy parents within a
            <span style={{ fontWeight: "bold", color: "#598f57" }}>
              {" "}
              10 km radius
            </span>
            .
          </HeroSubtitle>

          <LearnMoreButton onClick={scrollToSellingPointSection}>
            Learn More
          </LearnMoreButton>
        </TransparentOverlay>
        {/* Scroll Down Icon */}
        <ScrollIcon onClick={scrollToSellingPointSection} />{" "}
        {/* Bouncing icon */}
      </HeroSection>

      {/* Problem Section
      <ProblemSection ref={problemSectionRef}>
        <SectionTitle>Ever Wondered Why It's Still Hard to Find a Peaceful Green Space?</SectionTitle>
        <ProblemText>
          Despite the City of Melbourne having many green spaces, finding a peaceful and uncrowded spot can be challenging. Overcrowding, rapid urban development, and busy schedules make it difficult for families to enjoy a moment of tranquility in nature.
        </ProblemText>
      </ProblemSection> */}
      <SellingPointSection ref={sellingPointRef}>
        <SectionTitleContainer>
          <InlineSectionTitle color="#573E38">Why you need</InlineSectionTitle>
          <InlineSectionTitle color="#417446">GreenFinder</InlineSectionTitle>
        </SectionTitleContainer>
        <FeatureGrid>
          <FeatureCard>
            <FeatureIcon>
              <ParkIcon style={{ fontSize: 50 }} />
            </FeatureIcon>
            <FeatureText>
              <FeatureHeading>Personalized Park Experiences:</FeatureHeading>
              GreenFinder offers real-time recommendations based on your
              preferences for crowd levels, amenities, and park features.
            </FeatureText>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon>
              <DirectionsWalkIcon style={{ fontSize: 50 }} />
            </FeatureIcon>
            <FeatureText>
              <FeatureHeading>Eco-Friendly Travel Solutions:</FeatureHeading>
              We promote sustainable living by guiding users to parks via
              eco-friendly routes, such as walking, biking, or public transport.
            </FeatureText>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon>
              <WbSunnyIcon style={{ fontSize: 50 }} />
            </FeatureIcon>
            <FeatureText>
              <FeatureHeading>
                Smarter, Healthier Outdoor Planning:
              </FeatureHeading>
              Plan stress-free visits with live data on foot traffic, shaded
              areas, UV index, and weather conditions.
            </FeatureText>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon>
              <NatureIcon style={{ fontSize: 50 }} />
            </FeatureIcon>
            <FeatureText>
              <FeatureHeading>
                Supporting Melbourne’s Sustainability Goals:
              </FeatureHeading>
              By using GreenFinder, you’re contributing to Melbourne’s
              sustainability efforts, reducing overcrowding, and supporting
              preservation.
            </FeatureText>
          </FeatureCard>
        </FeatureGrid>
        {/* Scroll down to the benefits section */}
        <SectionScrollIcon onClick={scrollToBenefitsSection} />
      </SellingPointSection>

      {/* How Green Spaces Help Section
      <ListSection>
        <SectionTitle>How Green Spaces Help the City and Its Residents</SectionTitle>
        <List>
          <ListItem>
            <Icon>🌳</Icon>
            <ListText>Healthier Living: Visits to green spaces reduce stress, improve health, and lower congestion in urban areas.</ListText>
          </ListItem>
          <ListItem>
            <Icon>🚴‍♀️</Icon>
            <ListText>Sustainable Mobility: Walking or biking to parks reduces your carbon footprint, helping create cleaner air.</ListText>
          </ListItem>
          <ListItem>
            <Icon>🌿</Icon>
            <ListText>Environmental Benefits: Green spaces foster biodiversity, enhance safety, and reduce urban heat.</ListText>
          </ListItem>
          <ListItem>
            <Icon>☀️</Icon>
            <ListText>Cooler Cities: Green spaces absorb heat, making cities more livable, especially during summer months.</ListText>
          </ListItem>
        </List>
      </ListSection> */}

      {/* Benefits Section */}
      <BenefitsSection ref={benefitsSectionRef}>
        <BenefitGrid>
          <BenefitCard>
            <NumberContainer>
              <CountUp start={0} end={50} duration={2.5} suffix="%" />
            </NumberContainer>
            <BenefitText>
              Reduction in Carbon Footprint by walking or biking to parks.
            </BenefitText>
          </BenefitCard>

          <BenefitCard>
            <NumberContainer>
              <CountUp start={0} end={20} duration={2.5} suffix="%" />
            </NumberContainer>
            <BenefitText>Energy savings by spending time outdoors.</BenefitText>
          </BenefitCard>

          <BenefitCard>
            <NumberContainer>
              <CountUp start={0} end={7} duration={2.5} suffix="%" />
            </NumberContainer>
            <BenefitText>
              Boost in local economy by increasing foot traffic.
            </BenefitText>
          </BenefitCard>

          <BenefitCard>
            <NumberContainer>
              <CountUp start={0} end={15} duration={2.5} suffix="%" />
            </NumberContainer>
            <BenefitText>
              Increase in community engagement through green spaces.
            </BenefitText>
          </BenefitCard>
        </BenefitGrid>
        {/* <BenefitText
          style={{ fontSize: "18px", marginTop: "45px", marginBottom: "0px" }}
        >
          <Link
            to="/why-green-spaces"
            style={{
              display: "inline-block",
              padding: "10px 20px",
              fontSize: "20px",
              color: "#417446", // Button text color
              border: "2px solid #417446", // Button border
              borderRadius: "25px", // Rounded corners
              textDecoration: "none", // Remove underline
              cursor: "pointer",
              transition: "background-color 0.3s ease, color 0.3s ease", // Add transition for hover effect
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#417446"; // Change background color on hover
              e.target.style.color = "#fff"; // Change text color on hover
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "transparent"; // Revert to transparent background
              e.target.style.color = "#417446"; // Revert to original text color
            }}
          >
            Why Green?
          </Link>
        </BenefitText>
        <BenefitText
          style={{
            fontSize: "14px",
            marginTop: "10px",
            color: "#555",
            textAlign: "center",
          }}
        >
          Learn more on why green spaces are important.
        </BenefitText> */}
      </BenefitsSection>

      {/* Call to Action Section */}
      <CallToActionSection>
        <h2>
          Want to Know Your Impact on the City of Melbourne's Sustainability?
        </h2>
        <p>
          Discover how your lifestyle contributes to a greener City of
          Melbourne. Learn where you stand and take action to amplify your
          impact.
        </p>
        <CallToActionButton onClick={handleAssessmentClick}>
          See Your Contribution Now
        </CallToActionButton>

        <Typography
          variant="body1"
          color="#EFEAE0"
          sx={{ marginTop: "40px", textAlign: "center" }}
        >
          or
        </Typography>

        <BenefitText
          style={{ fontSize: "18px", marginTop: "45px", marginBottom: "0px" }}
        >
          <Link
            to="/why-green-spaces"
            style={{
              display: "inline-block",
              padding: "10px 20px",
              fontSize: "20px",
              color: "#EFEAE0", // Button text color
              border: "2px solid #EFEAE0", // Button border
              borderRadius: "25px", // Rounded corners
              textDecoration: "none", // Remove underline
              cursor: "pointer",
              transition: "background-color 0.3s ease, color 0.3s ease", // Add transition for hover effect
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#417446"; // Change background color on hover
              e.target.style.color = "#EFEAE0"; // Change text color on hover
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "transparent"; // Revert to transparent background
              e.target.style.color = "#EBDCC1"; // Revert to original text color
            }}
          >
            Why Green?
          </Link>
        </BenefitText>
        <BenefitText
          style={{
            fontSize: "14px",
            marginTop: "10px",
            color: "#ECE6DA",
            textAlign: "center",
          }}
        >
          Learn more on why green spaces are important.
        </BenefitText>
      </CallToActionSection>

      {/* Footer */}
      <Footer>
        <p>&copy; 2024 GreenFinder. All rights reserved.</p>
      </Footer>
    </Container>
  );
}

export default LandingPage;

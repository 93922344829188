import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EcoIcon from "@mui/icons-material/Nature";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import SearchIcon from "@mui/icons-material/Search";
import backgroundImage from "../assets/images/Melb.jpg";
import PersonIcon from "@mui/icons-material/Person";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q";
const MELBOURNE_BBOX = "144.5937,-38.4339,145.5126,-37.5113";

function QuestionPage() {
  const [step, setStep] = useState(1);
  const [location, setLocation] = useState("");
  const [coordinates, setCoordinates] = useState({
    latitude: -37.8136,
    longitude: 144.9631,
  });
  const [loading, setLoading] = useState(false);
  const [nearbySpaces, setNearbySpaces] = useState([]); // Store nearby green spaces with place_ids
  const navigate = useNavigate();

  const goToStep = (stepNumber) => {
    console.log(`Proceeding to step ${stepNumber}`);
    setStep(stepNumber);
  };

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAPBOX_TOKEN}`
      );
      const data = await response.json();
      if (data.features && data.features.length > 0) {
        return data.features[0].place_name;
      }
    } catch (error) {
      console.error("Error during reverse geocoding:", error);
    }
    return "Unknown Location";
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude });
          const placeName = await reverseGeocode(latitude, longitude);
          setLocation(placeName);
          handleFetchNearbyGreenSpaces(placeName);
        },
        (error) => {
          console.error("Error fetching the current location:", error);
          alert("Unable to retrieve your location. Please try again.");
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };

  // Fetch nearby green spaces using the user's location or input
  const handleFetchNearbyGreenSpaces = async (inputLocation = "Melbourne") => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.greenfinderinmelb.me/api/find-nearby-parks/?location=${encodeURIComponent(
          inputLocation
        )}&latitude=${coordinates.latitude}&longitude=${coordinates.longitude}`
      );
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        setNearbySpaces(data); // Save the fetched nearby green spaces with place_id
        console.log("Nearby green spaces with place_id:", data);
        goToStep(2); // Proceed to step 2 when green spaces are found
      } else {
        await handleFetchNearbyGreenSpaces("Melbourne");
      }
    } catch (error) {
      console.error("Error fetching nearby green spaces:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to collect all place_id from nearby green spaces
  const collectPlaceIds = () => {
    const placeIds = nearbySpaces.map((space) => space.id); // Collect all the place_id
    return placeIds;
  };

  // Function to parse and navigate to the results page with proper key-value pairs, including place_ids array
  const navigateWithAnswers = (
    category,
    amenities = [],
    crowding_level,
    sustainability_score = null
  ) => {
    const place_ids = collectPlaceIds(); // Collect all place_ids

    const userAnswers = {
      location, // Location of the user
      coordinates, // Latitude and Longitude of the user
      category, // Type of green space (e.g., Parks and Gardens, Sportsfields, etc.)
      amenities, // List of amenities like Drinking Fountain, Seat, etc.
      crowding_level, // Crowding level like Low, Moderate, etc.
      sustainability_score, // Optional sustainability score
      place_ids, // The array of all nearby green spaces' place_ids
    };
    console.log("Navigating with userAnswers:", userAnswers);

    // Navigate to the personalized results page with the collected data
    navigate("/personalized-results", { state: { userAnswers } });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "20px",
      }}
    >
      <Card
        sx={{
          maxWidth: 600,
          width: "100%",
          mb: 6,
          mt: 4,
          boxShadow: 3,
          borderRadius: 4,
          padding: 4,
          backgroundColor: "#F8F6F2",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          {/* Step 1 - Initial Location Input */}
          {step === 1 && (
            <>
              <Typography
                gutterBottom
                sx={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "#598F57",
                  textAlign: "center",
                  mb: 3,
                  letterSpacing: "1px",
                }}
              >
                Let's Plan Your Green Space Trip
              </Typography>

              <Typography
                sx={{
                  mb: 2,
                  fontSize: "20px",
                  textAlign: "center",
                  color: "#555",
                }}
              >
                Where are you currently?
              </Typography>

              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Autocomplete
                  freeSolo
                  options={[]}
                  getOptionLabel={(option) => option.label}
                  onInputChange={(event, value) => {
                    console.log(`Autocomplete input changed: ${value}`);
                  }}
                  onChange={(event, newValue) => {
                    if (newValue?.label && newValue?.coordinates) {
                      setLocation(newValue.label);
                      setCoordinates({
                        latitude: newValue.coordinates[1],
                        longitude: newValue.coordinates[0],
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter your location"
                      variant="outlined"
                      fullWidth
                      sx={{
                        flexGrow: 1,
                        backgroundColor: "white",
                        width: {
                          xs: "380%", // Full width on extra-small screens
                          sm: "380%", // Full width on small screens
                          md: "450px", // 450px width on medium screens
                          lg: "450px", // 450px width on large screens
                        },
                      }}
                    />
                  )}
                />

                <IconButton
                  onClick={() => handleFetchNearbyGreenSpaces(location)}
                  sx={{
                    backgroundColor: "#598F57",
                    color: "white",
                    "&:hover": { backgroundColor: "#4A7747" },
                    width: 48,
                    height: 48,
                    ml: { xs: 23, md: 2 },
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Box>

              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ margin: "0 10px", textAlign: "center" }}
              >
                OR
              </Typography>
              <Button
                variant="contained"
                onClick={getCurrentLocation}
                fullWidth
                sx={{
                  mt: 2,
                  backgroundColor: "#598F57",
                  color: "white",
                  "&:hover": { backgroundColor: "#4A7747" },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Use Current Location"
                )}
              </Button>
            </>
          )}

          {/* Step 2 - Plan to Go */}
          {step === 2 && (
            <>
              <Typography variant="h5" gutterBottom>
                Plan to go...
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    startIcon={<PersonIcon />}
                    variant="contained"
                    fullWidth
                    sx={{ backgroundColor: "#598F57" }}
                    onClick={() => goToStep(3)}
                  >
                    By Myself
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    startIcon={<FamilyRestroomIcon />}
                    variant="contained"
                    fullWidth
                    sx={{ backgroundColor: "#598F57" }}
                    onClick={() => goToStep(4)}
                  >
                    With My Family
                  </Button>
                </Grid>
              </Grid>
              {/* Back Button */}
              <Button onClick={() => goToStep(1)} sx={{ mt: 2 }}>
                Back to Location
              </Button>
            </>
          )}

          {/* Step 3 - By Myself */}
          {step === 3 && (
            <>
              <Typography variant="h5" gutterBottom>
                My main goal is to...
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    startIcon={<FitnessCenterIcon />}
                    variant="contained"
                    fullWidth
                    sx={{ backgroundColor: "#598F57" }}
                    onClick={() => goToStep(5)}
                  >
                    Improve my physical and mental health
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    startIcon={<EcoIcon />}
                    variant="contained"
                    fullWidth
                    sx={{ backgroundColor: "#598F57" }}
                    onClick={() =>
                      navigateWithAnswers("Parks and Gardens", [], "Low", 4)
                    }
                  >
                    Make the City of Melbourne more sustainable
                  </Button>
                </Grid>
              </Grid>
              {/* Back Button */}
              <Button onClick={() => goToStep(2)} sx={{ mt: 2 }}>
                Back to Plan to Go
              </Button>
            </>
          )}

          {/* Step 5 - Health Options (By Myself) */}
          {step === 5 && (
            <>
              <Typography variant="h5" gutterBottom>
                I plan to...
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ backgroundColor: "#598F57" }}
                    onClick={() =>
                      navigateWithAnswers(
                        "Parks and Gardens",
                        ["Drinking Fountain"],
                        "Low"
                      )
                    }
                  >
                    Stretching and doing some exercise
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ backgroundColor: "#598F57" }}
                    onClick={() =>
                      navigateWithAnswers("Parks and Gardens", ["Seat"], "Low")
                    }
                  >
                    Relax and enjoy green space
                  </Button>
                </Grid>
              </Grid>
              {/* Back Button */}
              <Button onClick={() => goToStep(3)} sx={{ mt: 2 }}>
                Back to My Main Goal
              </Button>
            </>
          )}

          {/* Step 4 - With My Family */}
          {step === 4 && (
            <>
              <Typography variant="h5" gutterBottom>
                Do you have kids?
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ backgroundColor: "#598F57" }}
                    onClick={() => goToStep(6)}
                  >
                    Yes
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ backgroundColor: "#598F57" }}
                    onClick={() => goToStep(7)}
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
              {/* Back Button */}
              <Button onClick={() => goToStep(2)} sx={{ mt: 2 }}>
                Back to Plan to Go
              </Button>
            </>
          )}

          {/* Step 6 - Kids' Age */}
          {step === 6 && (
            <>
              <Typography variant="h5" gutterBottom>
                How old are your kids?
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ backgroundColor: "#598F57" }}
                    onClick={() =>
                      navigateWithAnswers("Playgrounds", [], "High")
                    }
                  >
                    0-6 years old
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ backgroundColor: "#598F57" }}
                    onClick={() =>
                      navigateWithAnswers(
                        "Sportsfields and organised recreation",
                        [],
                        "Low"
                      )
                    }
                  >
                    7 years old or older
                  </Button>
                </Grid>
              </Grid>
              {/* Back Button */}
              <Button onClick={() => goToStep(4)} sx={{ mt: 2 }}>
                Back to Do You Have Kids?
              </Button>
            </>
          )}

          {/* Step 7 - Family Plan to... */}
          {step === 7 && (
            <>
              <Typography variant="h5" gutterBottom>
                I plan to...
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    startIcon={<FitnessCenterIcon />}
                    variant="contained"
                    fullWidth
                    sx={{ backgroundColor: "#598F57" }}
                    onClick={() =>
                      navigateWithAnswers(
                        "Parks and Gardens",
                        ["Drinking Fountain", "Seat"],
                        "Low"
                      )
                    }
                  >
                    Doing exercise
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    startIcon={<LocalDiningIcon />}
                    variant="contained"
                    fullWidth
                    sx={{ backgroundColor: "#598F57" }}
                    onClick={() =>
                      navigateWithAnswers(
                        "Parks and Gardens",
                        ["Picnic Setting", "Seat"],
                        "Low"
                      )
                    }
                  >
                    Having family picnic/BBQ
                  </Button>
                </Grid>
              </Grid>
              {/* Back Button */}
              <Button onClick={() => goToStep(4)} sx={{ mt: 2 }}>
                Back to Do You Have Kids?
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default QuestionPage;

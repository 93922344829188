import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../assets/images/logo2.png";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import FooterSellingPoint from "./Footer"; // Assuming FooterSellingPoint is a footer component

// Navbar styling
const NavbarContainer = styled("nav")(({ theme }) => ({
  width: "100vw", // Full viewport width
  height: "70px",
  backgroundColor: "#417446", // Fern Green
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 40px",
  position: "fixed",
  top: 0,
  zIndex: 10,
  [theme.breakpoints.down("sm")]: {
    padding: "0 20px",
    flexDirection: "column",
    height: "auto",
  },
}));

// Logo container
const Logo = styled("div")({
  fontSize: "24px",
  fontWeight: "bold",
  color: "white",
  display: "flex",
  alignItems: "center",
  img: {
    height: "70px",
    marginRight: "10px",
  },
});

// Navigation links
const NavLinks = styled("ul")(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  gap: "30px",
  padding: 0,
  margin: 0,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "10px",
  },
}));

const NavLinkItem = styled("li")({
  color: "#e6deb8", // Dutch White for link text
  fontSize: "18px",
  cursor: "pointer",

  "&:hover": {
    textDecoration: "underline",
    color: "#c9c088", // Sage for hover effect
  },

  "&.active": {
    color: "#598f57", // Middle Green for active link
    fontWeight: "bold",
  },
});

// Scroll-to-top button
function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fab
      onClick={handleClick}
      role="presentation"
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        backgroundColor: "#417446", // Fern Green for Scroll to top button
      }}
    >
      {children}
    </Fab>
  );
}

// Main component with Navbar, content, and footer
export default function AppBarComponent(props) {
  const { content } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); // For detecting the current route

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Ensure the container takes up the full viewport height
      }}
    >
      <CssBaseline />

      {/* Navbar */}
      <NavbarContainer>
        <Logo>
          <img src={logo} alt="Green Finder Logo" />
          <Typography
            variant="h5"
            sx={{ color: "#e6deb8", fontWeight: "bold" }}
          >
            GreenFinder
          </Typography>
        </Logo>

        {/* Hamburger Menu for small screens */}
        <IconButton
          sx={{ display: { xs: "block", sm: "none" }, color: "#e6deb8" }}
          onClick={handleMenuToggle}
        >
          <MenuIcon />
        </IconButton>

        {/* Navigation links */}
        <NavLinks
          sx={{ display: { xs: menuOpen ? "flex" : "none", sm: "flex" } }}
        >
          <NavLinkItem>
            <NavLink
              to="/LandingPage"
              style={{ color: "inherit", textDecoration: "none" }}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Home
            </NavLink>
          </NavLinkItem>
          <NavLinkItem>
            <NavLink
              to="/why-green-spaces"
              style={{ color: "inherit", textDecoration: "none" }}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Why Green?
            </NavLink>
          </NavLinkItem>
          <NavLinkItem>
            <NavLink
              to="/nearby-green-spaces"
              style={{ color: "inherit", textDecoration: "none" }}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Find Green
            </NavLink>
          </NavLinkItem>
          <NavLinkItem>
            <NavLink
              to="/more-green"
              style={{ color: "inherit", textDecoration: "none" }}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              More Green
            </NavLink>
          </NavLinkItem>

          <NavLinkItem>
            <NavLink
              to="/go-green"
              style={{ color: "inherit", textDecoration: "none" }}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Go Green
            </NavLink>
          </NavLinkItem>
        </NavLinks>
      </NavbarContainer>

      {/* Toolbar for spacing (fixed Navbar needs this for layout offset) */}
      <Toolbar id="back-to-top-anchor" />

      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1, // Allow the content to grow and take up space
          padding: 0,
          width: "100vw", // Full viewport width
        }}
      >
        {content}
      </Box>

      {/* Scroll to Top Button */}
      <ScrollTop {...props}>
        <KeyboardArrowUpIcon />
      </ScrollTop>

      {/* Conditionally render the footer if not on the Landing Page */}
      {location.pathname !== "/LandingPage" && <FooterSellingPoint />}
    </Box>
  );
}

import React from "react";
import styled, { keyframes } from "styled-components";
import ParkIcon from "@mui/icons-material/Update";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import WbSunnyIcon from "@mui/icons-material/WbSunnyOutlined";
import NatureIcon from "@mui/icons-material/ForestOutlined";

// Keyframes for animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const slideInUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

// Styled components for layout and sections
const FooterContainer = styled.section`
  width: 100vw;
  padding: 10px 10px 10px 10px;
  background-color: #f0ebd4;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${fadeIn} 1.5s ease;

  @media (max-width: 768px) {
    padding: 10px; /* Reduce padding on mobile */
  }
`;

const SectionTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;

const InlineSectionTitle = styled.h3`
  font-size: 28px;
  font-weight: bold;
  color: ${(props) => props.color || "#417446"};
  text-align: center;
  margin: 5px;
  animation: ${slideInUp} 1s ease;

  @media (max-width: 768px) {
    font-size: 20px; /* Reduce title size on mobile */
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Adjust column sizes */
  gap: 10px;
  width: 90%;
  max-width: 1200px;
  justify-items: center;
  align-items: start; /* Ensure alignment starts at the top */

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Single column on mobile */
  }
`;

const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background-color: #f0ebd4;
  padding: 20px;
  border-radius: 10px;
  height: 100%;
  max-height: 250px; /* Add a max-height */
  min-height: 250px; /* Add a min-height to equalize card sizes */

  @media (max-width: 768px) {
    padding: 15px;
    max-height: 220px; /* Adjust for mobile if necessary */
    min-height: 220px;
  }
`;

const FeatureIcon = styled.div`
  font-size: 40px;
  margin-bottom: 5px;
  color: #f2eee6;
  background-color: #598f57;
  border-radius: 50%;
  margin-bottom: 0px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;

  @media (max-width: 768px) {
    padding: 10px; /* Reduce padding for smaller icons on mobile */
    font-size: 30px; /* Reduce icon size on mobile */
  }
`;

const FeatureHeading = styled.h4`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #573e38;
  min-height: 25px;

  @media (max-width: 768px) {
    font-size: 14px; /* Smaller font size on mobile */
  }
`;

const FeatureText = styled.p`
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: #555;
  min-height: 60px;

  @media (max-width: 768px) {
    font-size: 12px; /* Smaller font size on mobile */
  }
`;

// Simple Footer component (the first design)
const SimpleFooter = styled.footer`
  padding: 30px;
  background-color: #417446;
  color: white;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px; /* Reduce footer padding on mobile */
  }
`;

// Footer Selling Point component
const FooterSellingPoint = () => {
  return (
    <>
      <FooterContainer>
        <SectionTitleContainer>
          <InlineSectionTitle color="#573E38" style={{ marginRight: "1px" }}>
            Why you need
          </InlineSectionTitle>
          <InlineSectionTitle color="#417446">GreenFinder</InlineSectionTitle>
        </SectionTitleContainer>

        <FeatureGrid>
          <FeatureCard>
            <FeatureIcon>
              <ParkIcon style={{ fontSize: 50 }} />
            </FeatureIcon>
            <FeatureHeading>Personalized Park Experiences</FeatureHeading>
            <FeatureText>
              Real-time recommendations for crowd levels and amenities.
            </FeatureText>
          </FeatureCard>

          <FeatureCard>
            <FeatureIcon>
              <DirectionsWalkIcon style={{ fontSize: 50 }} />
            </FeatureIcon>
            <FeatureHeading>Eco-Friendly Travel</FeatureHeading>
            <FeatureText>
              Walk, bike, or use public transport for sustainable park visits.
            </FeatureText>
          </FeatureCard>

          <FeatureCard>
            <FeatureIcon>
              <WbSunnyIcon style={{ fontSize: 50 }} />
            </FeatureIcon>
            <FeatureHeading>Smarter Outdoor Planning</FeatureHeading>
            <FeatureText>
              Plan visits with live data on foot traffic and weather.
            </FeatureText>
          </FeatureCard>

          <FeatureCard>
            <FeatureIcon>
              <NatureIcon style={{ fontSize: 50 }} />
            </FeatureIcon>
            <FeatureHeading>Supporting Sustainability Goals</FeatureHeading>
            <FeatureText>
              Contribute to reducing overcrowding and promoting green spaces.
            </FeatureText>
          </FeatureCard>
        </FeatureGrid>
      </FooterContainer>

      <SimpleFooter>© 2024 Green Finder. All rights reserved.</SimpleFooter>
    </>
  );
};

export default FooterSellingPoint;

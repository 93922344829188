import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ForestIcon from "@mui/icons-material/Forest"; // MUI Icon for environmental
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"; // MUI Icon for economic benefits
import PeopleIcon from "@mui/icons-material/People"; // MUI Icon for social benefits
import gardenImage from "../assets/images/garden.jpeg"; // Image for the hero section
import greengardenImage from "../assets/images/greenGardenNew1.jpeg";
import Vis1 from "../assets/images/Promoting (4).png";
import vis2 from "../assets/images/Green Cream Modern Financial Performance Dashboard Graph.png";
import image2 from "../assets/images/2 (7).png";
import image3 from "../assets/images/3 (8).png";
import image4 from "../assets/images/4 (2).png";
import image5 from "../assets/images/5 (4).png";
import image6 from "../assets/images/6 (4).png";
import image7 from "../assets/images/7 (8).png";
import image8 from "../assets/images/8 (8).png";
import image9 from "../assets/images/9.png";
import most from "../assets/images/most.png";
import least from "../assets/images/least.png";
import { useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { scaleQuantile } from "d3-scale";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideInUp = keyframes`
  from { transform: translateY(100px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const ContentWrapper = styled.div`
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
`;

const CanvaContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: -2F;
  padding: 0;
  overflow: hidden;
`;

const Section = styled.section`
  margin-bottom: 30px;
  text-align: center;
`;

const NewSection = styled.section`
  margin-top: 70px;
  margin-bottom: 20px;
  text-align: center;
`;

const NewContentWrapper = styled.section`
  width: 100%;
  padding: 40px 20px;
  background-color: #e3dfc8;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    align-items: center; // Center content for small screens
  }
`;

const ImageRow = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 50px;
  margin: 20px;

  // For small devices, stack images in a column
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 20px; // Reduce gap for better spacing
  }
`;

const ImageStyled = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: contain; // Ensures image fits inside the box without cropping
  border-radius: 10px;

  // Media query for medium devices (tablets)
  @media (max-width: 768px) {
    width: 100%; // Adjust the width to fit the container
    height: auto;
  }

  // Media query for small devices (phones)
  @media (max-width: 480px) {
    width: 100%; // Full width for small screens
    height: auto;
  }
`;

const StaticModuleItem = styled("div")`
  width: 260px; // Slightly larger to accommodate the image
  height: 260px;
  background-color: #e6deb8;
  padding: 10px; // Small padding to separate image from edges
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  // Media query for medium devices (tablets)
  @media (max-width: 768px) {
    width: 180px;
    height: 180px;
  }

  // Media query for small devices (phones)
  @media (max-width: 480px) {
    width: 150px;
    height: 150px;
  }
`;

const Divider = styled.hr`
  border: 0.1px solid #417446;
  width: 100%;
  margin: 40px 0;
`;

const MapModuleItem = styled.div`
  width: 100%;
  background-color: #e6deb8;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const BenefitList = styled.ul`
  display: flex;
  flex-wrap: wrap; // Wrap items if needed
  justify-content: space-around;
  gap: 50px;
  padding: 0;
  margin: 20px 0;
  list-style-type: none;
  width: 100%;

  // Stack items vertically on smaller screens
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CallToActionSection = styled.section`
  width: 100%;
  padding: 40px 0;
  background-color: #598f57;
  color: white;
  text-align: center;
`;

const CallToActionButton = styled.button`
  padding: 15px 40px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  background-color: #f0ebd4;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: #ede7cd;
  }
`;

const Outline = styled.h3`
  font-size: 18px;
  color: #417446;
  padding: 10px 20px;
  border: 1.5px solid #598f57; // Border around the title
  border-radius: 10px; // Rounded corners for a softer look
  display: inline-block;
  text-align: center;
  margin: 20px auto;
  width: 70%;
`;

const ProblemSubtitle = styled.h3`
  font-size: 30px;
  margin-bottom: 20px;
  color: #417446;
  text-align: center;
  animation: ${slideInUp} 1s ease;
`;

const HeroTitle = styled.h2`
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
`;

const HeroSubtitle = styled.p`
  font-size: 22px;
  margin-bottom: 5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

const InteractiveMapWrapper = styled.div`
  width: 100%;
  height: 400px;
  margin: 0;
  padding: 20px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const MapContainerStyled = styled(MapContainer)`
  height: 100%;
  width: 100%;
`;

const InstructionText = styled.p`
  font-size: 16px;
  color: #417446; // Matches the theme color
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
`;

const LegendWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const InteractiveMap = () => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [greenSpacesData, setGreenSpacesData] = useState(null);
  const [areaOfGreenData, setAreaOfGreenData] = useState(null);
  const [greenCapitaAreaData, setgreenCapitaAreaData] = useState(null);
  const [selectedLabelType, setSelectedLabelType] = useState(
    "numberOfGreenSpaces"
  );
  const mapRef = useRef(null);

  useEffect(() => {
    fetch("https://api.greenfinderinmelb.me/api/green-spaces/")
      .then((response) => response.json())
      .then((data) => {
        setGreenSpacesData(data);
        if (selectedLabelType === "numberOfGreenSpaces") {
          setGeoJsonData(data);
        }
      })
      .catch((error) =>
        console.error("Error loading green spaces data:", error)
      );
  }, [selectedLabelType]);

  useEffect(() => {
    fetch("https://api.greenfinderinmelb.me/api/agg-suburb-area/")
      .then((response) => response.json())
      .then((data) => {
        setAreaOfGreenData(data);
        if (selectedLabelType === "areaOfGreen") {
          setGeoJsonData(data);
        }
      })
      .catch((error) =>
        console.error("Error loading area of green data:", error)
      );
  }, [selectedLabelType]);

  useEffect(() => {
    fetch("https://api.greenfinderinmelb.me/api/agg-suburb-per-person-area/")
      .then((response) => response.json())
      .then((data) => {
        setgreenCapitaAreaData(data);
        if (selectedLabelType === "areaPerCapita") {
          setGeoJsonData(data);
        }
      })
      .catch((error) =>
        console.error("Error loading area of green data:", error)
      );
  }, [selectedLabelType]);

  const getSuburbStyle = () => ({
    weight: 1.8,
    opacity: 2,
    color: "green",
    fillOpacity: 0,
  });

  const onEachSuburb = (feature, layer) => {
    console.log("Feature properties:", feature.properties);

    let tooltipContent = `<b>Suburb:</b> ${feature.properties.suburb || "N/A"}`;

    if (selectedLabelType === "areaOfGreen" && areaOfGreenData) {
      const areaFeature = areaOfGreenData.features.find(
        (f) => f.properties.suburb === feature.properties.suburb
      );
      if (areaFeature && areaFeature.properties.area_hectares !== undefined) {
        tooltipContent += `<br><b>Green Area:</b> ${parseFloat(
          areaFeature.properties.area_hectares
        ).toFixed(2)} hectares`;
      }
    } else if (selectedLabelType === "numberOfGreenSpaces" && greenSpacesData) {
      const countFeature = greenSpacesData.features.find(
        (f) => f.properties.suburb === feature.properties.suburb
      );
      if (countFeature && countFeature.properties.total_count !== undefined) {
        tooltipContent += `<br><b>Green Spaces Count:</b> ${countFeature.properties.total_count}`;
      }
    } else if (selectedLabelType === "areaPerCapita" && greenCapitaAreaData) {
      const countFeature = greenCapitaAreaData.features.find(
        (f) => f.properties.suburb === feature.properties.suburb
      );
      if (
        countFeature &&
        countFeature.properties.per_capita_space_sqkm !== undefined
      ) {
        tooltipContent += `<br><b>Green Space per Person:</b> ${parseFloat(
          countFeature.properties.per_capita_space_sqkm
        ).toFixed(2)} sq. kms`;
      }
    }

    layer.bindPopup(tooltipContent);

    // Icon logic remains the same
    let icon = null;
    if (selectedLabelType === "areaOfGreen") {
      if (
        ["Port Melbourne", "Albert Park", "Melbourne"].includes(
          feature.properties.suburb
        )
      ) {
        icon = most;
      } else if (
        ["South Wharf", "Southbank", "Flemington"].includes(
          feature.properties.suburb
        )
      ) {
        icon = least;
      }
    } else if (selectedLabelType === "numberOfGreenSpaces") {
      if (
        ["Port Melbourne", "Kensington", "Melbourne"].includes(
          feature.properties.suburb
        )
      ) {
        icon = most;
      } else if (
        ["Flemington", "South Wharf", "Carlton North"].includes(
          feature.properties.suburb
        )
      ) {
        icon = least;
      }
    } else if (selectedLabelType === "areaPerCapita") {
      if (
        ["Albert Park", "East Melbourne", "West Melbourne"].includes(
          feature.properties.suburb
        )
      ) {
        icon = most;
      } else if (
        ["Southbank", "South Wharf", "Flemington"].includes(
          feature.properties.suburb
        )
      ) {
        icon = least;
      }
    }

    if (icon) {
      const tooltipIcon = `<div style="display: flex; align-items: center;">
        <img src="${icon}" alt="${
        icon === most ? "Most" : "Least"
      }" style="width: 25px; height: 25px;" />
      </div>`;
      layer.bindTooltip(tooltipIcon, {
        permanent: true,
        direction: "center",
        className: "custom-tooltip",
      });
    }
  };

  const MapInvalidateSize = () => {
    const map = useMap();
    useEffect(() => {
      map.invalidateSize();
    }, [map]);
    return null;
  };

  const handleLabelTypeChange = (labelType) => {
    setSelectedLabelType(labelType);
    if (labelType === "areaOfGreen") {
      setGeoJsonData(areaOfGreenData);
    } else if (labelType === "numberOfGreenSpaces") {
      setGeoJsonData(greenSpacesData);
    } else if (labelType === "areaPerCapita") {
      setGeoJsonData(greenCapitaAreaData);
    }
  };

  // Function to render the legend content dynamically
  const renderLegendContent = () => {
    let mostLabel = "";
    let leastLabel = "";

    switch (selectedLabelType) {
      case "areaOfGreen":
        mostLabel = "Most Green Area";
        leastLabel = "Least Green Area";
        break;
      case "numberOfGreenSpaces":
        mostLabel = "Most Green Spaces";
        leastLabel = "Least Green Spaces";
        break;
      case "areaPerCapita":
        mostLabel = "Most Green Area per Person";
        leastLabel = "Least Green Area per Person";
        break;
      default:
        mostLabel = "Most";
        leastLabel = "Least";
    }

    return (
      <>
        <div>
          <img
            src={most}
            alt="Most"
            style={{ width: "25px", height: "25px" }}
          />{" "}
          {mostLabel}
        </div>
        <div>
          <img
            src={least}
            alt="Least"
            style={{ width: "25px", height: "25px" }}
          />{" "}
          {leastLabel}
        </div>
      </>
    );
  };

  return (
    <div style={{ width: "100%", marginBottom: "20px" }}>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginBottom: "10px",
          justifyContent: "center",
        }}
      >
        <InstructionText>
          PICK A CATEGORY TO COMPARE GREEN SPACES ACROSS SUBURBS:
        </InstructionText>
        <Button
          onClick={() => handleLabelTypeChange("areaPerCapita")}
          variant="contained"
          style={{
            backgroundColor:
              selectedLabelType === "areaPerCapita" ? "#417446" : "grey",
            color: "#f5f4e2",
          }}
        >
          Green Area per Person
        </Button>
        <Button
          onClick={() => handleLabelTypeChange("numberOfGreenSpaces")}
          variant="contained"
          style={{
            backgroundColor:
              selectedLabelType === "numberOfGreenSpaces" ? "#417446" : "grey",
            color: "#f5f4e2",
          }}
        >
          Number of Green Spaces
        </Button>
        <Button
          onClick={() => handleLabelTypeChange("areaOfGreen")}
          variant="contained"
          style={{
            backgroundColor:
              selectedLabelType === "areaOfGreen" ? "#417446" : "grey",
            color: "#f5f4e2",
          }}
        >
          Total Green Area (in hectares)
        </Button>
      </div>
      <InteractiveMapWrapper>
        <p
          style={{
            textAlign: "left",
            color: "#417446",
            fontSize: "14px",
            fontFamily: "Arial, sans-serif",
            marginTop: "0",
            fontWeight: "bold",
          }}
        >
          Click a suburb or icon to view details.
        </p>
        {geoJsonData ? (
          <MapContainerStyled
            center={[-37.8136, 144.9631]}
            zoom={12.49999999}
            style={{ height: "100%", width: "100%" }}
            whenCreated={(map) => {
              mapRef.current = map;
            }}
            zoomControl={false}
            scrollWheelZoom={false}
            F
            doubleClickZoom={false}
            dragging={false}
            touchZoom={false}
          >
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            />
            <GeoJSON
              key={`${selectedLabelType}-${JSON.stringify(geoJsonData)}`}
              data={geoJsonData}
              style={getSuburbStyle}
              onEachFeature={onEachSuburb}
            />
            <LegendWrapper>{renderLegendContent()}</LegendWrapper>
          </MapContainerStyled>
        ) : (
          <p
            style={{
              color: "#417446",
              fontSize: "20px",
              fontFamily: "Arial, sans-serif",
              fontWeight: "bold",
            }}
          >
            Loading map ...
          </p>
        )}
      </InteractiveMapWrapper>
      <style jsx global>{`
        .custom-tooltip {
          background: none;
          border: none;
          box-shadow: none;
        }
        .leaflet-tooltip-top:before,
        .leaflet-tooltip-bottom:before,
        .leaflet-tooltip-left:before,
        .leaflet-tooltip-right:before {
          border: none !important;
        }
        .leaflet-container {
          z-index: 1;
        }
      `}</style>
    </div>
  );
};

// Styled components for the page layout
const PageContainer = styled("div")({
  fontFamily: "Arial, sans-serif",
  width: "100%",
  padding: "0px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: "hidden",
  margin: 0,
  backgroundColor: "#f5f4e2",
});

const HeroSection = styled("section")({
  width: "100vw",
  height: "45vh",
  background: `url(${greengardenImage}) center/cover no-repeat`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#417446",
  textAlign: "center",
  padding: 0,
  margin: 0,
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
  position: "relative",
  top: 0,
});

const TransparentOverlay = styled.div`
  background-color: rgba(255, 255, 255, 0.4);
  padding: 30px;
  border-radius: 0px;
  max-width: 60%;
  color: white;
  animation: ${fadeIn} 2s ease-in-out;
`;

const ProblemText = styled.p`
  max-width: 800px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.6;
`;

const ProblemSection = styled.section`
  width: 100vw;
  padding: 40px 20px;
  background-color: #e3dfc8;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  animation: ${fadeIn} 2s ease forwards;
  animation-delay: 0.5s; // Delayed to appear smoothly after scroll
`;

const SectionTitle = styled(Typography)({
  fontSize: "34px",
  color: "#417446", // Fern Green
  marginBottom: "20px",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "50px",
    height: "3px",
    backgroundColor: "#598f57", // Middle Green
    bottom: "-10px",
    left: "50%",
    transform: "translateX(-50%)",
  },
});

const SectionText = styled(Typography)({
  fontSize: "18px",
  color: "#333",
  lineHeight: "1.6",
  marginBottom: "30px",
  wordWrap: "break-word", // Wrap long words
  overflow: "hidden", // Prevent content overflow
  textOverflow: "ellipsis", // Show ellipsis for overflowing text
});

const SubSectionTitle = styled.h2`
  font-size: 26px !important; // Increase font size here as needed
  color: #417446 !important;
  margin-top: 60px !important;
  text-align: center !important;
  width: 100%;
  font-weight: bold !important;
  line-height: 1.4 !important;
`;

const ModuleItem = styled("div")`
  width: 220px;
  height: 220px;
  background-color: #e6deb8;
  padding: 10px; // Small padding to separate image from edges
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  // Media query for medium devices (tablets)
  @media (max-width: 768px) {
    width: 180px;
    height: 180px;
  }

  // Media query for small devices (phones)
  @media (max-width: 480px) {
    width: 150px;
    height: 150px;
  }
`;

const ImageContainer = styled.div`
  width: 250px;
  height: 250px;
  margin: 5px;
  padding: 5px;
  background-color: #e6deb8;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
`;

const InteractiveImage = styled.img`
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
`;

const InlineSectionTitle = styled.h3`
  font-size: 30px;
  margin-bottom: 20px;
  color: ${(props) => props.color || "#417446"};
  text-align: center;
  margin: 0 5px; /* Add some spacing between the two texts */
  display: inline;
`;

const BenefitItem = styled("li")`
  background-color: #e6deb8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 220px; // Set a consistent width

  // Adjust size on smaller screens
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const BenefitIcon = styled("div")({
  fontSize: "50px",
  marginBottom: "10px",
  color: "#417446", // Fern Green for icons
});

const CanvaEmbedWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding-top: 75%; // This creates a 4:3 aspect ratio. Adjust if needed.
  position: relative;
  margin: 40px auto;
  overflow: hidden;
`;

const CanvaEmbedIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

function WhyGreenSpaces() {
  const problemSectionRef = useRef(null);
  const navigate = useNavigate();

  const handleMoreGreenClick = () => {
    navigate("/nearby-green-spaces");
  };

  return (
    <PageContainer>
      <HeroSection>
        <TransparentOverlay>
          <HeroTitle variant="h4">Why Green Spaces Matter?</HeroTitle>
          <HeroSubtitle>
            Green spaces improve well-being, fight climate change, and bring
            beauty to our cities.
          </HeroSubtitle>
        </TransparentOverlay>
      </HeroSection>

      <ProblemSection ref={problemSectionRef}>
        <ProblemSubtitle>
          Ever Wondered Why It's Still Hard to Find a Peaceful Green Space?
        </ProblemSubtitle>
        <ProblemText>
          Finding a peaceful green space in Melbourne City can be challenging.{" "}
          <br /> Overcrowding, urban development, and busy schedules often make
          it hard for families to enjoy nature.
        </ProblemText>
      </ProblemSection>

      <ContentWrapper>
        <Section>
          <ProblemSubtitle>Melbourne's Green Space Gap</ProblemSubtitle>
          <SectionText>
            See which suburbs have the most green areas and which are lacking.{" "}
            <br /> Find out where nature thrives and where more green is needed.
          </SectionText>
        </Section>

        <Section>
          <MapModuleItem>
            <InteractiveMap />
          </MapModuleItem>

          <Section>
            <SubSectionTitle>Suburb Green Space Comparisons</SubSectionTitle>
            <SectionText>
              Compare which neighborhoods excel in green spaces and which fall
              behind.
            </SectionText>

            <NewContentWrapper>
              {/* First row */}
              <ImageRow>
                <ModuleItem>
                  <ImageStyled
                    src={image2}
                    alt="Top 3 suburbs with the most green space"
                  />
                </ModuleItem>
                <ModuleItem>
                  <ImageStyled
                    src={image3}
                    alt="Top 3 suburbs with the least green space"
                  />
                </ModuleItem>
              </ImageRow>

              {/* Second row */}
              <ImageRow>
                <img
                  src={image4}
                  alt="Family outing"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "10px",
                  }}
                />
                <ModuleItem>
                  <ImageStyled
                    src={image5}
                    alt="3 suburbs with the least green area per capita"
                  />
                </ModuleItem>
                <ModuleItem>
                  <ImageStyled
                    src={image6}
                    alt="3 suburbs with the most number of green areas"
                  />
                </ModuleItem>
              </ImageRow>

              {/* Third row */}
              <ImageRow>
                <ModuleItem>
                  <ImageStyled
                    src={image7}
                    alt="3 suburbs with the least number of green areas"
                  />
                </ModuleItem>
                <ModuleItem>
                  <ImageStyled
                    src={image8}
                    alt="3 suburbs with the least number of green areas"
                  />
                </ModuleItem>
                <img
                  src={image9}
                  alt="Park Illustration"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "10px",
                  }}
                />
              </ImageRow>
            </NewContentWrapper>
          </Section>
        </Section>

        <ProblemSubtitle>
          <InlineSectionTitle color="#573E38">
            How Green Spaces
          </InlineSectionTitle>
          <InlineSectionTitle color="#417446">
            Benefit You and the City
          </InlineSectionTitle>
        </ProblemSubtitle>
        <NewContentWrapper>
          <BenefitList>
            <BenefitItem>
              <BenefitIcon>
                <ForestIcon fontSize="inherit" />
              </BenefitIcon>
              <Typography>Environmental Benefits</Typography>
              <Typography variant="body2">
                Reduce CO2, promote biodiversity, and cool urban areas.
              </Typography>
            </BenefitItem>
            <BenefitItem>
              <BenefitIcon>
                <AttachMoneyIcon fontSize="inherit" />
              </BenefitIcon>
              <Typography>Economic Benefits</Typography>
              <Typography variant="body2">
                Increase property values and reduce healthcare costs.
              </Typography>
            </BenefitItem>
            <BenefitItem>
              <BenefitIcon>
                <PeopleIcon fontSize="inherit" />
              </BenefitIcon>
              <Typography>Social Benefits</Typography>
              <Typography variant="body2">
                Foster community engagement and social interactions.
              </Typography>
            </BenefitItem>
          </BenefitList>
        </NewContentWrapper>
      </ContentWrapper>

      <Section>
        <ProblemSubtitle>
          <InlineSectionTitle color="#573E38">Green Spaces</InlineSectionTitle>
          <InlineSectionTitle color="#417446">
            Make Life Better
          </InlineSectionTitle>
        </ProblemSubtitle>
        <SectionText>
          Explore how Melbourne's green spaces are enriching urban life. They
          help reduce stress, improve well-being, and create opportunities for
          community interaction.
          <br></br>
          Enjoy a cleaner, healthier lifestyle with the benefits these natural
          areas bring to the heart of the city.
        </SectionText>
      </Section>
      {/* Canva Embed Section */}
      <CanvaContentWrapper>
        <CanvaEmbedWrapper>
          <CanvaEmbedIframe
            src="https://www.canva.com/design/DAGRGoWCQnM/OGU5E_E1K4ypl5KsqzxS_A/view?embed"
            allowFullScreen
            allow="fullscreen"
          />
        </CanvaEmbedWrapper>
      </CanvaContentWrapper>
      <CallToActionSection>
        <h3>Explore Green Spaces Near You</h3>
        <p>
          Find nearby parks and gardens where you can unwind, play, and enjoy
          the beauty of nature.
        </p>
        <CallToActionButton onClick={handleMoreGreenClick}>
          Discover Green Spaces
        </CallToActionButton>
      </CallToActionSection>
    </PageContainer>
  );
}

export default WhyGreenSpaces;

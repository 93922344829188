import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";

const UVIndexInfo = ({ placeId, category }) => {
  const [uvData, setUvData] = useState({});
  const [vitaminDData, setVitaminDData] = useState([]); // State to store Vitamin D times
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSkinType, setSelectedSkinType] = useState(""); // State for selected skin type

  // Fetch UV data when the component mounts
  useEffect(() => {
    axios
      .get(
        `https://api.greenfinderinmelb.me/api/fetch-uv-data?place_id=${placeId}&category=${category}`
      )
      .then((response) => {
        setUvData(response.data); // Store the fetched UV data
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching UV data:", error);
        setError("Error fetching UV data");
        setLoading(false);
      });

    // Fetch Vitamin D recommendation times
    axios
      .get(
        `https://api.greenfinderinmelb.me/api/suggest-vitamin-d-times?place_id=${placeId}&category=${category}`
      )
      .then((response) => {
        if (Array.isArray(response.data)) {
          setVitaminDData(response.data); // Ensure the data is stored if it's an array
        } else {
          setVitaminDData([]); // Default to an empty array if data isn't an array
        }
      })
      .catch((error) => {
        console.error("Error fetching Vitamin D data:", error);
      });
  }, [placeId, category]);

  // Get the current hour
  const getCurrentHour = () => {
    const now = new Date();
    return now.getHours(); // Local time (Melbourne time with the right timezone offset)
  };

  // Extract hour from timestamp
  const extractHourFromTimestamp = (timestamp) => {
    const date = new Date(timestamp); // The timestamp already contains the timezone offset
    return date.getHours(); // Extract the hour directly
  };

  // Get UV data for the current hour
  const getUvDataForCurrentHour = (data) => {
    const currentHour = getCurrentHour(); // Get the current hour
    for (const [timestamp, uvDetails] of Object.entries(data)) {
      const hour = extractHourFromTimestamp(timestamp); // Extract the hour from each timestamp
      if (hour === currentHour) {
        return uvDetails;
      }
    }
    return null; // No matching UV data for the current hour
  };

  const uvDataForCurrentHour = getUvDataForCurrentHour(uvData);

  if (loading) {
    return <Typography>Loading UV data...</Typography>;
  }

  if (error || !uvDataForCurrentHour) {
    return (
      <Typography>{error || "No UV data available for this hour."}</Typography>
    );
  }

  // UV Level Color Mapping
  const getUVLevelColor = (uvLevel) => {
    switch (uvLevel.toLowerCase()) {
      case "low":
        return "#81C784"; // Green
      case "moderate":
        return "#FFEB3B"; // Yellow
      case "high":
        return "#FF9800"; // Orange
      case "very high":
        return "#F44336"; // Red
      case "extreme":
        return "#9C27B0"; // Purple
      default:
        return "#979186"; // Gray for unknown
    }
  };

  // Skin tone color mapping
  const skinToneColors = {
    "Scandinavian, Celtic": "#FAD9B1",
    "Northern European (Caucasian)": "#F1C27D",
    "Darker Caucasian (Central Europe)": "#E0AC69",
    "Mediterranean, Asian, Hispanic": "#C68642",
    "Middle eastern, Latin, light-skinned African-American, Indian": "#8D5524",
    "Dark-skinned African American": "#4B382A",
  };

  // Extract skin types from the data
  const skinTypes = uvDataForCurrentHour
    ? uvDataForCurrentHour.exposure_by_skintype.map(
        (skinTypeInfo) => Object.keys(skinTypeInfo)[0]
      )
    : [];

  // Get exposure information for the selected skin type
  const selectedSkinTypeInfo = uvDataForCurrentHour
    ? uvDataForCurrentHour.exposure_by_skintype.find(
        (info) => Object.keys(info)[0] === selectedSkinType
      )
    : null;

  // Ensure vitaminDData is an array before using find
  const selectedVitaminDInfo = Array.isArray(vitaminDData)
    ? vitaminDData.find((info) => info.skin_type_name === selectedSkinType)
    : null;

  return (
    <Box mt={3}>
      {/* UV Index and Level */}
      <Box mb={2}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#388E3C" }}>
          UV Index: {uvDataForCurrentHour.uv_index}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: getUVLevelColor(uvDataForCurrentHour.uv_level),
            fontWeight: "bold",
          }}
        >
          UV Level: {uvDataForCurrentHour.uv_level}
        </Typography>
        <Typography sx={{ fontStyle: "italic" }}>
          {uvDataForCurrentHour.safety_recommendation}
        </Typography>
      </Box>

      <Divider sx={{ my: 2, borderColor: "#81C784" }} />

      {/* Dropdown to select skin type */}
      <FormControl fullWidth sx={{ mt: 3 }}>
        <InputLabel id="skin-type-select-label">Select Skin Type</InputLabel>
        <Select
          labelId="skin-type-select-label"
          id="skin-type-select"
          value={selectedSkinType}
          onChange={(e) => setSelectedSkinType(e.target.value)}
          label="Select Skin Type"
        >
          {skinTypes.map((skinType, index) => (
            <MenuItem key={index} value={skinType}>
              {skinType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Show selected skin type information */}
      {selectedSkinTypeInfo && (
        <Box
          sx={{
            mt: 3,
            p: 3, // Increased padding for better spacing
            borderRadius: "12px", // Softer rounded corners
            background: "linear-gradient(135deg, #61995f 0%, #76a56f 100%)", // Gradient background
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly more pronounced shadow
            display: "flex",
            alignItems: "center", // Align content vertically
            justifyContent: "space-between", // Space out content horizontally
            color: "white", // White text for contrast
          }}
        >
          <Box>
            <Typography
              variant="h7"
              sx={{ fontWeight: "bold", mb: 1, fontSize: "1.0rem" }} // Use fontSize property
            >
              Exposure Information for {selectedSkinType}
            </Typography>

            <Typography variant="body2">
              <strong>Safe Exposure:</strong>{" "}
              {
                selectedSkinTypeInfo[selectedSkinType][
                  "Safe exposure time (minutes)"
                ]
              }{" "}
              minutes
            </Typography>
            <Typography variant="body2">
              <strong>Vitamin D Exposure:</strong>{" "}
              {
                selectedSkinTypeInfo[selectedSkinType][
                  "Recommended sun exposure for vitamin D (minutes)"
                ]
              }{" "}
              minutes
            </Typography>

            {/* Show Vitamin D recommendation if available */}
            {selectedVitaminDInfo && (
              <>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  <strong>Best Time for Vitamin D: </strong>
                  {new Date(selectedVitaminDInfo.time).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Typography>
                <Typography variant="body1">
                  <strong>Recommended Vitamin D Time: </strong>
                  {selectedVitaminDInfo.vitamin_d_recommendation} minutes
                </Typography>
              </>
            )}
          </Box>

          {/* Skin Tone Square */}
          <Box
            sx={{
              width: 60, // Larger square for better prominence
              height: 60,
              backgroundColor: skinToneColors[selectedSkinType] || "#E0E0E0",
              borderRadius: "8px", // More rounded corners
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", // Deeper shadow for better prominence
              ml: 3, // Add more margin to the left
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default UVIndexInfo;

import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Divider,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import ForestIcon from "@mui/icons-material/Forest";
import EcoIcon from "@mui/icons-material/NaturePeople";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CustomStepper from "../CustomStepper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import footprintIcon from "../../assets/images/footprint.png";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

const TripSummaryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const park = location.state?.park || { name: "Flagstaff Garden" };
  const carbonSavingsFromNavigation = location.state?.carbonSavings || 0;
  const [timeSpent, setTimeSpent] = useState(30); // Default to 30 minutes
  console.log("Passing savings to TripSummary:", carbonSavingsFromNavigation);

  // Calculate carbon savings based on the time spent in the park
  function calculateCarbonSavingsFromPark(timeInMinutes) {
    const hoursSpent = timeInMinutes / 60;
    return (hoursSpent * 0.1).toFixed(2); // Hypothetical 0.1 kg CO<sub>2</sub> savings per hour
  }

  const carbonSavingsFromPark = calculateCarbonSavingsFromPark(timeSpent);
  let combinedSavings =
    parseFloat(carbonSavingsFromNavigation) + parseFloat(carbonSavingsFromPark);
  let savingsMessage = "";

  if (combinedSavings < 0) {
    combinedSavings = 0;
    savingsMessage =
      "Your driving emissions exceeded the CO₂ savings from your trip.";
  }

  combinedSavings = combinedSavings.toFixed(2);

  // Long-term impact over a year
  const yearlySavings = (combinedSavings * 365).toFixed(2);
  const equivalentTreesPlanted = Math.round(yearlySavings / 21);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#E8F5E9",
        padding: { xs: "10px", sm: "20px", md: "40px" }, // Responsive padding
        pt: { xs: "60px", sm: "80px" }, // Add top padding to account for navbar height
      }}
    >
      <Button
        variant="outlined"
        sx={{
          mt: 3,
          borderColor: "#1B5E20",
          color: "#1B5E20",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#E8F5E9",
            borderColor: "#1B5E20",
          },
        }}
        onClick={() => navigate(-1)} // This will navigate back to the previous page
      >
        Back to Explore Park
      </Button>

      <Box sx={{ mt: { xs: -5, md: 0 }, mb: 3, width: "100%" }}>
        <CustomStepper activeStep={3} />
      </Box>

      <Card
        sx={{
          width: { xs: "100%", sm: "80%", md: "80%", lg: "80%" }, // Responsive width
          padding: { xs: 2, sm: 3, md: 4 }, // Responsive padding
          backgroundColor: "#FBFAF8",
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <CardContent>
          <Box sx={{ textAlign: "center", mb: { xs: 2, md: 4 } }}>
            <ThumbUpAltIcon
              sx={{ fontSize: { xs: 60, sm: 80 }, color: "#189e13" }}
            />
            {/* <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: "#1B5E20",
                mb: 1,
                fontSize: { xs: "24px", md: "32px" }, // Responsive font size
              }}
            >
              Amazing Work!
            </Typography> */}
            <Typography variant="h6" sx={{ color: "#2E7D32" }}>
              Your trip to {park.name} and time spent there are helping the city
              of Melbourne.
            </Typography>
          </Box>

          <Divider sx={{ my: { xs: 2, md: 3 } }} />

          {/* Improved Slider Section */}
          <Box
            sx={{
              textAlign: "center",
              mb: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <AccessTimeIcon
              sx={{ fontSize: { xs: 40, sm: 60 }, color: "#598F57" }}
            />
            <Typography
              variant="h5"
              sx={{
                color: "#388E3C",
                fontWeight: "bold",
                mt: 1,
                fontSize: { xs: "18px", md: "24px" },
              }}
            >
              How long did you spend at {park.name}?
            </Typography>
            {/* Custom Styled Slider */}
            <Slider
              value={timeSpent}
              onChange={(e, newValue) => setTimeSpent(newValue)}
              aria-labelledby="time-spent-slider"
              valueLabelDisplay="auto"
              step={15}
              marks={[
                { value: 15, label: "15 mins" },
                { value: 30, label: "30 mins" },
                { value: 60, label: "1 hour" },
                { value: 120, label: "2 hours" },
              ]}
              min={15}
              max={120}
              sx={{
                mt: 3,
                width: {
                  xs: "100%", // Full width on extra-small screens (mobile)
                  sm: "100%", // Slightly reduced width on small screens
                  md: "90%", // Medium width on medium screens
                  lg: "90%", // Narrower on large screens
                },
                color: "#417446",
                "& .MuiSlider-thumb": {
                  height: { xs: 20, sm: 24 }, // Adjust thumb size for smaller screens
                  width: { xs: 20, sm: 24 },
                  backgroundColor: "#417446",
                },
                "& .MuiSlider-track": {
                  height: { xs: 6, sm: 8 }, // Adjust track height for responsiveness
                  borderRadius: 4,
                },
                "& .MuiSlider-rail": {
                  height: { xs: 6, sm: 8 }, // Adjust rail height for responsiveness
                  borderRadius: 4,
                  backgroundColor: "#E0E0E0",
                },
                "& .MuiSlider-markLabel": {
                  fontSize: { xs: "12px", sm: "14px", md: "16px" },
                },
              }}
            />
            <Typography
              variant="body1"
              sx={{
                color: "#417446",
                mt: 3,
                fontSize: { xs: "14px", sm: "16px", md: "18px" }, // Responsive text size
                textAlign: "center", // Center align text for better presentation on smaller screens
              }}
            >
              Spending {timeSpent} minutes in nature helps recharge the city of
              Melbourne.
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />

          {/* Breakdown of CO<sub>2</sub> Savings */}
          <Box sx={{ textAlign: "center", mb: 4 }}>
            <Typography
              variant="h5"
              sx={{
                color: "#2E7D32",
                fontWeight: "bold",
                mt: 1,
                fontSize: { xs: "18px", sm: "20px", md: "24px" }, // Responsive font size
              }}
            >
              Your CO<sub>2</sub> Savings Breakdown
            </Typography>

            <Table
              sx={{
                width: { xs: "100%", sm: "90%", md: "80%" }, // Responsive table width
                margin: "0 auto",
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          width: { xs: 25, sm: 30 }, // Responsive icon size
                          height: { xs: 25, sm: 30 },
                          borderRadius: "50%",
                          backgroundColor: "#598F57",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          marginRight: "10px",
                        }}
                      >
                        <DirectionsWalkIcon
                          sx={{ color: "white", fontSize: { xs: 18, sm: 20 } }} // Responsive icon size
                        />
                      </Box>
                      CO<sub>2</sub> saved from transport
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }} // Responsive font size
                    >
                      {carbonSavingsFromNavigation} kg
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          width: { xs: 25, sm: 30 }, // Responsive icon size
                          height: { xs: 25, sm: 30 },
                          borderRadius: "50%",
                          backgroundColor: "#598F57",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          marginRight: "10px",
                        }}
                      >
                        <NaturePeopleIcon
                          sx={{ color: "white", fontSize: { xs: 18, sm: 20 } }}
                        />
                      </Box>
                      CO<sub>2</sub> saved from time in {park.name}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }} // Responsive font size
                    >
                      {carbonSavingsFromPark} kg
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          width: { xs: 25, sm: 30 },
                          height: { xs: 25, sm: 30 },
                          borderRadius: "50%",
                          backgroundColor: "#598F57",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          marginRight: "10px",
                        }}
                      >
                        <EcoIcon
                          sx={{ color: "white", fontSize: { xs: 18, sm: 20 } }}
                        />
                      </Box>
                      <strong>
                        Total CO<sub>2</sub> saved
                      </strong>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }} // Responsive font size
                    >
                      <strong>{combinedSavings} kg</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {savingsMessage && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {savingsMessage}
              </Typography>
            )}
          </Box>

          <Divider sx={{ my: 3 }} />

          {/* Long-term Impact */}
          <Box
            sx={{
              textAlign: "center",
              mb: 3,
              backgroundColor: "#F2EEE6",
              padding: { xs: "10px", sm: "15px", md: "20px" }, // Responsive padding
              borderRadius: "12px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "#2E7D32",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  fontSize: { xs: "18px", sm: "20px", md: "24px" }, // Responsive text
                }}
              >
                Your Green Footprint
                <img
                  src={footprintIcon}
                  alt="Green Footprint"
                  style={{ width: "50px", marginLeft: "10px" }} // Responsive image size
                />
                <Tooltip
                  title="This is a yearly calculation based on estimated CO2 savings."
                  arrow
                >
                  <InfoIcon
                    sx={{
                      fontSize: "24px",
                      marginLeft: "8px",
                      color: "#2E7D32",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
                justifyContent: "center",
                alignItems: "center",
                mb: 2,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: { xs: 2, sm: 0 }, // Stack and add margin for smaller screens
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={120} // Responsive size for smaller screens
                  thickness={5}
                  sx={{ position: "absolute", color: "#E0E0E0" }}
                />
                <CircularProgress
                  variant="determinate"
                  value={Math.min((yearlySavings / 1000) * 100, 100)}
                  size={120}
                  thickness={5}
                  sx={{ color: "#417446" }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#2E7D32",
                      fontWeight: "bold",
                      fontSize: { xs: "16px", sm: "18px" },
                    }} // Responsive text
                  >
                    {yearlySavings} kg
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ ml: { sm: 4 }, textAlign: "center" }}>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#2E7D32",
                    fontWeight: "bold",
                    fontSize: { xs: "18px", sm: "24px" }, // Responsive text size
                  }}
                >
                  {yearlySavings} kg
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#388E3C",
                    fontStyle: "italic",
                    fontSize: { xs: "14px", sm: "16px" },
                  }} // Responsive text size
                >
                  CO<sub>2</sub> saved in a year
                </Typography>
              </Box>
            </Box>

            {equivalentTreesPlanted > 0 ? (
              <Typography variant="body1" sx={{ color: "#417446", mt: 1 }}>
                That’s like planting{" "}
                <strong>{equivalentTreesPlanted} trees</strong> 🌳
              </Typography>
            ) : (
              <Typography variant="body1" sx={{ color: "#f57c00", mt: 1 }}>
                You can make an even bigger impact next time by choosing more
                sustainable transport options!{" "}
                <Typography component="span" sx={{ fontSize: "1.5rem" }}>
                  🚶‍♂️🚲
                </Typography>
              </Typography>
            )}
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box sx={{ mt: 5 }}>
            <Typography
              variant="h5"
              sx={{
                color: "#2E7D32",
                fontWeight: "bold",
                textAlign: "center",
                mb: 3,
                fontSize: { xs: "18px", sm: "20px", md: "24px" }, // Responsive text size
              }}
            >
              Want to amplify your impact? Try these next steps:
            </Typography>

            {/* Actionable Cards */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
                justifyContent: "space-between",
                gap: 2,
                mt: 2,
              }}
            >
              {/* Card 1: Use Public Transport */}
              <Card
                sx={{
                  width: { xs: "100%", sm: "280px" }, // Responsive width
                  textAlign: "center",
                  backgroundColor: "#EFEAE0",
                  padding: 2,
                  boxShadow: 3,
                  borderRadius: 2,
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#c0debf",
                    width: 60,
                    height: 60,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                    mb: 2,
                  }}
                >
                  <DirectionsWalkIcon sx={{ fontSize: 30, color: "#1B5E20" }} />
                </Box>
                <Typography variant="h7" sx={{ color: "#1B5E20", mb: 1 }}>
                  Use Public Transport
                </Typography>
                <Typography variant="body2" sx={{ color: "#598F57" }}>
                  Reduce your carbon footprint by opting for buses or trains
                  instead of driving.
                </Typography>
              </Card>

              {/* Card 2: Start a Home Garden */}
              <Card
                sx={{
                  width: { xs: "100%", sm: "280px" }, // Responsive width
                  textAlign: "center",
                  backgroundColor: "#EFEAE0",
                  padding: 2,
                  boxShadow: 3,
                  borderRadius: 2,
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#c0debf",
                    width: 60,
                    height: 60,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                    mb: 2,
                  }}
                >
                  <ForestIcon sx={{ fontSize: 30, color: "#1B5E20" }} />
                </Box>
                <Typography variant="h7" sx={{ color: "#1B5E20", mb: 1 }}>
                  Start a Home Garden
                </Typography>
                <Typography variant="body2" sx={{ color: "#598F57" }}>
                  Grow your own vegetables and plants to contribute to a cleaner
                  environment.
                </Typography>
              </Card>

              {/* Card 3: Join a Clean-Up Event */}
              <Card
                sx={{
                  width: { xs: "100%", sm: "280px" }, // Responsive width
                  textAlign: "center",
                  backgroundColor: "#EFEAE0",
                  padding: 2,
                  boxShadow: 3,
                  borderRadius: 2,
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#c0debf",
                    width: 60,
                    height: 60,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                    mb: 2,
                  }}
                >
                  <EcoIcon sx={{ fontSize: 30, color: "#1B5E20" }} />
                </Box>
                <Typography variant="h7" sx={{ color: "#1B5E20", mb: 1 }}>
                  Join a Clean-Up Event
                </Typography>
                <Typography variant="body2" sx={{ color: "#598F57" }}>
                  Participate in local events to clean up parks and beaches to
                  keep the environment green.
                </Typography>
              </Card>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: { xs: "center", sm: "space-between" }, // Center on small screens, space between on larger
          flexDirection: { xs: "column", sm: "row" }, // Stack buttons on small screens
        }}
      >
        {/* Back to Homepage Button */}
        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 3,
            backgroundColor: "#1B5E20",
            color: "white",
            padding: { xs: "10px 20px", sm: "12px 24px" }, // Responsive padding
            fontSize: { xs: "14px", sm: "16px" }, // Responsive font size
            fontWeight: "bold",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow
            transition: "transform 0.2s ease-in-out", // Smooth scaling on hover
            "&:hover": {
              backgroundColor: "#388E3C",
              transform: "scale(1.05)", // Slightly enlarge on hover
            },
          }}
          onClick={() => navigate("/more-green")}
        >
          Explore plants in Green Spaces
        </Button>

        {/* Learn More Green Actions Button */}
        <Button
          variant="outlined"
          fullWidth
          sx={{
            mt: 3,
            borderColor: "#1B5E20",
            backgroundColor: "white",
            color: "#1B5E20",
            padding: { xs: "10px 20px", sm: "12px 24px" }, // Responsive padding
            fontSize: { xs: "14px", sm: "16px" }, // Responsive font size
            fontWeight: "bold",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow
            transition:
              "transform 0.3s ease-in-out, background-color 0.3s ease",
            animation: "pulse 1.5s infinite", // Pulsing animation
            "&:hover": {
              backgroundColor: "#E8F5E9",
              color: "#388E3C",
              transform: "scale(1.1)", // Larger scale on hover
              borderColor: "#388E3C",
            },
          }}
          onClick={() =>
            navigate("/go-green", { state: { fromSummaryPage: true } })
          }
        >
          Learn More Green Actions
        </Button>

        {/* Keyframes for pulse animation */}
        <style>
          {`
      @keyframes pulse {
        0% { transform: scale(1); }
        50% { transform: scale(1.05); }
        100% { transform: scale(1); }
      }
    `}
        </style>
      </Box>
    </Box>
  );
};

export default TripSummaryPage;

import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import OpacityIcon from "@mui/icons-material/Opacity";
import AirIcon from "@mui/icons-material/Air";
import CloudIcon from "@mui/icons-material/CloudOutlined";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import GrainIcon from "@mui/icons-material/Grain";

import sunnyBackground from "../../assets/images/sunny_background.jpg";
import cloudyBackground from "../../assets/images/cloudy_background.jpg";
import rainyBackground from "../../assets/images/rainy_background.jpg";
import snowyBackground from "../../assets/images/snowy_background.jpg";
import defaultBackground from "../../assets/images/default_background.jpg";

// Helper function to select background image
const getBackgroundImage = (weatherDescription) => {
  if (!weatherDescription) return defaultBackground;
  const description = weatherDescription.toLowerCase();
  if (description.includes("sunny") || description.includes("clear")) {
    return sunnyBackground;
  } else if (description.includes("cloudy") || description.includes("fair")) {
    return cloudyBackground;
  } else if (description.includes("rain") || description.includes("showers")) {
    return rainyBackground;
  } else if (description.includes("snow")) {
    return snowyBackground;
  } else {
    return defaultBackground;
  }
};

const WeatherInfo = ({ placeId, category }) => {
  const [weatherData, setWeatherData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWeatherData = async () => {
      const currentDate = new Date().toLocaleDateString("en-CA");
      const apiUrl = `https://api.greenfinderinmelb.me/api/fetch-weather-data?place_id=${placeId}&category=${category}&date=${currentDate}`;

      try {
        const response = await axios.get(apiUrl);
        if (response.data && Object.keys(response.data).length > 0) {
          setWeatherData(response.data);
        } else {
          setError("No weather data available for this time.");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching weather data:", error);
        setError("Error fetching weather data");
        setLoading(false);
      }
    };

    fetchWeatherData();
  }, [placeId, category]);

  const getCurrentHour = () => new Date().getHours();
  const extractHourFromTimestamp = (timestamp) =>
    new Date(timestamp).getHours();
  const getWeatherDataForCurrentHour = (data) => {
    const currentHour = getCurrentHour();
    for (const [timestamp, weatherDetails] of Object.entries(data)) {
      const hour = extractHourFromTimestamp(timestamp);
      if (hour === currentHour) return weatherDetails;
    }
    return null;
  };

  const getWeatherIcon = (weatherDescription) => {
    const description = weatherDescription?.toLowerCase();
    if (description.includes("sunny") || description.includes("clear")) {
      return <WbSunnyIcon sx={{ fontSize: "2rem", color: "white" }} />;
    } else if (description.includes("cloudy")) {
      return <CloudIcon sx={{ fontSize: "2rem", color: "white" }} />;
    } else if (
      description.includes("rain") ||
      description.includes("showers")
    ) {
      return <GrainIcon sx={{ fontSize: "2rem", color: "white" }} />;
    } else if (description.includes("snow")) {
      return <AcUnitIcon sx={{ fontSize: "2rem", color: "white" }} />;
    } else {
      return <CloudIcon sx={{ fontSize: "2rem", color: "white" }} />;
    }
  };

  const weatherDataForCurrentHour = getWeatherDataForCurrentHour(weatherData);

  if (loading) {
    return <Typography>Loading weather data...</Typography>;
  }

  if (error) {
    return <Typography>{error}</Typography>;
  }

  return (
    weatherDataForCurrentHour && (
      <Box
        sx={{
          width: "100%",
          height: "100%", // Ensure full height of the container
          display: "flex",
          flexDirection: "column", // Stack weather overview and details vertically
          justifyContent: "space-between", // Spread items evenly
        }}
      >
        {/* Weather Overview at the top */}
        <Box
          sx={{
            padding: 3,
            borderRadius: 3,
            backgroundImage: `url(${getBackgroundImage(
              weatherDataForCurrentHour?.weather_description
            )})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)", // Shadow for depth
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            flex: "1", // Take up half of the total height
            mb: 2,
            // transition: "transform 0.3s ease", // Subtle hover effect
            // "&:hover": {
            //   transform: "scale(1.05)", // Slight scale-up on hover
            // },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "white", // Keep the text color white for contrast
              fontWeight: "bold", // Make the text bold
            }}
          >
            Weather: {weatherDataForCurrentHour?.weather_description}
          </Typography>

          {getWeatherIcon(weatherDataForCurrentHour?.weather_description)}
        </Box>

        {/* Transparent weather detail cards below */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)", // Three equal columns
            gap: 2, // Space between cards
            flex: "1", // Take up half of the total height
            "@media (max-width: 900px)": {
              gridTemplateColumns: "1fr", // Stack cards in one column on smaller screens
            },
          }}
        >
          {/* Temperature */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "1.5rem", // Enhanced padding for spacing
              borderRadius: 3,
              backgroundColor: "rgba(245, 29, 65, 0.15)", // Transparent white
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)", // Softer shadow
              minHeight: "100%", // Ensures all cards expand equally
              textAlign: "center", // Center-align the text
              // transition: "transform 0.3s ease", // Subtle hover effect
              // "&:hover": {
              //   transform: "scale(1.05)", // Slight scale-up on hover
              // },
            }}
          >
            <ThermostatIcon
              sx={{ color: "#ff6f61", fontSize: "2.5rem", mb: 1 }}
            />
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Temperature
            </Typography>
            <Typography>{weatherDataForCurrentHour?.temperature}°C</Typography>
          </Box>

          {/* Humidity */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "1.5rem", // Enhanced padding for spacing
              borderRadius: 3,
              backgroundColor: "rgba(29, 177, 245, 0.15)", // Transparent white
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)", // Softer shadow
              minHeight: "100%", // Ensures all cards expand equally
              textAlign: "center", // Center-align the text
              // transition: "transform 0.3s ease", // Subtle hover effect
              // "&:hover": {
              //   transform: "scale(1.05)", // Slight scale-up on hover
              // },
            }}
          >
            <OpacityIcon sx={{ color: "#03a9f4", fontSize: "2.5rem", mb: 1 }} />
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Humidity
            </Typography>
            <Typography>{weatherDataForCurrentHour?.humidity}%</Typography>
          </Box>

          {/* Wind Speed */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "1.5rem", // Enhanced padding for spacing
              borderRadius: 3,
              backgroundColor: "rgba(73, 173, 64, 0.15)", // Transparent white
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)", // Softer shadow
              minHeight: "100%", // Ensures all cards expand equally
              textAlign: "center", // Center-align the text
              // transition: "transform 0.3s ease", // Subtle hover effect
              // "&:hover": {
              //   transform: "scale(1.05)", // Slight scale-up on hover
              // },
            }}
          >
            <AirIcon sx={{ color: "#81c784", fontSize: "2.5rem", mb: 1 }} />
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Wind Speed
            </Typography>
            <Typography>{weatherDataForCurrentHour?.wind_speed} m/s</Typography>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default WeatherInfo;

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTheme, useMediaQuery } from "@mui/material"; // Change the import for useMediaQuery
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Autocomplete,
  TextField,
  Drawer,
  IconButton,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import GreenSpaceMap from "./GreenSpaceMap";
import SearchResults from "./SearchResults";
import CloseIcon from "@mui/icons-material/Close";
import loaderGif from "../assets/images/research2.gif";
import CustomStepper from "./CustomStepper";

import currentLocationIcon from "../assets/images/current_location.png";
import parkLocationIcon from "../assets/images/park_location.png";
import playgroundLocationIcon from "../assets/images/playground_location.png";
import sportLocationIcon from "../assets/images/sport_location.png";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q";

const NearbyGreenSpaces = () => {
  const mapRef = useRef(null);
  const searchSectionRef = useRef(null);
  const theme = useTheme();
  const bottomSectionRef = useRef(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [location, setLocation] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [coordinates, setCoordinates] = useState({
    latitude: -37.8136,
    longitude: 144.9631,
  });
  const [showMarker, setShowMarker] = useState(false);
  const [greenSpaceType, setGreenSpaceType] = useState("");
  const [facilities, setFacilities] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [greenSpaces, setGreenSpaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [placeIds, setPlaceIds] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const locationState = useLocation().state;
  const [isRecommendation, setIsRecommendation] = useState(false);

  // Check if user comes from ParkInfoDetailPage and restore session data
  useEffect(() => {
    if (locationState?.fromNearbyGreenSpaces) {
      const savedLocation = sessionStorage.getItem("location");
      const savedCoordinates = JSON.parse(
        sessionStorage.getItem("coordinates")
      );
      const savedGreenSpaceType = sessionStorage.getItem("greenSpaceType");
      const savedFacilities = JSON.parse(sessionStorage.getItem("facilities"));
      const savedGreenSpaces = JSON.parse(
        sessionStorage.getItem("greenSpaces")
      );
      const savedPlaceIds = JSON.parse(sessionStorage.getItem("placeIds"));
      const savedDrawerOpen = sessionStorage.getItem("drawerOpen") === "true"; // Check drawer state

      if (savedLocation) setLocation(savedLocation);
      if (savedCoordinates) setCoordinates(savedCoordinates);
      if (savedGreenSpaceType) setGreenSpaceType(savedGreenSpaceType);
      if (savedFacilities) setFacilities(savedFacilities);
      if (savedGreenSpaces) setGreenSpaces(savedGreenSpaces);
      if (savedPlaceIds) setPlaceIds(savedPlaceIds);
      if (savedDrawerOpen) setDrawerOpen(true); // Open the drawer if previously opened

      setShowMarker(true); // Restore marker visibility
    }
  }, [locationState]);

  const facilityOptions = {
    "Park and Garden": [
      "Drinking Fountain",
      "Bicycle Rails",
      "Picnic Setting",
      "Playground",
      "Toilet",
    ],
    Sportsfield: [
      "Drinking Fountain",
      "Bicycle Rails",
      "Toilet",
      "Picnic Setting",
      "Seat",
    ],
    Playground: ["Swing", "Shade", "Slide", "PicnicSetting", "Toilet"],
  };

  const fetchSuggestions = async (input) => {
    if (!input) {
      setSuggestions([]);
      return;
    }

    const bbox = "144.5937,-38.4339,145.5126,-37.5113"; // Southwest lon/lat, Northeast lon/lat

    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        input
      )}.json?bbox=${bbox}&limit=5&access_token=${MAPBOX_TOKEN}`
    );
    const data = await response.json();
    if (data.features) {
      setSuggestions(
        data.features.map((feature) => ({
          label: feature.place_name,
          coordinates: feature.center,
        }))
      );
    }
  };

  const reverseGeocode = async (latitude, longitude) => {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAPBOX_TOKEN}`
    );
    const data = await response.json();
    if (data.features && data.features.length > 0) {
      return data.features[0].place_name;
    }
    return "Current Location";
  };

  const handleLocationChange = (event, newValue) => {
    const locationName = newValue?.label || "";
    setLocation(locationName);

    if (locationName && newValue?.coordinates) {
      setCoordinates({
        latitude: newValue.coordinates[1],
        longitude: newValue.coordinates[0],
      });
      setShowMarker(true);
      fetchNearbyGreenSpaces(locationName);

      if (searchSectionRef.current) {
        searchSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude });
          setShowMarker(true);
          const placeName = await reverseGeocode(latitude, longitude);
          setLocation(placeName);
          fetchNearbyGreenSpaces(placeName);

          if (searchSectionRef.current) {
            searchSectionRef.current.scrollIntoView({ behavior: "smooth" });
          }
        },
        (error) => {
          console.error("Error fetching location:", error);
          if (error.code === error.TIMEOUT) {
            alert(
              "The request to get your location timed out. Please try again or manually enter your location."
            );
          } else if (error.code === error.PERMISSION_DENIED) {
            alert(
              "Permission to access location was denied. Please allow location access or manually enter your location."
            );
          } else if (error.code === error.POSITION_UNAVAILABLE) {
            alert(
              "Location information is unavailable. Please manually enter your location."
            );
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 10000, // Increased timeout to 10 seconds
          maximumAge: 0,
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [coordinates.longitude, coordinates.latitude],
        zoom: 12,
        essential: true,
      });
    }
  }, [coordinates]);

  const fetchNearbyGreenSpaces = async (locationName) => {
    try {
      console.log(`Fetching nearby green spaces for location: ${locationName}`);
      const response = await fetch(
        `https://api.greenfinderinmelb.me/api/find-nearby-parks/?location=${encodeURIComponent(
          locationName
        )}&latitude=${coordinates.latitude}&longitude=${coordinates.longitude}`
      );
      const data = await response.json();

      if (Array.isArray(data) && data.length > 0) {
        setGreenSpaces(data);
        const ids = data.map((space) => space.id);
        setPlaceIds(ids); // Populate placeIds for nearby spaces
        setIsRecommendation(false); // Not a recommendation, these are nearby spaces
        sessionStorage.setItem("placeIds", JSON.stringify(ids));
      } else {
        setDialogOpen(true); // Open the dialog
        setIsRecommendation(true); // This is a recommendation
        console.log(
          "No nearby green spaces found. Fetching spaces for Melbourne..."
        );

        const melbourneResponse = await fetch(
          `https://api.greenfinderinmelb.me/api/find-nearby-parks/?location=Melbourne`
        );
        const melbourneData = await melbourneResponse.json();

        if (Array.isArray(melbourneData) && melbourneData.length > 0) {
          const ids = melbourneData.map((space) => space.id);
          setPlaceIds(ids); // Populate placeIds for Melbourne

          const calculatedSpaces = melbourneData.map((space) => {
            const coordsArray = space.coords
              .replace(/[()]/g, "")
              .split(",")
              .map(Number);
            const latitude = parseFloat(coordsArray[0]);
            const longitude = parseFloat(coordsArray[1]);
            const distance = calculateDistance(
              coordinates.latitude,
              coordinates.longitude,
              latitude,
              longitude
            );
            return { ...space, distance };
          });

          const sortedSpaces = calculatedSpaces.sort(
            (a, b) =>
              b.matchedAmenities?.length - a.matchedAmenities?.length ||
              a.distance - b.distance
          );
          setGreenSpaces(sortedSpaces.slice(0, 10)); // Show top 10 suggestions
          sessionStorage.setItem("placeIds", JSON.stringify(ids)); // Save Melbourne placeIds for future filtering
        } else {
          console.log("No green spaces found in Melbourne.");
          setGreenSpaces([]);
        }
      }
    } catch (error) {
      console.error("Error fetching nearby green spaces:", error);
      setGreenSpaces([]);
    }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degree) => degree * (Math.PI / 180);
    const R = 6371;
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  };

  const fetchFilteredGreenSpaces = useCallback(async () => {
    if (placeIds.length === 0) {
      console.log("No green spaces available for filtering.");
      setGreenSpaces([]);
      return;
    }

    const categoryMap = {
      "Park and Garden": "Parks and gardens",
      Sportsfield: "Sportsfields and organised recreation",
      Playground: "Playgrounds",
    };

    const selectedCategory = categoryMap[greenSpaceType];

    try {
      const response = await fetch(
        `https://api.greenfinderinmelb.me/api/filter-space-type/?category=${encodeURIComponent(
          selectedCategory
        )}&place_ids=${JSON.stringify(placeIds)}`
      );
      const data = await response.json();

      if (Array.isArray(data) && data.length > 0) {
        const filteredData = data.filter(
          (space) =>
            space.name.trim() !== "" && space.category === selectedCategory
        );
        setGreenSpaces(filteredData);

        // Save search results in sessionStorage
        sessionStorage.setItem("greenSpaces", JSON.stringify(filteredData));
      } else {
        console.log("No green spaces available after filtering.");
        setGreenSpaces([]);
      }
    } catch (error) {
      console.error("Error fetching filtered results:", error);
      setGreenSpaces([]);
    }
  }, [greenSpaceType, placeIds]);

  useEffect(() => {
    if (greenSpaceType && placeIds.length > 0) {
      fetchFilteredGreenSpaces();
    }
  }, [greenSpaceType, placeIds, fetchFilteredGreenSpaces]);

  const handleGreenSpaceTypeChange = (event) => {
    setGreenSpaceType(event.target.value);
    setFacilities([]);

    setTimeout(() => {
      if (bottomSectionRef.current) {
        bottomSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  };

  const handleFacilityChange = (event) => {
    const value = event.target.name;
    setFacilities((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      // Fetch green spaces based on Melbourne location if no nearby spaces
      if (placeIds.length === 0) {
        console.log(
          "No nearby green spaces found. Fetching Melbourne spaces..."
        );
        await fetchNearbyGreenSpaces("Melbourne");

        // Wait for placeIds to update before proceeding
        await new Promise((resolve) => setTimeout(resolve, 300)); // Allow state updates to settle
      }

      // Ensure placeIds have been updated
      if (placeIds.length === 0) {
        console.error("No placeIds available after fetching, skipping search.");
        setLoading(false);
        return;
      }

      console.log(
        "Session data being set. Current location:",
        location,
        "Coordinates:",
        coordinates,
        "GreenSpaceType:",
        greenSpaceType,
        "Facilities:",
        facilities
      );

      // Save session data for future use
      sessionStorage.setItem("location", location);
      sessionStorage.setItem("coordinates", JSON.stringify(coordinates));
      sessionStorage.setItem("greenSpaceType", greenSpaceType);
      sessionStorage.setItem("facilities", JSON.stringify(facilities));
      sessionStorage.setItem("drawerOpen", true);

      const categoryMap = {
        "Park and Garden": "Parks and gardens",
        Sportsfield: "Sportsfields and organised recreation",
        Playground: "Playgrounds",
      };

      const selectedCategory = categoryMap[greenSpaceType];

      // Build the filter URL
      let url = `https://api.greenfinderinmelb.me/api/filter-amenities/?place_ids=[${placeIds.join(
        ","
      )}]&category=${encodeURIComponent(selectedCategory)}`;

      // Append the amenities filter if selected
      if (facilities.length === 0) {
        url += `&amenity_filters`; // No filters, but the parameter must still be included
      } else {
        // If amenities are selected, append them as filters
        const amenityFiltersQueryString = facilities
          .map((facility) => `amenity_filters=${encodeURIComponent(facility)}`)
          .join("&");
        url += `&${amenityFiltersQueryString}`;
      }

      console.log("Fetching filtered data with URL:", url);

      const response = await fetch(url);
      const data = await response.json();

      console.log("Data fetched from API:", data);

      if (data.length > 0) {
        const filteredData = data.filter(
          (space) =>
            space.name.trim() !== "" && space.category === selectedCategory
        );

        const sortedData = filteredData
          .map((space) => {
            const coordsArray = space.coords
              .replace(/[()]/g, "")
              .split(",")
              .map(Number);
            const latitude = parseFloat(coordsArray[0]);
            const longitude = parseFloat(coordsArray[1]);
            const distance = calculateDistance(
              coordinates.latitude,
              coordinates.longitude,
              latitude,
              longitude
            );

            const matchedAmenities = space.amenities
              ? JSON.parse(space.amenities.replace(/'/g, '"'))
              : [];

            return {
              ...space,
              latitude,
              longitude,
              distance,
              matchedAmenities,
            };
          })
          .sort(
            (a, b) =>
              b.matchedAmenities.length - a.matchedAmenities.length ||
              a.distance - b.distance
          );

        console.log("Sorted and filtered data:", sortedData);

        setGreenSpaces(sortedData.slice(0, 10)); // Show top 10 results

        sessionStorage.setItem(
          "top10GreenSpaces",
          JSON.stringify(sortedData.slice(0, 10))
        );

        const lngs = sortedData.map((space) => space.longitude);
        const lats = sortedData.map((space) => space.latitude);

        const bounds = [
          [Math.min(...lngs), Math.min(...lats)],
          [Math.max(...lngs), Math.max(...lats)],
        ];

        if (mapRef.current) {
          mapRef.current.fitBounds(bounds, {
            padding: { top: 20, bottom: 20, left: 20, right: 20 },
            maxZoom: 14,
            duration: 1000,
          });
        }

        console.log("Drawer will open with results.");
        setDrawerOpen(true); // Open the drawer after data is ready
      } else {
        setGreenSpaces([]); // No results found
        console.log("No results found after applying filters.");
        setDrawerOpen(true); // Open the drawer to show "No green spaces found" message
      }
    } catch (error) {
      console.error("Error fetching filtered results:", error);
    } finally {
      setLoading(false);
      console.log("Search completed.");
    }
  };

  const handleNavigate = (park) => {
    // Save the user's selections and search results to sessionStorage before navigating
    sessionStorage.setItem("location", location);
    sessionStorage.setItem("coordinates", JSON.stringify(coordinates));
    sessionStorage.setItem("greenSpaceType", greenSpaceType);
    sessionStorage.setItem("facilities", JSON.stringify(facilities));
    sessionStorage.setItem("greenSpaces", JSON.stringify(greenSpaces));
    sessionStorage.setItem("placeIds", JSON.stringify(placeIds));

    const { id: placeId, category } = park;

    // Navigate to the ParkInfoDetailPage
    navigate("/navigate", {
      state: {
        park,
        placeId,
        category,
        userCoordinates: coordinates,
        fromNearbyGreenSpaces: true, // Custom state to indicate navigation from NearbyGreenSpaces
      },
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: "#f5f4e2",
        padding: { xs: 2, sm: 3 },
        flexGrow: 1,
        minHeight: "100vh",
        boxSizing: "border-box",
        overflowX: "hidden", // Prevent horizontal scrolling
        overflowY: "auto", // Enable vertical scrolling for long content
      }}
    >
      <CustomStepper activeStep={0} />{" "}
      {/* Set the active step based on the current page */}
      {/* Add Static Banner */}
      {/* <StaticBanner /> */}
      {/* Add paddingTop or marginTop to the Grid */}
      <Grid
        container
        spacing={2}
        sx={{
          flexGrow: 1,
          padding: 0,
          margin: 0,
          width: "100%",
          overflowX: "hidden", // Ensure the grid does not cause horizontal overflow
          flexDirection: { xs: "column", md: "row" }, // Stack on small screens, side by side on larger
        }}
      >
        {/* Map Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            height: { xs: "40vh", sm: "45vh", md: "calc(100vh - 120px)" },
            padding: 0,
            margin: 0,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            width: "100%", // Ensure full width on small screens
          }}
        >
          <GreenSpaceMap
            ref={mapRef}
            coordinates={coordinates}
            greenSpaces={greenSpaces}
            currentLocationIcon={currentLocationIcon}
            parkLocationIcon={parkLocationIcon}
            playgroundLocationIcon={playgroundLocationIcon}
            sportLocationIcon={sportLocationIcon}
            showMarker={showMarker}
            style={{
              flexGrow: 1,
              width: "100%",
              height: "100%",
            }}
          />
          {/* Legend under the map */}
          <Box
            mt={1}
            mb={2}
            p={1}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{
              border: "1px solid #E0E0E0", // Light border for separation
              borderRadius: "12px", // Softer corners for a modern look
              backgroundColor: "#b2c7b4", // Light background
              boxShadow: "0px 4px 8px rgba(0,0,0,0.1)", // Shadow for depth
              padding: "8px", // Padding for spacing
              color: "#417446", // Green text color to match the theme
              width: "100%",
              flexWrap: "wrap", // Allow the legend items to wrap on smaller screens
            }}
          >
            {/* Individual Legend Item */}
            {[
              { icon: currentLocationIcon, label: "Your Current Location" },
              { icon: parkLocationIcon, label: "Park and Garden" },
              { icon: sportLocationIcon, label: "Sportsfield" },
              { icon: playgroundLocationIcon, label: "Playground" },
            ].map((item, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                gap={0.2} // Add space between icon and text
                sx={{
                  backgroundColor: "#ffffff", // White background for each item
                  padding: "4px 6px", // Inner padding
                  borderRadius: "8px", // Rounded corners
                  border: "1px solid #E0E0E0", // Subtle border for distinction
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Slight shadow for depth
                  width: "fit-content", // Ensure it wraps the content
                }}
              >
                <img
                  src={item.icon}
                  alt={`${item.label} Icon`}
                  style={{ width: "28px", height: "28px" }} // Slightly larger icons for clarity
                />
                <Typography
                  sx={{
                    fontSize: "0.75rem", // Slightly larger for better readability
                    fontWeight: 500, // Medium weight for clearer emphasis
                    color: "#2e7d32", // Dark green for consistency
                  }}
                >
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: { xs: "center", md: "flex-start" },
            alignItems: "center",
            height: { xs: "40vh", sm: "45vh", md: "calc(100vh - 120px)" },
            overflowY: "auto",
            width: "100%",
            boxSizing: "border-box",
            padding: { xs: 2, md: 3 },
            margin: 0,
            overflowX: "hidden", // Prevent horizontal scroll for search form
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            p={2}
            sx={{
              backgroundColor: "#FAF8F5",
              borderRadius: "8px",
              border: "1px solid #E0E0E0",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              width: "100%",
              maxWidth: "600px",
              minWidth: { xs: "320px", sm: "400px" }, // Ensure minimum width but prevent overflow
              textAlign: "center",
              boxSizing: "border-box",
            }}
          >
            <Autocomplete
              freeSolo
              options={suggestions}
              value={location}
              onInputChange={(event, value) => fetchSuggestions(value)}
              onChange={handleLocationChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location within City of Melbourne"
                  placeholder="Enter your current location"
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#FAF8F5", // Lighter background inside input
                      "& fieldset": {
                        borderColor: "#417446", // Fern Green border
                      },
                      "&:hover fieldset": {
                        borderColor: "#598f57", // Middle Green border on hover
                      },
                    },
                  }}
                />
              )}
            />

            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ margin: "0 10px", textAlign: "center" }}
            >
              or
            </Typography>

            <Button
              variant="outlined"
              onClick={handleUseCurrentLocation}
              sx={{
                color: "#417446", // Fern Green for text
                borderColor: "#417446", // Fern Green for border
                transition: "all 0.3s ease", // Smooth transition effect
                "&:hover": {
                  backgroundColor: "#598f57", // Middle Green on hover
                  color: "white",
                  borderColor: "#598f57", // Middle Green border on hover
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Slight shadow on hover
                },
              }}
            >
              USE CURRENT LOCATION
            </Button>

            {location && (
              <>
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ marginBottom: "20px" }}
                >
                  <InputLabel id="green-space-type-label">
                    Select Green Space Type
                  </InputLabel>
                  <Select
                    labelId="green-space-type-label"
                    value={greenSpaceType}
                    onChange={handleGreenSpaceTypeChange}
                    label="Select Green Space Type"
                  >
                    <MenuItem value="Park and Garden">Park and Garden</MenuItem>
                    <MenuItem value="Sportsfield">Sportsfield</MenuItem>
                    <MenuItem value="Playground">Playground</MenuItem>
                  </Select>
                </FormControl>

                {greenSpaceType && (
                  <>
                    <Box>
                      <h3>
                        Choose the facilities you want in your green space:
                      </h3>
                      {facilityOptions[greenSpaceType].map((facility) => (
                        <FormControlLabel
                          key={facility}
                          control={
                            <Checkbox
                              checked={facilities.includes(facility)}
                              onChange={handleFacilityChange}
                              name={facility}
                            />
                          }
                          label={facility}
                        />
                      ))}
                    </Box>

                    <Box mt={2} mb={2}>
                      <span style={{ color: "#666", fontSize: "0.875rem" }}>
                        Please click on the "Search" button to see the green
                        spaces update on the map.
                      </span>
                    </Box>

                    <Button
                      variant="contained"
                      onClick={handleSearch}
                      sx={{
                        background:
                          "linear-gradient(90deg, #417446 0%, #598f57 100%)", // Gradient from Fern Green to Middle Green
                        color: "white",
                        padding: {
                          xs: "8px 16px", // Smaller padding on extra small screens
                          sm: "10px 20px", // Default padding on small screens and above
                        },
                        fontWeight: "bold",
                        borderRadius: "8px",
                        width: "100%", // Ensure the button takes full width
                        textAlign: "center",
                        "&:hover": {
                          backgroundColor: "#598f57", // Solid Middle Green on hover
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slight shadow for a raised effect
                        },
                      }}
                    >
                      SEARCH
                    </Button>
                  </>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          position="fixed"
          top="0"
          left="0"
          width="100%"
          zIndex="1000"
          backgroundColor="rgba(255, 255, 255, 0.8)"
        >
          <img
            src={loaderGif}
            alt="Loading..."
            style={{ width: "150px", height: "150px" }}
          />
        </Box>
      )}
      <Drawer
        anchor={isSmallScreen ? "bottom" : "right"} // Drawer from bottom on small screens
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: isSmallScreen ? "100%" : "50%", // Full width on small screens, 50% on large
            height: isSmallScreen ? "70vh" : "100vh", // Shorter height for bottom drawer
            backgroundColor: "#f5f4e2",
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end" p={1}>
          <IconButton
            onClick={() => setDrawerOpen(false)}
            aria-label="close"
            sx={{ color: "#417446" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Handle the case where no nearby green spaces are found, but we have recommendations */}
        {isRecommendation && greenSpaces.length > 0 && (
          <Box sx={{ padding: 5 }}>
            <Typography
              variant="h6"
              sx={{ color: "#008000", fontWeight: "bold" }}
            >
              No nearby green spaces found. But don't worry, we've found some
              great recommendations for you
            </Typography>
            {/* <Typography variant="body1" sx={{ marginTop: 1 }}>
        Although there are no green spaces around your location, we've found some great recommendations for you to explore nearby! 
        Check out the list below.
      </Typography> */}
          </Box>
        )}

        {/* Handle the case where no spaces match the selected amenities */}
        {greenSpaces.length === 0 && (
          <Box sx={{ padding: 2 }}>
            <Typography
              variant="h6"
              sx={{ color: "#FF5722", fontWeight: "bold" }}
            >
              No green spaces match your preferences
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              We couldn't find any green spaces that match your selected
              category and amenities. But don't give up! 🌳 Why not try
              adjusting your selections?
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginTop: 1, fontStyle: "italic", color: "#666" }}
            >
              You can try different green space types or amenities and discover
              more wonderful spaces in Melbourne!
            </Typography>
            <Box sx={{ textAlign: "center", marginTop: 2 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0C7621",
                  color: "white",
                  "&:hover": { backgroundColor: "#2ABB48" },
                }}
                onClick={() => setDrawerOpen(false)} // Close drawer on retry
              >
                Try Again
              </Button>
            </Box>
          </Box>
        )}

        {/* Display the list of green spaces if we have any */}
        {greenSpaces.length > 0 && (
          <SearchResults greenSpaces={greenSpaces} onSelect={handleNavigate} />
        )}
      </Drawer>
      {/* Modal for notifying no nearby green spaces */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: "16px", // Slightly larger radius for a softer appearance
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Softer shadow for modern look
            padding: "20px",
            width: "100%",
            maxWidth: "520px",
            textAlign: "center",
            maxHeight: "100vh",
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#417446", // Fern Green
            color: "white",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          <ErrorOutlineIcon sx={{ marginRight: "8px", fontSize: "2rem" }} />
          No Nearby Green Spaces Found
        </DialogTitle>

        <DialogContent
          dividers
          sx={{
            backgroundColor: "#FAF8F5",
            color: "#0C7621",
            padding: "24px",
            textAlign: "center",
            fontSize: "1rem",
          }}
        >
          <Typography variant="body1" sx={{ marginBottom: "16px" }}>
            We couldn't find any green spaces within a 3 km radius of your
            location. However, don't worry!
          </Typography>

          <Typography
            variant="body1"
            sx={{ marginBottom: "16px", fontWeight: "bold" }}
          >
            Here's what you can do:
          </Typography>

          <Box
            component="ul"
            sx={{
              textAlign: "left",
              paddingLeft: "20px",
              marginBottom: "16px",
              fontSize: "1rem",
            }}
          >
            <li>Select a green space type from the dropdown.</li>
            <li>
              Optionally, choose the amenities you want to see in a green space.
            </li>
            <li>
              Press the "Search" button to see smart suggestions based on your
              preferences.
            </li>
          </Box>

          <Typography
            variant="body2"
            sx={{ color: "#666", fontStyle: "italic" }}
          >
            Click ‘OK’ to continue searching for green spaces.
          </Typography>
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: "center",
            padding: "16px",
            backgroundColor: "#FAF8F5",
          }}
        >
          <Button
            onClick={() => setDialogOpen(false)}
            sx={{
              backgroundColor: "#598f57", // Middle Green
              color: "white",
              padding: "10px 24px",
              "&:hover": { backgroundColor: "#417446" }, // Fern Green on hover
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NearbyGreenSpaces;

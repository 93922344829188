import React, { useEffect, useRef, useState } from "react";
import { Map, Marker, NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl"; // Required for directions and fitting bounds
import axios from "axios";
import currentLocationIcon from "../../assets/exploreIcon/current_location.png";
import toiletIcon from "../../assets/exploreIcon/toilet.png";
import barbecueIcon from "../../assets/exploreIcon/barbecue.png";
import bicycleRailsIcon from "../../assets/exploreIcon/bicycle_rails.png";
import binIcon from "../../assets/exploreIcon/bin.png";
import drinkingFountainIcon from "../../assets/exploreIcon/drinking_fountain.png";
import picnicSettingIcon from "../../assets/exploreIcon/picnic_setting.png";
import playgroundIcon from "../../assets/exploreIcon/playground.png";
import seatIcon from "../../assets/exploreIcon/seat.png";
import { FormControlLabel, Switch } from "@mui/material";

const ExploreMap = ({
  parkCoordinates,
  amenities,
  onAmenityClick,
  directions,
  placeId,
  category,
  setSelectedAmenity = () => {},
  showGeoPoints,
  setShowGeoPoints,
}) => {
  const mapRef = useRef(null);
  const [shadedRegions, setShadedRegions] = useState([]); // Shaded regions
  const [markerRefs, setMarkerRefs] = useState([]); // To keep track of all marker references
  const [selectedGeoPoint, setSelectedGeoPoint] = useState(null); // To track selected geo point
  const [routeFitted, setRouteFitted] = useState(false); // Route handling
  // const [showGeoPoints, setShowGeoPoints] = useState(true);

  // Mapping of amenity types to image file paths
  const amenityImages = {
    Toilets: toiletIcon,
    Barbecues: barbecueIcon,
    "Bicycle Rails": bicycleRailsIcon,
    "Litter Bin": binIcon,
    "Drinking Fountain": drinkingFountainIcon,
    "Picnic Setting": picnicSettingIcon,
    Playgrounds: playgroundIcon,
    Seat: seatIcon,
  };

  const isCoordinateInMelbourne = ([lon, lat]) => {
    const melbourneBounds = {
      minLat: -38.433859,
      maxLat: -37.459772,
      minLon: 144.593741,
      maxLon: 145.512529,
    };

    return (
      lat >= melbourneBounds.minLat &&
      lat <= melbourneBounds.maxLat &&
      lon >= melbourneBounds.minLon &&
      lon <= melbourneBounds.maxLon
    );
  };

  // Fetch shaded regions from the backend
  useEffect(() => {
    if (placeId && category) {
      const fetchShadedRegions = async () => {
        try {
          const response = await axios.get(
            `https://api.greenfinderinmelb.me/api/get-shaded-regions?place_id=${placeId}&category=${category}`
          );
          setShadedRegions(response.data);
        } catch (error) {
          console.error("Error fetching shaded regions:", error);
        }
      };
      fetchShadedRegions();
    }
  }, [placeId, category]);

  // Add shaded regions to the map
  useEffect(() => {
    if (
      mapRef.current &&
      shadedRegions &&
      Object.keys(shadedRegions).length > 0
    ) {
      const map = mapRef.current.getMap();

      const addShadedRegions = () => {
        // Clear previous markers first
        markerRefs.forEach((marker) => marker.remove());
        setMarkerRefs([]); // Reset markerRefs state

        Object.keys(shadedRegions).forEach((geoPointKey) => {
          const geoShape = shadedRegions[geoPointKey]; // POLYGON data
          const geoPoint = geoPointKey.split(",").map(parseFloat); // Key as coordinates

          const [geoLon, geoLat] = geoPoint;

          let coordinates = geoShape
            .replace("POLYGON ((", "")
            .replace("))", "")
            .split(",")
            .map((coord) => {
              const [lon, lat] = coord.trim().split(" ").map(parseFloat);
              return [lon, lat];
            });

          coordinates = coordinates.filter(isCoordinateInMelbourne);

          if (
            coordinates.length > 0 &&
            coordinates[0][0] !== coordinates[coordinates.length - 1][0]
          ) {
            coordinates.push(coordinates[0]); // Close the polygon
          }

          if (coordinates.length <= 1) {
            console.log(
              `Region ${geoPointKey} has invalid coordinates, skipping...`
            );
            return;
          }

          const geojson = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [coordinates],
            },
          };

          // Add shaded region (polygon)
          if (!map.getSource(`shaded-region-${geoPointKey}`)) {
            map.addSource(`shaded-region-${geoPointKey}`, {
              type: "geojson",
              data: geojson,
            });

            map.addLayer({
              id: `shaded-region-layer-${geoPointKey}`,
              type: "fill",
              source: `shaded-region-${geoPointKey}`,
              paint: {
                "fill-color": "#66BB6A",
                "fill-opacity": 0.6,
              },
            });
          }

          // Handle geo-point markers
          if (showGeoPoints) {
            const markerElement = document.createElement("div");
            markerElement.style.width = "10px";
            markerElement.style.height = "10px";
            markerElement.style.borderRadius = "50%";
            markerElement.style.backgroundColor = "green";
            markerElement.style.cursor = "pointer";

            const marker = new mapboxgl.Marker({ element: markerElement })
              .setLngLat([geoLon, geoLat])
              .addTo(map);

            // Add marker to refs for future removal
            setMarkerRefs((prevRefs) => [...prevRefs, marker]);

            // Add event listener to marker
            marker.getElement().addEventListener("click", () => {
              const selectedGeoPoint = {
                id: geoPointKey,
                type: "Shaded Area",
                lon: geoLon,
                lat: geoLat,
              };

              setSelectedAmenity(selectedGeoPoint);
              onAmenityClick(selectedGeoPoint);
            });
          }
        });
      };

      if (map.isStyleLoaded()) {
        addShadedRegions();
      } else {
        map.on("load", addShadedRegions);
      }
    }
  }, [shadedRegions, showGeoPoints]);

  // Adjust map zoom and fit bounds for park coordinates and amenities
  useEffect(() => {
    if (mapRef.current && amenities) {
      const map = mapRef.current.getMap();
      const bounds = new mapboxgl.LngLatBounds();

      // Add park location to bounds
      bounds.extend([parkCoordinates.longitude, parkCoordinates.latitude]);

      // Add all amenities to bounds
      Object.keys(amenities).forEach((amenityType) => {
        amenities[amenityType].forEach((amenity) => {
          bounds.extend([amenity.lon, amenity.lat]);
        });
      });

      // Fit the map to show all amenities and the park
      if (!bounds.isEmpty()) {
        map.fitBounds(bounds, { padding: 50, maxZoom: 18, duration: 1000 });
      }
    }
  }, [amenities, parkCoordinates]);

  // Draw the route if directions are provided
  useEffect(() => {
    if (mapRef.current && directions?.coordinates) {
      const map = mapRef.current.getMap();

      const fitRouteBounds = () => {
        const routeGeoJSON = {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: directions.coordinates,
          },
        };

        // Remove any existing route source and layer before adding a new one
        if (map.getLayer("route")) {
          map.removeLayer("route");
        }
        if (map.getSource("route")) {
          map.removeSource("route");
        }

        // Add the new route
        map.addSource("route", {
          type: "geojson",
          data: routeGeoJSON,
        });

        map.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": 8,
            "line-dasharray": [2, 4],
          },
        });

        // Adjust bounds to fit the new route
        const bounds = new mapboxgl.LngLatBounds();
        directions.coordinates.forEach((coord) => bounds.extend(coord));

        if (!bounds.isEmpty()) {
          map.fitBounds(bounds, {
            padding: 30,
            maxZoom: 18,
            duration: 1500,
          });
        }
      };

      if (map.isStyleLoaded()) {
        fitRouteBounds();
      } else {
        map.on("load", fitRouteBounds);
      }
    }
  }, [directions]); // Trigger this effect when `directions` changes

  // Return a loading state if parkCoordinates are not available
  if (!parkCoordinates) {
    return <div>Loading Map...</div>;
  }

  return (
    <Map
      ref={mapRef}
      initialViewState={{
        longitude: parkCoordinates.longitude,
        latitude: parkCoordinates.latitude,
        zoom: 12,
      }}
      style={{ width: "100%", height: "100%", minHeight: "300px" }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxAccessToken="pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q"
    >
      {/* Toggle for shaded areas */}
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "10px", // Position it in the top-right corner
          backgroundColor: "rgba(255, 255, 255, 0.8)", // White with slight transparency
          padding: "8px", // Adjust padding to make it smaller
          borderRadius: "8px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 1, // Ensure it's on top of the map
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={showGeoPoints}
              onChange={(e) => setShowGeoPoints(e.target.checked)}
              color="success"
            />
          }
          label="Show Shaded Area Locations"
          style={{ fontSize: "0.7rem" }} // Decrease the font size
        />
      </div>

      {/* Marker for the park location */}
      <Marker
        longitude={parkCoordinates.longitude}
        latitude={parkCoordinates.latitude}
      >
        <img
          src={currentLocationIcon}
          alt="Park Location"
          style={{ width: "40px", height: "40px" }}
        />
      </Marker>

      {/* Plot amenities on the map */}
      {Object.keys(amenities)
        .filter((amenityType) => amenityImages[amenityType])
        .map((amenityType) =>
          amenities[amenityType].map((amenity) => (
            <Marker
              key={amenity.id}
              longitude={amenity.lon}
              latitude={amenity.lat}
            >
              <img
                src={amenityImages[amenityType]}
                alt={amenityType}
                style={{ width: "30px", height: "30px", cursor: "pointer" }}
                onClick={() => onAmenityClick(amenity)}
              />
            </Marker>
          ))
        )}

      {/* Add navigation control for zoom and pan */}
      <NavigationControl position="top-left" />
    </Map>
  );
};

export default ExploreMap;

import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Paper,
} from "@mui/material";
import EcoIcon from "@mui/icons-material/NaturePeopleRounded";
import CustomStepper from "./CustomStepper";
import { useLocation } from "react-router-dom";
import greenParentingImage from "../assets/images/parenting.jpg";
import homeImage from "../assets/images/house2.jpg";
import transportImage from "../assets/images/transport.jpg";
import headerImage from "../assets/images/firstBanner.jpg";
import ShoppingImage from "../assets/images/shopping.jpg";
import { useNavigate } from "react-router-dom";

export default function GoGreen() {
  const location = useLocation();
  const showStepper = location.state?.fromSummaryPage || false;
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "40px 20px",
        backgroundColor: "#F9F6EE",
        minHeight: "100vh",
      }}
    >
      {/* Conditionally render the stepper only if the user came from the summary page */}
      {showStepper && <CustomStepper activeStep={4} />}{" "}
      {/* Step 4 is "Go Green" */}
      <Typography variant="h3" sx={{ color: "#417446", marginBottom: "30px" }}>
        Go Green: A Guide for Busy Parents
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "#598f57",
          marginBottom: "50px",
          maxWidth: "900px",
          fontSize: "18px",
        }}
      >
        Living sustainably doesn’t have to be complicated. Research shows that
        adopting even small lifestyle changes can have a significant impact on
        reducing your carbon footprint while fostering a healthier environment
        for your children. Discover practical ways to integrate green habits
        into your daily routine—from transportation to home energy use—without
        adding stress to your busy life.
      </Typography>
      {/* Image Placeholder */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "600px",
          height: "350px",
          backgroundColor: "#D3D3D3",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "50px",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <img
          src={headerImage}
          alt="Header Image"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "8px",
          }}
        />
      </Box>
      {/* Sections with Hierarchy and Tips */}
      <Grid container spacing={6} sx={{ maxWidth: "1200px" }}>
        {/* Eco-Friendly Transportation Section */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "15px",
              padding: "8px",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Eco-Friendly Transportation
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#5A5A5A",
                  fontSize: "16px",
                  marginBottom: "15px",
                }}
              >
                Transportation accounts for around 19% of Melbourne’s carbon
                emissions. Choosing sustainable transportation is a powerful way
                to reduce your impact.
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Walk or Bike
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#5A5A5A",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
              >
                Studies show that 40% of trips in Australian cities are less
                than 2 kilometers—ideal for walking or biking. These choices not
                only reduce emissions but also benefit your family's health.
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Public Transport
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#5A5A5A", fontSize: "16px" }}
              >
                Using public transport instead of driving can cut your carbon
                footprint by up to 45% per trip, according to the International
                Transport Forum.
              </Typography>
              <Paper
                elevation={3}
                sx={{
                  padding: "15px",
                  marginTop: "20px",
                  backgroundColor: "#EAF8E6",
                  borderRadius: "15px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#417446", fontWeight: "bold" }}
                >
                  Tip for Parents:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#5A5A5A", fontSize: "16px" }}
                >
                  Turn sustainable trips into a game for your children. They can
                  earn points for every eco-friendly trip, encouraging lifelong
                  green habits.
                </Typography>
              </Paper>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                backgroundColor: "#D3D3D3",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <img
                src={transportImage}
                alt="Eco-Friendly Transportation"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            </Box>
          </Box>
        </Grid>

        {/* Energy-Efficient Homes Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                backgroundColor: "#D3D3D3",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <img
                src={homeImage}
                alt="Energy-Efficient Homes"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "15px",
              padding: "8px",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Energy-Efficient Homes
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#5A5A5A",
                  fontSize: "16px",
                  marginBottom: "15px",
                }}
              >
                Small changes at home can significantly reduce your energy
                consumption and save money. According to Energy Rating
                Australia, switching to energy-efficient appliances can reduce
                household energy use by up to 30%.
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Switch to LED Bulbs
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#5A5A5A",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
              >
                LED bulbs use 75% less energy and last 25 times longer than
                traditional incandescent bulbs, according to the U.S. Department
                of Energy.
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Unplug Devices
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#5A5A5A", fontSize: "16px" }}
              >
                Studies show that standby power (devices left plugged in when
                not in use) can account for up to 10% of household electricity
                use.
              </Typography>
              <Paper
                elevation={3}
                sx={{
                  padding: "15px",
                  marginTop: "20px",
                  backgroundColor: "#EAF8E6",
                  borderRadius: "15px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#417446", fontWeight: "bold" }}
                >
                  Did You Know?
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#5A5A5A", fontSize: "16px" }}
                >
                  Simple acts like turning off lights and electronics when not
                  in use can save the average family $100 to $150 annually on
                  energy bills.
                </Typography>
              </Paper>
            </CardContent>
          </Card>
        </Grid>

        {/* Green Shopping Section */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "15px",
              padding: "8px",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Green Shopping: Shop Smart, Shop Local
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#5A5A5A",
                  fontSize: "16px",
                  marginBottom: "15px",
                }}
              >
                Shopping choices can play a big role in your family’s
                environmental impact. According to a study by the Australian
                Conservation Foundation, reducing plastic use and buying local
                products are some of the most effective ways to live more
                sustainably.
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Buy Local
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#5A5A5A", fontSize: "16px" }}
              >
                Supporting local farmers and markets helps cut down on the
                carbon emissions associated with food transportation. Locally
                produced food travels an average of 50 kilometers, compared to
                2,400 kilometers for imported goods, significantly reducing its
                carbon footprint.
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Eco-Friendly Products
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#5A5A5A", fontSize: "16px" }}
              >
                Choosing products with minimal packaging or made from
                sustainable materials can make a huge difference. The Ellen
                MacArthur Foundation reports that 45% of global plastic waste
                comes from packaging alone.
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Bring Reusable Bags
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#5A5A5A", fontSize: "16px" }}
              >
                According to Planet Ark, Australians use over 3.9 billion
                plastic bags every year. By switching to reusable bags, you can
                prevent an estimated 150 plastic bags from entering the waste
                stream annually.
              </Typography>
              <Paper
                elevation={3}
                sx={{
                  padding: "15px",
                  marginTop: "20px",
                  backgroundColor: "#EAF8E6",
                  borderRadius: "15px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#417446", fontWeight: "bold" }}
                >
                  Green Tip:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#5A5A5A", fontSize: "16px" }}
                >
                  Turn grocery shopping into a family activity. Teach your
                  children to identify eco-friendly products by looking for
                  green labels like “Fair Trade,” “Organic,” or “Sustainable.”
                </Typography>
              </Paper>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                backgroundColor: "#D3D3D3",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <img
                src={ShoppingImage}
                alt="Green Shopping"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            </Box>
          </Box>
        </Grid>

        {/* Green Parenting Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                backgroundColor: "#D3D3D3",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <img
                src={greenParentingImage}
                alt="Green Parenting image"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "15px",
              padding: "8px",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Green Parenting: Nurture a Sustainable Future
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#5A5A5A", fontSize: "16px" }}
              >
                Getting children involved in green activities fosters a lifelong
                commitment to sustainability. A study by UNICEF shows that
                children who engage in eco-friendly practices are more likely to
                become environmentally responsible adults.
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Gardening
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#5A5A5A", fontSize: "16px" }}
              >
                Studies by The Royal Horticultural Society show that gardening
                helps children understand the natural world and develop
                responsibility. Plus, it’s a fun way to spend time outdoors
                together!
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Reduce & Reuse
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#5A5A5A", fontSize: "16px" }}
              >
                Teaching your kids to reduce waste and reuse materials helps
                them develop an eco-conscious mindset. Research from Zero Waste
                International Alliance suggests that households practicing waste
                reduction can reduce their total waste by 50%.
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Teach Sustainability
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#5A5A5A", fontSize: "16px" }}
              >
                According to Earthwatch Europe, involving children in
                discussions about the environment from a young age helps create
                environmentally conscious citizens.
              </Typography>
              <Paper
                elevation={3}
                sx={{
                  padding: "15px",
                  marginTop: "20px",
                  backgroundColor: "#EAF8E6",
                  borderRadius: "15px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#417446", fontWeight: "bold" }}
                >
                  Pro Parent Tip:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#5A5A5A", fontSize: "16px" }}
                >
                  Host a “Green Day” once a week where the family focuses on
                  eco-friendly activities like recycling, composting, or
                  exploring local parks.
                </Typography>
              </Paper>
            </CardContent>
          </Card>
        </Grid>

        {/* Join the Movement Section */}
        <Grid item xs={12}>
          <Card
            sx={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "15px",
              marginTop: "30px",
              padding: "8px",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                sx={{ color: "#417446", marginBottom: "10px" }}
              >
                Join the Movement: Make a Difference, One Step at a Time
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#5A5A5A",
                  fontSize: "16px",
                  marginBottom: "15px",
                }}
              >
                By making small, manageable changes, you’re not only helping to
                create a greener Melbourne, but you’re also setting a lasting
                example for your children. Research shows that parents who model
                sustainable behaviors have a positive influence on their
                children’s long-term habits. Start today and watch your family's
                impact grow!
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Call to Action */}
      <Box sx={{ marginTop: "50px", display: "flex", gap: "20px" }}>
        {/* Button to navigate back to homepage */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#417446",
            color: "#ffffff",
            padding: "12px 24px",
            "&:hover": {
              backgroundColor: "#598f57",
            },
          }}
          onClick={() => navigate("/")} // Navigate to homepage
        >
          Back to Homepage
        </Button>

        {/* Button to navigate to Learn More page */}
        <Button
          variant="outlined"
          sx={{
            borderColor: "#417446",
            color: "#417446",
            padding: "12px 24px",
            "&:hover": {
              borderColor: "#598f57",
              color: "#598f57",
            },
          }}
          onClick={() => navigate("/more-green")} // Navigate to learn more page
        >
          Explore Plants in Green Spaces
        </Button>
      </Box>
    </Box>
  );
}

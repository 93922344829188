import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import ResultCard from "./ResultCard"; // Assuming you have the ResultCard component
import loaderGif from "../assets/images/research2.gif";

// Color scheme for the theme
const colors = {
  background: "#c5d6ca",
  cardBackground: "#f5f4e2",
  text: "#2e7d32",
  header: "#558b2f",
  button: "#4caf50",
  buttonHover: "#388e3c",
};

// Styled components for layout
const Container = styled.div`
  font-family: "Arial", sans-serif;
  color: ${colors.text};
  background-color: ${colors.background};
  width: 100vw;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; // Adjust as per your layout
`;

const LoadingImage = styled.img`
  width: 100px;
  height: 100px;
`;

const MainTitle = styled.h2`
  font-size: 36px;
  font-weight: bold;
  color: #2e7d32;
  margin-bottom: 30px;
  text-align: center;
`;

const ErrorMessage = styled.p`
  font-size: 18px;
  color: red;
`;

const PersonalizedResults = () => {
  const location = useLocation();
  const { userAnswers } = location.state;

  const [parksData, setParksData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null); // State for handling errors

  useEffect(() => {
    if (userAnswers) {
      fetchFilteredParks(userAnswers);
    }
  }, [userAnswers]);

  // Fetch parks based on user filters
  const fetchFilteredParks = async (userAnswers) => {
    setIsLoading(true);
    setError(null); // Reset error on new fetch

    const {
      category,
      amenities,
      place_ids,
      coordinates,
      crowding_level,
      sustainability_score,
    } = userAnswers;

    const placeIdsString = place_ids.join(",");

    let apiUrl = `https://api.greenfinderinmelb.me/api/filter-amenities/?place_ids=[${placeIdsString}]&category=${category}`;

    if (amenities && amenities.length > 0) {
      const amenityFilters = amenities
        .map((amenity) => `amenity_filters=${amenity}`)
        .join("&");
      apiUrl += `&${amenityFilters}`;
    } else {
      apiUrl += `&amenity_filters`;
    }

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch parks. Please try again.");
      }

      const data = await response.json();
      let filteredParks = Array.isArray(data) ? data : [];

      if (crowding_level && filteredParks.length > 0) {
        filteredParks = filteredParks.filter(
          (park) => park.crowding_level === crowding_level
        );
      }

      if (sustainability_score) {
        const parksWithSustainabilityScores = [];

        for (const park of filteredParks) {
          try {
            const parkInfoResponse = await fetch(
              `https://api.greenfinderinmelb.me/api/place-info/?place_id=${park.id}&category=${category}`
            );
            const parkInfoData = await parkInfoResponse.json();

            if (parkInfoData.sustainability_score >= 4) {
              parksWithSustainabilityScores.push({
                ...park,
                sustainability_score: parkInfoData.sustainability_score,
              });
            }
          } catch (error) {
            console.error("Error fetching park info:", error);
          }
        }

        const sortedParks = parksWithSustainabilityScores.sort((a, b) => {
          const distanceA = calculateDistance(coordinates, a.coords);
          const distanceB = calculateDistance(coordinates, b.coords);
          return distanceA - distanceB;
        });

        const nearestParks = sortedParks.slice(0, 10);
        const smallestAreaParks = [...nearestParks]
          .sort((a, b) => a.area - b.area)
          .slice(0, 3);

        const parksWithInfo = await Promise.all(
          smallestAreaParks.map(async (park) => {
            try {
              const parkInfoResponse = await fetch(
                `https://api.greenfinderinmelb.me/api/place-info/?place_id=${park.id}&category=${category}`
              );
              const parkInfoData = await parkInfoResponse.json();
              return {
                ...park,
                ...parkInfoData,
              };
            } catch (error) {
              console.error("Error fetching park info:", error);
              return park;
            }
          })
        );

        setParksData(parksWithInfo);
      } else {
        const sortedParks = filteredParks.sort((a, b) => {
          const distanceA = calculateDistance(coordinates, a.coords);
          const distanceB = calculateDistance(coordinates, b.coords);
          return distanceA - distanceB;
        });

        const nearestParks = sortedParks.slice(0, 10);
        const smallestAreaParks = [...nearestParks]
          .sort((a, b) => a.area - b.area)
          .slice(0, 3);

        const parksWithInfo = await Promise.all(
          smallestAreaParks.map(async (park) => {
            try {
              const parkInfoResponse = await fetch(
                `https://api.greenfinderinmelb.me/api/place-info/?place_id=${park.id}&category=${category}`
              );
              const parkInfoData = await parkInfoResponse.json();
              return {
                ...park,
                ...parkInfoData,
              };
            } catch (error) {
              console.error("Error fetching park info:", error);
              return park;
            }
          })
        );

        setParksData(parksWithInfo);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Calculate distance between two coordinates using Haversine formula
  const calculateDistance = (userCoords, parkCoords) => {
    const [lat1, lon1] = [userCoords.latitude, userCoords.longitude];
    const [lat2, lon2] = parkCoords
      .replace("(", "")
      .replace(")", "")
      .split(", ")
      .map(parseFloat);

    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  };

  const deg2rad = (deg) => deg * (Math.PI / 180);

  return (
    <Container>
      <MainTitle>Your Matched Green Space Recommendations</MainTitle>
      {isLoading ? (
        <LoadingWrapper>
          <LoadingImage src={loaderGif} alt="Loading..." />
        </LoadingWrapper>
      ) : error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : parksData.length === 0 ? (
        <ErrorMessage>No parks found with the given criteria.</ErrorMessage>
      ) : (
        <ResultsWrapper>
          {parksData.map((park) => (
            <ResultCard
              key={park.place_id}
              name={park.name}
              category={park.category}
              opening_hours={park.opening_hours}
              user_rating={park.user_rating}
              sustainability_score={park.sustainability_score}
              amenities={park.amenities}
              associated_keywords={park.associated_keywords}
              colors={colors}
              parsedData={park}
              placeId={park.place_id}
              userCoordinates={userAnswers.coordinates}
            />
          ))}
        </ResultsWrapper>
      )}
    </Container>
  );
};

export default PersonalizedResults;

import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Stepper, Step, StepLabel } from "@mui/material"; // Import Stepper from MUI
import BoltIcon from "@mui/icons-material/Bolt";
import CommuteIcon from "@mui/icons-material/Commute";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; // Expand icon
import ExpandLessIcon from "@mui/icons-material/ExpandLess"; // Collapse icon

import GreenGuardian from "../assets/images/Green Guardian.png";
import EcoExplorer from "../assets/images/Eco Explorer.png";
import NatureNewbie from "../assets/images/Nature Newbie.png";

// Main Container
const Container = styled.div`
  font-family: "Arial", sans-serif;
  background-color: #417446;
  width: 100%;
  height: auto; /* Adjust height based on content */
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    height: auto; /* Allow height to adjust for mobile */
    padding-bottom: 20px;
  }
`;

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 80px 20px;

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`;

const SectionTitle = styled.h3`
  font-size: 36px;
  font-weight: bold;
  color: #f0ebd4;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 28px; /* Adjust for mobile */
  }
  @media (min-width: 1024px) {
    font-size: 40px; /* Increase for larger screens */
  }
`;

const Question = styled.p`
  font-size: 26px;
  margin-bottom: 30px;
  font-weight: 500;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 20px; /* Reduce font size on mobile */
    margin-bottom: 20px;
  }
`;

const OptionGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
  max-width: 500px;

  @media (max-width: 768px) {
    width: 100%; /* Expand width on mobile */
  }
`;

const OptionButton = styled.button`
  font-size: 20px;
  background-color: #f0ebd4;
  padding: 12px 20px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #ede7cd;
    transform: scale(1.05);
  }

  &.active {
    background-color: #417446;
    color: white;
  }
`;

const SummaryContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto; // Let the height adjust based on content, and we'll balance with flex properties
  @media (max-width: 768px) {
    flex-direction: column; // Stack sections vertically on mobile
    height: auto; // Allow height to adjust for mobile
  }
`;

const LeftSummary = styled.div`
  flex: 1; // Make the left section take up equal height
  background-color: #ece6da;
  padding: 40px;
  box-sizing: border-box;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%; /* Full width on mobile */
    padding: 20px;
  }
`;

const RightSummary = styled.div`
  width: 50%;
  height: auto; // Let the height adjust based on content
  background-color: #f5f2ec;
  padding: 40px;
  box-sizing: border-box;
  color: #2e7d32;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  @media (max-width: 768px) {
    width: 100%; /* Full width on mobile */
    padding: 20px;
  }
`;

// Encouraging Message based on score
// const EncouragingMessage = styled.p`
//   font-size: 18px;
//   color: #417446;
//   margin-top: 20px;
//   text-align: center;
// `;

const ButtonDescription = styled.p`
  font-size: 14px;
  color: #417446;
  margin-top: 5px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%; /* This ensures the buttons take up the same space as before */

  @media (max-width: 768px) {
    width: 80%; /* Make buttons wider but not full width on mobile */
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between; /* Keep the buttons side by side on desktop */
  margin-top: 30px;
  width: 100%; /* Ensure the buttons take up the full width of the container */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack buttons vertically on mobile */
    align-items: center; /* Center align the buttons */
    gap: 15px; /* Add some space between buttons */
  }
`;

const ExploreButton = styled.button`
  padding: 15px 20px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: #417446;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 100%;

  &:hover {
    background-color: #598f57;
    transform: scale(1.05);
  }
`;

const MatchButton = styled(ExploreButton)`
  background-color: #598f57;

  &:hover {
    background-color: #417446;
    transform: scale(1.05);
  }
`;

const MotivationalText = styled.h3`
  font-size: 22px;
  color: #2e7d32;
  margin-bottom: 20px;
  text-align: center;
`;

const QuestionText = styled.h4`
  font-size: 20px;
  margin-bottom: 10px;
  color: #417446;
`;

const FeedbackText = styled.p`
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
`;

// const PromotionText = styled.p`
//   font-size: 14px;
//   color: #598f57;
//   font-weight: bold;
// `;

const ScoreContainer = styled.div`
  background-color: #d8e8d2; /* Light green background to fit the eco-friendly theme */
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  margin-bottom: 20px; /* Space between score and encouraging message */
  margin-top: 30px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for a card effect */
`;

const ScoreText = styled.p`
  font-size: 28px; /* Larger font size for the score */
  font-weight: bold;
  color: #417446; /* Dark green for the text */
  margin: 0;
`;

const RankText = styled.p`
  font-size: 24px; /* Slightly smaller font for the rank */
  font-weight: 500;
  color: #598f57; /* Medium green color */
  margin: 10px 0 0 0;
`;

// Style for the icon wrapper to give it a circular background
const IconWrapper = styled.div`
  width: 60px; /* Increased width for more space */
  height: 60px; /* Increased height for more space */
  background-color: #598f57; /* Dark green background */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px; /* Increased margin for better spacing */
  flex-shrink: 0; /* Prevents the icon from shrinking */
`;

const FeedbackCardContent = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
`;

// FeedbackCard styles
const FeedbackCard = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer; /* Make the card clickable */
  transition: all 0.3s ease; /* Smooth transition */
  overflow: hidden;
  position: relative;
  &:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15); /* Hover effect */
  }
`;

// Collapsible content with transition
const CollapsibleContent = styled.div`
  max-height: ${(props) =>
    props.$isExpanded ? "1000px" : "0"}; /* Control expansion */
  overflow: hidden;
  transition: max-height 0.5s ease;
`;

const StepperContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center; /* Center the stepper horizontally */
  align-items: center;
  margin: 0; /* Remove margins */
  background-color: #d8e8d2; /* Set background color for the container */
  border-radius: 4px; /* Optional: add border radius */
  padding: 10px 0; /* Optional: only vertical padding */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow */
`;

// Indicator to show expand/collapse
const ExpandIndicator = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  color: #598f57;
`;

const RankProgressBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0ebd4;
  border-radius: 12px;
  padding: 10px;
  margin: 20px; /* Adjust margins for spacing */
  margin-top: -10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
`;

const RankLabel = styled.p`
  margin-top: 10px;
  text-align: center;
  font-weight: ${(props) => (props.$active ? "bold" : "normal")};
  color: ${(props) => (props.$active ? "#388e3c" : "#808080")};
  opacity: ${(props) => (props.$active ? 1 : 0.5)};
  transition: color 0.3s, opacity 0.3s, font-weight 0.3s;
`;

const ReadMoreText = styled.span`
  font-size: 14px;
  color: #598f57;
  font-weight: bold;
  margin-left: 5px;
`;

// Stepper Wrapper to control max-width
const StepperWrapper = styled.div`
  width: 80%;
  max-width: 800px;
  background-color: #d8e8d2;
  border-radius: 4px;
  padding: 10px;
`;

function AssessmentPage() {
  const [step, setStep] = useState(0);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState([]); // Array to store feedback for each step
  // const [promotions, setPromotions] = useState([]); // Array to store promotions
  const [expandedCards, setExpandedCards] = useState([]);
  const navigate = useNavigate();

  // Handle expanding and collapsing feedback cards
  const toggleExpandCard = (index) => {
    setExpandedCards((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleNextStep = (info, scoreValue = 0) => {
    setScore((prevScore) => Math.min(prevScore + scoreValue, 10)); // Limit score to a maximum of 10
    setFeedback((prevFeedback) => [...prevFeedback, info]); // Save feedback
    setStep((prevStep) => prevStep + 1); // Move to the next step
  };

  const getGreenRank = () => {
    if (score >= 8) {
      return { rank: "Green Guardian", image: GreenGuardian };
    }
    if (score >= 5) {
      return { rank: "Eco Explorer", image: EcoExplorer };
    }
    return { rank: "Nature Newbie", image: NatureNewbie };
  };

  return (
    <>
      <StepperContainer>
        <StepperWrapper>
          <Stepper
            activeStep={step}
            alternativeLabel
            sx={{
              "& .MuiStepIcon-root": {
                color: "#417446",
                "&.Mui-active": {
                  color: "#417446",
                },
                "&.Mui-completed": {
                  color: "#417446",
                },
              },
              "& .MuiStepIcon-text": {
                fill: "white",
              },
              "& .MuiStepLabel-label": {
                color: step >= 0 ? "#417446" : "#808080",
                fontWeight: step === step ? "bold" : "normal",
              },
            }}
          >
            {["Question 1", "Question 2", "Question 3", "Your Score"].map(
              (label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              )
            )}
          </Stepper>
        </StepperWrapper>
      </StepperContainer>

      {/* Main Content */}
      <Container>
        {step === 0 && (
          <Section>
            <SectionTitle>How Green Are You?</SectionTitle>
            <Question>
              How many hours of free time do you spend indoors each day?
            </Question>
            <OptionGroup>
              <OptionButton
                onClick={() =>
                  handleNextStep(
                    <>
                      By spending <strong>0-2 hours</strong> indoors, you
                      consume around <strong>0.8 kWh</strong> of electricity
                      each day, contributing to approximately{" "}
                      <strong>0.3 kg of CO2</strong> emissions. This is
                      equivalent to driving around <strong>2 kilometers</strong>{" "}
                      in a car!
                    </>,
                    // "Even though your energy use is already minimal, Green Finder helps you explore nearby green spaces for further sustainability.",
                    4
                  )
                }
              >
                0-2 hours
              </OptionButton>
              <OptionButton
                onClick={() =>
                  handleNextStep(
                    <>
                      By spending <strong>3-5 hours</strong> indoors, you
                      consume around <strong>2.5 kWh</strong> of electricity
                      each day, contributing to approximately{" "}
                      <strong>1.1 kg of CO2</strong> emissions. This is
                      equivalent to driving around <strong>7 kilometers</strong>{" "}
                      in a car!
                    </>,
                    // "Reducing your indoor time can save significant energy. Green Finder helps you find outdoor activities, cutting down energy consumption.",
                    3
                  )
                }
              >
                3-5 hours
              </OptionButton>
              <OptionButton
                onClick={() =>
                  handleNextStep(
                    <>
                      By spending <strong>6-8 hours</strong> indoors, you
                      consume around <strong>3.5 kWh</strong> of electricity
                      each day, contributing to approximately{" "}
                      <strong>1.5 kg of CO2</strong> emissions. This is
                      equivalent to driving around{" "}
                      <strong>10 kilometers</strong> in a car!
                    </>,
                    // <>
                    //   With Green Finder, you can easily plan your next visit to
                    //   a nearby green space. Spending 2 hours outdoors can help
                    //   you save around <strong>1.2 kWh</strong> of electricity,
                    //   reducing your carbon footprint by{" "}
                    //   <strong>0.5 kg of CO2</strong>.
                    // </>,
                    2
                  )
                }
              >
                6-8 hours
              </OptionButton>
              <OptionButton
                onClick={() =>
                  handleNextStep(
                    <>
                      By spending <strong>9+ hours</strong> indoors, you consume
                      around <strong>4.5 kWh</strong> of electricity each day,
                      contributing to approximately <strong>2 kg of CO2</strong>{" "}
                      emissions. This is equivalent to driving around{" "}
                      <strong>13 kilometers</strong> in a car!
                    </>,
                    // <>
                    //   Switching to outdoor activities with Green Finder could
                    //   save you up to <strong>1.5 kWh</strong> of electricity,
                    //   reducing your CO2 footprint.
                    // </>,
                    1
                  )
                }
              >
                9+ hours
              </OptionButton>
            </OptionGroup>
          </Section>
        )}

        {step === 1 && (
          <Section>
            <Question>
              How many trips do you take to parks or green spaces each week by
              car?
            </Question>
            <OptionGroup>
              <OptionButton
                onClick={() =>
                  handleNextStep(
                    <>
                      By taking <strong>0-1 round trips</strong> to green spaces
                      by car each week, you emit around{" "}
                      <strong>0.96 kg of CO2</strong> for a{" "}
                      <strong>4 km</strong> trip. This is equivalent to what{" "}
                      <strong>1/4th of a tree</strong> absorbs in a year.
                    </>,
                    // "By using Green Finder, you can walk to nearby green spaces and avoid CO2 emissions altogether.",
                    4
                  )
                }
              >
                0-1 trips
              </OptionButton>
              <OptionButton
                onClick={() =>
                  handleNextStep(
                    <>
                      By taking <strong>2-3 round trips</strong> to green spaces
                      by car each week, you emit around{" "}
                      <strong>2.88 kg of CO2</strong> for <strong>4 km</strong>{" "}
                      trips. This is equivalent to what <strong>1 tree</strong>{" "}
                      absorbs in a year.
                    </>,
                    // "Green Finder helps you choose walking and cycling routes, reducing your CO2 footprint by 2.88 kg each week.",
                    3
                  )
                }
              >
                2-3 trips
              </OptionButton>
              <OptionButton
                onClick={() =>
                  handleNextStep(
                    <>
                      By taking <strong>4-6 round trips</strong> to green spaces
                      by car each week, you emit around{" "}
                      <strong>5.76 kg of CO2</strong> for <strong>4 km</strong>{" "}
                      trips. This is equivalent to what <strong>2 trees</strong>{" "}
                      absorb in a year.
                    </>,
                    // "Green Finder helps you find walking and cycling routes, reducing CO2 emissions by 2.88 kg each week.",
                    2
                  )
                }
              >
                4-6 trips
              </OptionButton>
              <OptionButton
                onClick={() =>
                  handleNextStep(
                    <>
                      By taking <strong>7 or more round trips</strong> to green
                      spaces by car each week, you emit around{" "}
                      <strong>6.72+ kg of CO2</strong> for <strong>4 km</strong>{" "}
                      trips. This is equivalent to what{" "}
                      <strong>2.25 trees</strong> absorb in a year.
                    </>,
                    // "Switching to walking or cycling with Green Finder can help you cut your emissions by 3 kg or more.",
                    1
                  )
                }
              >
                7+ trips
              </OptionButton>
            </OptionGroup>
          </Section>
        )}

        {step === 2 && (
          <Section>
            <Question>
              Have you ever felt stress or your mind got blocked?
            </Question>
            <OptionGroup>
              <OptionButton
                onClick={() =>
                  handleNextStep(
                    <>
                      Spending time in nature has been shown to reduce cortisol
                      levels (the stress hormone) by <strong>16%</strong>,
                      improving mental clarity and reducing stress.
                    </>,
                    // "Green Finder helps you find calming green spaces nearby to reduce stress and improve mental health.",
                    2 // Score for answering Yes
                  )
                }
              >
                Yes
              </OptionButton>
              <OptionButton
                onClick={() =>
                  handleNextStep(
                    <>
                      Even if you don’t feel stress, spending time in nature can
                      still boost your mood and mental well-being.
                    </>,
                    // "Green Finder helps you discover peaceful and quiet green spaces to maintain your mental health.",
                    2 // Score for answering No
                  )
                }
              >
                No
              </OptionButton>
            </OptionGroup>
          </Section>
        )}

        {step === 3 && (
          <SummaryContainer>
            {/* RightSummary will now be on the left side */}
            <RightSummary>
              <div>
                {/* Rank Progress Bar */}
                <RankProgressBar>
                  <RankLabel $active={getGreenRank().rank === "Nature Newbie"}>
                    Nature Newbie
                  </RankLabel>
                  <RankLabel $active={getGreenRank().rank === "Eco Explorer"}>
                    Eco Explorer
                  </RankLabel>
                  <RankLabel $active={getGreenRank().rank === "Green Guardian"}>
                    Green Guardian
                  </RankLabel>
                </RankProgressBar>

                {/* Updated Score and Rank Section */}
                <ScoreContainer>
                  <ScoreText>Your green score is: {score}/10</ScoreText>
                  <RankText>You are ranked as: {getGreenRank().rank}</RankText>

                  {/* Display Rank Image under the text */}
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <img
                      src={getGreenRank().image}
                      alt={getGreenRank().rank}
                      style={{ width: "200px" }}
                    />
                  </div>
                </ScoreContainer>

                {/* Encourage Message */}
                {/* <EncouragingMessage>
                  {getGreenRank().rank === "Green Guardian"
                    ? "Great work! You're a Green Guardian! Keep pushing for more sustainable actions and inspire others to follow!"
                    : getGreenRank().rank === "Eco Explorer"
                    ? "You're doing great! You've achieved the Eco Explorer rank. Consider challenging yourself to maintain this lifestyle!"
                    : "You're a Nature Newbie! There's a lot more you can do to reduce your carbon footprint. Start exploring green spaces and make a positive impact!"}
                </EncouragingMessage> */}

                <MotivationalText>
                  Now, it's time to contribute to a sustainable city of
                  Melbourne. How would you want to do it?
                </MotivationalText>

                <ButtonGroup>
                  <ButtonWrapper>
                    <ExploreButton
                      onClick={() => navigate("/nearby-green-spaces")}
                    >
                      Explore Myself
                    </ExploreButton>
                    <ButtonDescription>
                      Discover nearby green spaces and outdoor areas on your
                      own.
                    </ButtonDescription>
                  </ButtonWrapper>

                  <ButtonWrapper>
                    <MatchButton onClick={() => navigate("/question-page")}>
                      Get the Match
                    </MatchButton>
                    <ButtonDescription>
                      Get personalized green space recommendations tailored for
                      you.
                    </ButtonDescription>
                  </ButtonWrapper>
                </ButtonGroup>
              </div>
            </RightSummary>

            {/* LeftSummary will now be on the right side */}
            <LeftSummary>
              <h2>Here's Your Green Details</h2>
              {feedback.map((item, index) => (
                <FeedbackCard
                  key={index}
                  onClick={() => toggleExpandCard(index)}
                >
                  <FeedbackCardContent>
                    <IconWrapper>
                      {index === 0 && <BoltIcon style={{ color: "white" }} />}
                      {index === 1 && (
                        <CommuteIcon style={{ color: "white" }} />
                      )}
                      {index === 2 && (
                        <AddReactionIcon style={{ color: "white" }} />
                      )}
                    </IconWrapper>
                    <div>
                      <QuestionText>Question {index + 1}</QuestionText>
                      <CollapsibleContent $isExpanded={expandedCards[index]}>
                        <FeedbackText>{item}</FeedbackText>
                      </CollapsibleContent>
                    </div>
                  </FeedbackCardContent>
                  {/* Indicator for expansion/collapse */}
                  <ExpandIndicator>
                    {expandedCards[index] ? (
                      <>
                        <ExpandLessIcon />
                        <ReadMoreText>Hide</ReadMoreText>
                      </>
                    ) : (
                      <>
                        <ExpandMoreIcon />
                        <ReadMoreText>Read More</ReadMoreText>
                      </>
                    )}
                  </ExpandIndicator>
                </FeedbackCard>
              ))}
            </LeftSummary>
          </SummaryContainer>
        )}
      </Container>
    </>
  );
}

export default AssessmentPage;
